import React, { useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  CSDSelPage,
  GridView,
  Autocomplete,
  DatePicker,
  Panel,
  Switch,
  ToolbarButtons,
} from 'ui/components';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import {
  getPedagioPagined,
  printPedagio,
  deleteAllPedagio,
  excelPedagio,
} from 'core/services/FRO/pedagio';
import ImportPedagio from './importPedagio';

function formataDataHora(pedagios) {
  pedagios.forEach((element) => {
    const novaData = new Date(element.dtPedagio).toLocaleDateString();
    const novaHora = new Date(element.dtPedagio).toLocaleTimeString();

    element.dtPedagio = `${novaData} ${novaHora}`;
  });
  return pedagios;
}

export default function Pedagio({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [panelImportShow, setPanelImportShow] = useState({
    importPedagio: false,
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params = null) => {
    setLoading(true);

    if (filtros.dataFinal && !filtros.dataInicial) {
      onSetMessage(
        ResponseStatus.Error,
        'Selecione a data inicial para a busca.'
      );

      setLoading(false);
      return;
    }

    if (filtros.dataInicial && !filtros.dataFinal) {
      filtros.dataFinal = new Date();
    }

    const { data: pedagios, pagination } = await getPedagioPagined(
      params ?? filtros
    );

    const pedagiosFormatados = formataDataHora(pedagios);

    if (gridView && gridView.current)
      gridView.current.setDataSource(pedagiosFormatados, pagination);

    setLoading(false);
  };

  const columns = [
    { key: 'nrSeqPedagio', type: GridView.ColumnTypes.Checkbox },
    { key: 'placa', title: 'Placa' },
    { key: 'noTag', title: 'Tag' },
    {
      key: 'flgDuplicado',
      title: 'Duplicado',
      format: GridView.DataTypes.Boolean,
    },
    {
      key: 'dtPedagio',
      title: 'Data',
      sortable: true,
    },
    {
      key: 'dtCadastro',
      title: 'Cadastro',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'vlrPedagio',
      title: 'Valor Pedágio',
      format: GridView.DataTypes.Money,
    },
  ];

  const onOpenImportFile = async () => {
    setPanelImportShow({ importPedagio: true });
  };

  const onSearchVeiculo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      noVeiculo: e,
    });
    if (msg) setMessage(status, msg);
    return veiculos;
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'Pedagio/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqPedagio = sourceRow.find((e) => e.key === 'nrSeqPedagio');
    onOpenMaintenance(nrSeqPedagio.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printPedagio(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDownloadExcel = async () => {
    setLoading(true);

    const { value: base64 } = await excelPedagio(filtros);

    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'Pedagio.csv';
      downloadLink.click();
    }

    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllPedagio(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção Pedágio'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      onDownloadExcel={onDownloadExcel}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Importar'
          icon={['fas', 'file']}
          onClick={onOpenImportFile}
        />
      </ToolbarButtons>
      {panelImportShow.importPedagio && (
        <div className='row'>
          <div className='col'>
            <ImportPedagio
              visible={panelImportShow.importPedagio}
              onClose={() => {
                setPanelImportShow({
                  ...panelImportShow,
                  importPedagio: false,
                });
              }}
              onSuccess={() => {
                setMessage({
                  message: 'Informações de pedágios importadas com sucesso.',
                  theme: Theme.Success,
                });
              }}
            />
          </div>
        </div>
      )}
      {!panelImportShow.importPedagio && (
        <Panel>
          <div className='row'>
            <div className='col-12 mb-4'>
              <Panel.Header
                icon={['fas', 'user']}
                title='Filtros'
                theme={Theme.Primary}
              />
            </div>
          </div>
          <Panel.Body>
            <div className='row'>
              <div className='col-4 mb-4'>
                <DatePicker
                  label='Data Inicial: '
                  text={filtros.dataInicial}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dataInicial) =>
                    setFiltros({ ...filtros, dataInicial })
                  }
                />
              </div>
              <div className='col-4 mb-4'>
                <DatePicker
                  label='Data Final: '
                  text={filtros.dataFinal}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dataFinal) =>
                    setFiltros({ ...filtros, dataFinal })
                  }
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-4 mb-4'>
                <Autocomplete
                  label='Veículo'
                  searchDataSource={onSearchVeiculo}
                  selectedItem={filtros.veiculo}
                  onSelectItem={(veiculo) => {
                    setFiltros({
                      ...filtros,
                      veiculo,
                      nrSeqVeiculo: veiculo.nrSeqVeiculo,
                    });
                  }}
                  dataSourceTextProperty='noVeiculo'
                />
              </div>
              <div className='col-6 mb-4 '>
                <div className='row'>
                  <div className='col-3 mb-4 '>
                    <Switch
                      formControl
                      label='Duplicado'
                      checked={false}
                      onChange={(flgDuplicado) =>
                        setFiltros({
                          ...filtros,
                          flgDuplicado,
                        })
                      }
                    />
                  </div>

                  <div className='col-8 mb-4'>
                    <Switch
                      formControl
                      label='Faturamento Pendente'
                      checked={false}
                      onChange={(flgFaturado) =>
                        setFiltros({
                          ...filtros,
                          flgFaturado,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </Panel.Body>
        </Panel>
      )}

      {!panelImportShow.importPedagio && (
        <div className='row'>
          <div className='col'>
            <GridView
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
              onColumnSort={onColumnSort}
              onPageSizeChange={onPageSizeChange}
              onPageChange={onPageChange}
              onRowDoubleClick={onRowDoubleClick}
              transaction={transaction}
              sumFields
            />
          </div>
        </div>
      )}
    </CSDSelPage>
  );
}
