import React, { useRef, useState, useEffect } from 'react';
import { ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getCanhotoExpedicaoPaginated,
  printCanhotoExpedicao,
} from 'core/services/VEN/pedido';
import { CSDSelPage, GridView, DatePicker, Autocomplete } from 'ui/components';
import { getEmpresaAutoComplete } from 'core/services/SEG';

import { MaskTypes } from 'ui/Helpers/masks';

const columns = [
  { key: 'nrSeqPedido', type: GridView.ColumnTypes.Checkbox },
  {
    key: 'nrSeqPedido',
    title: 'Nr Ped',
  },
  {
    key: 'cliente.noPessoa',
    title: 'Cliente',
  },
  {
    key: 'dtSaida',
    title: 'Data',
  },
];
export default function CanhotoExpedicao({
  transaction,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };
  const formatDateGrid = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getCanhotoExpedicaoPaginated(
      params ?? filtros
    );
    const formattedData = data.map((item) => ({
      ...item,
      dtSaida: formatDateGrid(item.dtSaida),
    }));
    if (gridView && gridView.current) {
      gridView.current.setDataSource(formattedData, pagination);
    }

    setLoading(false);
  };
  const onPrint = async () => {
    setLoading(true);

    const selecteds = gridView.current.getCheckBoxValuesAt(0);
    if (selecteds.length === 0)
      setMessage(
        Theme.Danger,
        'Necessário selecionar os pedidos para poder imprimir'
      );
    if (selecteds.length > 0) {
      const { value } = await printCanhotoExpedicao(
        filtros.nrSeqEmpresa,
        filtros.dtDocumento,
        selecteds.map((row) => row[0].value)
      );
      onOpenReport(transaction.noTransacao, value);
    }
    setLoading(false);
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });
    if (msg) onSetMessage(status, msg);
    const empresasFiltradas = empresas.filter(
      (empresa) => empresa.nrSeqEmpresa === 1 || empresa.nrSeqEmpresa === 5
    );
    return empresasFiltradas;
  };
  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  useEffect(() => {
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    setFiltros({
      ...filtros,
      dtDocumento: formatDate(today),
    });
  }, []);

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Canhoto Expedição'
      loading={loading}
      onSearch={() => search()}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-2'>
          <DatePicker
            label='Data'
            text={filtros.dtDocumento}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtDocumento) => setFiltros({ ...filtros, dtDocumento })}
          />
        </div>
      </div>
      <div className='col-6 mb-3'>
        <Autocomplete
          label='Empresa Padrão'
          searchDataSource={onSearchEmpresa}
          selectedItem={filtros.empresa}
          onSelectItem={(empresa) => {
            setFiltros({
              ...filtros,
              nrSeqEmpresa: empresa.nrSeqEmpresa,
            });
          }}
          dataSourceTextProperty='noPessoa'
        />
      </div>
      <div className='row mb-3'>
        <div className='col-12'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            sumFields
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
