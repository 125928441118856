import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  CSDSelPage,
  DatePicker,
  Textbox,
  GridView,
  Panel,
  Autocomplete,
  ToolbarButtons,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getPneuPagined,
  printPneu,
  deleteAllPneu,
  excelPneu,
  printRelatorioPorKmRodado,
} from 'core/services/FRO/pneu';
import { getPneuVidaAutoComplete } from 'core/services/FRO/pneuVida';
import { getPneuSituacaoAutoComplete } from 'core/services/FRO/pneuSituacao';
import { getPneuModeloAutoComplete } from 'core/services/FRO/pneuModelo';
import { getPneuMarcaAutoComplete } from 'core/services/FRO/pneuMarca';
import { getPneuDimensaoAutoComplete } from 'core/services/FRO/pneuDimensao';

const columns = [
  { key: 'nrSeqPneu', type: GridView.ColumnTypes.Checkbox },
  { key: 'cdPneu', title: 'Código Pneu' },
  { key: 'nrDot', title: 'Nr. Dot' },
  { key: 'numeroSerie', title: 'Numero Série' },
  { key: 'nrLacre', title: 'Lacre', sortable: false },
  { key: 'kmAcumulada', title: 'Km Acumulada' },
  { key: 'nrSulcoInicial', title: 'Nr. Sulco Inicial' },
  { key: 'nrSulcoFinal', title: 'Nr. Sulco Final' },
  { key: 'nf.NrForNf', title: 'NF', visible: false },
  {
    key: 'dtTermino',
    title: 'Dt. Término',
    format: GridView.DataTypes.Date,
    visible: false,
  },
  {
    key: 'dtCompra',
    title: 'Dt. Compra',
    format: GridView.DataTypes.Date,
    visible: false,
  },
  {
    key: 'pneuModelo.noPneuModelo',
    title: 'Modelo',
    sortKey: 'noPneuModelo',
  },
  {
    key: 'pneuModelo.pneuMarca.noPneuMarca',
    title: 'Marca',
    sortKey: 'noPneuMarca',
  },
  {
    key: 'pneuModelo.pneuDimensao.noPneuDimensao',
    title: 'Medida/Dimensão',
    sortKey: 'noPneuDimensao',
  },
  {
    key: 'pneuSituacao.noPneuSituacao',
    title: 'Situação',
    sortKey: 'noPneuSituacao',
  },
  { key: 'pneuVida.noPneuVida', title: 'Vida', sortKey: 'noPneuVida' },
];

export default function Pneu({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getPneuPagined(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getPneuPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };

  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };

  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'Pneu/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow) => {
    const nrSeqPneu = sourceRow.find((e) => e.key === 'nrSeqPneu');
    onOpenMaintenance(nrSeqPneu.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printPneu(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDownloadExcel = async () => {
    setLoading(true);

    const { value: base64 } = await excelPneu(filtros);

    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'Pneu.csv';
      downloadLink.click();
    }

    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllPneu(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onSearchPneuModelo = async (e) => {
    const {
      status,
      message: msg,
      pneuModelo,
    } = await getPneuModeloAutoComplete({ noPneuModelo: e });
    if (msg) onSetMessage(status, msg);
    return pneuModelo;
  };

  const onSearchPneuMarca = async (e) => {
    const {
      status,
      message: msg,
      pneuMarcas,
    } = await getPneuMarcaAutoComplete({ noPneuMarca: e });
    if (msg) onSetMessage(status, msg);
    return pneuMarcas;
  };

  const onSearchPneuDimensao = async (e) => {
    const {
      status,
      message: msg,
      data: pneuDimensoes,
    } = await getPneuDimensaoAutoComplete({ noPneuDimensao: e });
    if (msg) onSetMessage(status, msg);

    return pneuDimensoes;
  };

  const onSearchPneuSituacao = async (e) => {
    const {
      status,
      message: msg,
      data: pneusVida,
    } = await getPneuSituacaoAutoComplete({ noPneuVida: e });
    if (msg) onSetMessage(status, msg);
    return pneusVida;
  };

  const onSearchPneuVida = async (e) => {
    const {
      status,
      message: msg,
      data: pneusVida,
    } = await getPneuVidaAutoComplete({ noPneuVida: e });
    if (msg) onSetMessage(status, msg);
    return pneusVida;
  };

  const onPrintRelatorioPorKmRodado = async () => {
    setLoading(true);
    const { value } = await printRelatorioPorKmRodado(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Pneu'
      loading={loading}
      onSearch={() => search()}
      transaction={transaction}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      onDownloadExcel={onDownloadExcel}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Relatório Custo por Km Rodado'
          icon={['fas', 'file-pdf']}
          onClick={onPrintRelatorioPorKmRodado}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header title='Filtros' theme={Theme.Primary} />
          <Panel.Body>
            {/* Linha 1 */}
            <div className='row mb-3'>
              <div className='col-2 mb-3'>
                <Textbox
                  label='Código Pneu:'
                  maxLength={20}
                  text={filtros.cdPneu}
                  onChangedValue={(cdPneu) =>
                    setFiltros({ ...filtros, cdPneu })
                  }
                />
              </div>
              <div className='col-2 mb-3'>
                <Textbox
                  label='Nr Dot:'
                  maxLength={20}
                  text={filtros.nrDot}
                  onChangedValue={(nrDot) => setFiltros({ ...filtros, nrDot })}
                />
              </div>
              <div className='col-2 mb-3'>
                <Textbox
                  label='Número Série:'
                  maxLength={20}
                  text={filtros.numeroSerie}
                  onChangedValue={(numeroSerie) =>
                    setFiltros({ ...filtros, numeroSerie })
                  }
                />
              </div>
              <div className='col-2 mb-3'>
                <Textbox
                  label='Lacre:'
                  maxLength={20}
                  text={filtros.nrLacre}
                  onChangedValue={(nrLacre) =>
                    setFiltros({ ...filtros, nrLacre })
                  }
                />
              </div>
              <div className='col-2 mb-3'>
                <Textbox
                  label='Sulco Inicial:'
                  maxLength={20}
                  text={filtros.nrSulcoInicial}
                  mask={MaskTypes.Decimal}
                  onChangedValue={(nrSulcoInicial) =>
                    setFiltros({ ...filtros, nrSulcoInicial })
                  }
                />
              </div>
              <div className='col-2 mb-3'>
                <Textbox
                  label='Sulco Inicial:'
                  maxLength={20}
                  text={filtros.nrSulcoFinal}
                  mask={MaskTypes.Decimal}
                  onChangedValue={(nrSulcoFinal) =>
                    setFiltros({ ...filtros, nrSulcoFinal })
                  }
                />
              </div>
            </div>

            {/* Linha 2 */}
            <div className='row mb-3'>
              <div className='col'>
                <Autocomplete
                  label='Pneu Situação:'
                  searchDataSource={onSearchPneuSituacao}
                  selectedItem={filtros.pneuSituacao}
                  onSelectItem={(pneuSituacao) => {
                    setFiltros({
                      ...filtros,
                      pneuSituacao,
                      nrSeqPneuSituacao: pneuSituacao.nrSeqPneuSituacao,
                    });
                  }}
                  dataSourceTextProperty='noPneuSituacao'
                />
              </div>
              <div className='col'>
                <Autocomplete
                  label='Pneu Vida:'
                  searchDataSource={onSearchPneuVida}
                  selectedItem={filtros.pneuVida}
                  onSelectItem={(pneuVida) => {
                    setFiltros({
                      ...filtros,
                      pneuVida,
                      nrSeqPneuVida: pneuVida.nrSeqPneuVida,
                    });
                  }}
                  dataSourceTextProperty='noPneuVida'
                />
              </div>
              <div className='col'>
                <Autocomplete
                  label='Pneu Modelo:'
                  searchDataSource={onSearchPneuModelo}
                  selectedItem={filtros.pneuModelo}
                  onSelectItem={(pneuModelo) => {
                    setFiltros({
                      ...filtros,
                      pneuModelo,
                      nrSeqPneuModelo: pneuModelo.nrSeqPneuModelo,
                    });
                  }}
                  dataSourceTextProperty='noPneuModelo'
                />
              </div>
              <div className='col'>
                <Autocomplete
                  label='Pneu Marca:'
                  searchDataSource={onSearchPneuMarca}
                  selectedItem={filtros.pneuMarca}
                  onSelectItem={(pneuMarca) => {
                    setFiltros({
                      ...filtros,
                      pneuMarca,
                      nrSeqPneuMarca: pneuMarca.nrSeqPneuMarca,
                    });
                  }}
                  dataSourceTextProperty='noPneuMarca'
                />
              </div>
              <div className='col'>
                <Autocomplete
                  label='Medida/Dimensão:'
                  searchDataSource={onSearchPneuDimensao}
                  selectedItem={filtros.pneuDimensao}
                  onSelectItem={(pneuDimensao) => {
                    setFiltros({
                      ...filtros,
                      pneuDimensao,
                      nrSeqPneuDimensao: pneuDimensao.nrSeqPneuDimensao,
                    });
                  }}
                  dataSourceTextProperty='noPneuDimensao'
                />
              </div>
            </div>

            {/* Linha 3 */}
            <div className='row mb-3'>
              <div className='col-2'>
                <DatePicker
                  label='Data Término de:'
                  text={filtros.dtTerminoInicial}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtTerminoInicial) => {
                    setFiltros({ ...filtros, dtTerminoInicial });
                  }}
                />
              </div>
              <div className='col-2'>
                <DatePicker
                  label='Data Término até:'
                  text={filtros.dtTerminoFinal}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtTerminoFinal) => {
                    setFiltros({ ...filtros, dtTerminoFinal });
                  }}
                />
              </div>
              <div className='col-2'>
                <DatePicker
                  label='Data Compra de:'
                  text={filtros.dtCompraInicial}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtCompraInicial) => {
                    setFiltros({ ...filtros, dtCompraInicial });
                  }}
                />
              </div>
              <div className='col-2'>
                <DatePicker
                  label='Data Compra até:'
                  text={filtros.dtCompraFinal}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtCompraFinal) => {
                    setFiltros({ ...filtros, dtCompraFinal });
                  }}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            enableExcelExport
            canControlVisibility
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
