import React, { useEffect, useRef, useState } from 'react';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  getTituloPagarPagined,
  printTituloPagar,
  deleteAllTituloPagar,
  printTituloPagarFornecedor,
  printTituloPagarFornecedorDetalhado,
  printTituloPagarVencimento,
  printTituloPagarVencimentoCheque,
  printTituloPagarFornecedorAgrupado,
  printTituloPagarFornecedorCheque,
  SearchTituloPagamentoMassivo,
  SearchTituloAgrupamentoFinanceiro,
  ExcelTituloPagarFornecedorAgrupado,
  ExcelTituloPagarFornecedor,
  ExcelTituloPagarFornecedorDetalhado,
  ExcelTituloPagarFornecedorCheque,
  ExcelTituloPagarVencimento,
  ExcelTituloPagarVencimentoCheque,
  excelTituloPagar,
  onPrintDataContabil,
  excelContabilTituloPagar,
  SearchTituloVencimentoMassivo,
  ExcelTituloPagarVencimentoSemObs,
  printTituloPagarVencimentoSemObs,
  ExcelTituloPagarTipoDespesa,
  printTituloPagarTipoDespesa,
  printTituloPagarTipoDespesaTotais,
  printTituloPagarTipoDespesaDetalhado,
  onPrintPlanoDeConta,
  excelPlanoContaTituloPagar,
} from 'core/services/FIN/tituloPagar';
import {
  Autocomplete,
  CSDSelPage,
  Confirm,
  DatePicker,
  DropdownMulti,
  GridView,
  Panel,
  RadioButton,
  Switch,
  Textbox,
  ToolbarButtons,
} from 'ui/components';
import { mask, MaskTypes } from 'ui/Helpers/masks';
import {
  getFormaPagamentoAutoComplete,
  getTipoDespesaAutoComplete,
} from 'core/services/FIN';

import { getUsuarioAutoComplete } from 'core/services/SEG/usuario';
import { getVeiculoAutoCompleteResumido } from 'core/services/FRO/veiculo';
import { getPlanoDeContaAutoComplete } from 'core/services/FIN/planoDeConta';
import { getEmpresaAutoComplete } from 'core/services/SEG';
import { getFornecedorAutoComplete } from 'core/services/FIN/fornecedor';
import { getCentroDeCustoAutoComplete } from 'core/services/TEL';
import { getTituloPagarTipoDocumentoAutoComplete } from 'core/services/FIN/tituloPagarTipoDocumento';
import ModalPagamentoMassivo from './ModalPagamentoMassivo';
import ModalAgrupamentoTitulo from './ModalAgrupamentoTitulo';
import ModalAlteracaoVencimento from './ModalAlteracaoVencimento';
import ModalAlteracaoFormaPagamento from './ModalAlteracaoFormaPagamento';
import { printDetalhadoComInformacaoBancaria } from '../../../../core/services/FIN/tituloPagar';

export default function TituloPagar({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();
  const dropDownEmpresa = useRef();
  const [data, setData] = useState({});
  const [totalSelecionadoSaldo, setTotalSelecionadoSaldo] = useState(0);
  const [modalShow, setModalShow] = useState({});
  const [filtros, setFiltros] = useState({
    tipoTituloPagar: 'A',
    pesquisaPlanoConta: 'N',
  });
  const [selectedItems, setSelectedItems] = useState({});
  const [loading, setLoading] = useState(false);
  const [
    showConfirmaDeleteCustoFixo,
    setShowConfirmaDeleteCustoFixo,
  ] = useState(false);
  const [itensDelete, setItensDelete] = useState({});
  const [message, setMessage] = useState(null);
  const dropDownFormaPagamento = useRef();
  const dropDownTipoDespesa = useRef();
  const dropDownCentroDeCusto = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchVeiculo = async (e) => {
    const {
      status,
      message: msg,
      veiculos,
    } = await getVeiculoAutoCompleteResumido({
      placa: e,
      flgPrincipal: filtros.tipoBusca === 'Acerto' ? true : undefined,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  function RenderTextMask(props) {
    const { label, value, masktype } = props;
    return (
      <div className='col-auto mt-3'>
        <div className='fw-bold'>
          {label}: {mask(value ?? 0, masktype ?? MaskTypes.Currency)}
        </div>
      </div>
    );
  }

  const search = async (params = null) => {
    setLoading(true);

    const { data: titulopagar, pagination } = await getTituloPagarPagined(
      params ?? filtros
    );

    if (titulopagar !== undefined && titulopagar !== null) {
      if (titulopagar.length > 0) {
        setFiltros({
          ...filtros,
          vlrTotal: titulopagar[0].vlrSomadoTotal,
          saldoTotal: titulopagar[0].vlrSaldoTotal,
          totalPago: titulopagar[0].vlrPagoTotal,
        });
      } else {
        setFiltros({
          ...filtros,
          vlrTotal: 0,
          saldoTotal: 0,
          totalPago: 0,
        });
      }
    } else {
      setFiltros({
        ...filtros,
        vlrTotal: 0,
        saldoTotal: 0,
        totalPago: 0,
      });
    }

    if (gridView && gridView.current)
      gridView.current.setDataSource(titulopagar, pagination);

    setLoading(false);
  };

  const onOpenPagamento = async (sourceRow) => {
    const { nrSeqTituloPagar } = sourceRow;
    const searchTituloPagar = { nrSeqTituloPagar };
    const titulospagar = await SearchTituloPagamentoMassivo(searchTituloPagar);

    if (titulospagar.data.length === 0) {
      setMessage({
        message: titulospagar.message,
        theme:
          titulospagar.status === ResponseStatus.Success
            ? Theme.Success
            : Theme.Danger,
      });
      return;
    }

    setModalShow({
      ...modalShow,
      enviarFaturasPorEmail: true,
      pagamentoMassivo: titulospagar.data,
    });
  };

  const columns = [
    { key: 'nrSeqTituloPagar', type: GridView.ColumnTypes.Checkbox },
    { key: 'cdTituloPagar', title: 'Compromisso' },
    { key: 'nrTit', title: 'Nr. Tit' },
    { key: 'nrOrdem', title: 'Ordem' },
    {
      key: 'fornecedor.noFornecedor',
      title: 'Fornecedor',
      sortKey: 'noFornecedor',
    },
    {
      key: 'tipoDespesa.noTipoDespesa',
      title: 'Tipo Despesa',
      sortKey: 'noTipoDespesa',
    },
    {
      key: 'planoConta',
      title: 'Plano De Conta',
      visible: false,
    },
    {
      key: 'noVeiculos',
      title: 'Veículos',
      visible: false,
    },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma Pagamento',
      sortKey: 'noFormaPagamento',
    },
    {
      key: 'vlrTitulo',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
    },
    { key: 'vlrPago', title: 'Pago', format: GridView.DataTypes.Decimal },
    {
      key: 'vlrSaldoTit',
      title: 'Saldo',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrDesconto',
      title: 'Desconto',
      visible: false,
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrJuroPago',
      title: 'Juro',
      visible: false,
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrMultaPaga',
      title: 'Multa',
      visible: false,
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'dtPagamento',
      title: 'Pagamento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrParcela',
      title: 'Parcelas Pagas',
      format: GridView.DataTypes.Integer,
    },
    {
      key: 'nrSeqTituloPagar',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onOpenPagamento(e),
      theme: Theme.Primary,
      icon: 'cash-register',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Quitar Titulo',
      tooltipDirection: 'bottom',
      title: 'Quitar',
    },
    { key: 'flgBoletoEntregue', title: 'Entregue' },
  ];

  const tipoTitulo = [
    {
      text: 'Aberto',
      value: 'A',
    },
    {
      text: 'Todos',
      value: 'T',
    },
    {
      text: 'Fechado',
      value: 'F',
    },
  ];

  const PesquisaPlano = [
    {
      text: 'Sim',
      value: 'S',
    },
    {
      text: 'Não',
      value: 'N',
    },
  ];

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'TituloPagar/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow) => {
    const nrSeqTituloPagar = sourceRow.find(
      (e) => e.key === 'nrSeqTituloPagar'
    );
    onOpenMaintenance(nrSeqTituloPagar.value);
  };

  const onOpenPagamentoMassivo = async () => {
    const nrSeqTituloPagarS = gridView.current
      .getCheckBoxValuesAt(0)
      ?.map((row) => row[0].value);

    if (nrSeqTituloPagarS.length === 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Selecione um item para fazer o pagamento'
      );
      return;
    }

    const searchTituloPagar = { nrSeqTituloPagarS };

    const titulospagar = await SearchTituloPagamentoMassivo(searchTituloPagar);

    if (titulospagar.data.length === 0) {
      setMessage({
        message: titulospagar.message,
        theme:
          titulospagar.status === ResponseStatus.Success
            ? Theme.Success
            : Theme.Danger,
      });
      return;
    }

    setModalShow({
      ...modalShow,
      enviarFaturasPorEmail: true,
      pagamentoMassivo: titulospagar.data,
    });
  };
  const onOpenVencimentoMassivo = async () => {
    const nrSeqTituloPagarS = gridView.current
      .getCheckBoxValuesAt(0)
      ?.map((row) => row[0].value);

    if (nrSeqTituloPagarS.length === 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Selecione um item para fazer a alteração do vencimento'
      );
      return;
    }

    const searchTituloPagar = { nrSeqTituloPagarS };

    const titulospagar = await SearchTituloVencimentoMassivo(searchTituloPagar);

    if (titulospagar.data.length === 0) {
      setMessage({
        message: titulospagar.message,
        theme:
          titulospagar.status === ResponseStatus.Success
            ? Theme.Success
            : Theme.Danger,
      });
      return;
    }

    setModalShow({
      ...modalShow,
      alteracaoVencimento: true,
      alteracaoVencimentoValor: titulospagar.data,
    });
  };

  const onOpenFormaPagamento = async () => {
    const nrSeqTituloPagarS = gridView.current
      .getCheckBoxValuesAt(0)
      ?.map((row) => row[0].value);

    if (nrSeqTituloPagarS.length === 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Selecione um item para fazer a alteração do vencimento'
      );
      return;
    }

    const searchTituloPagar = { nrSeqTituloPagarS };

    const titulospagar = await SearchTituloVencimentoMassivo(searchTituloPagar);

    if (titulospagar.data.length === 0) {
      setMessage({
        message: titulospagar.message,
        theme:
          titulospagar.status === ResponseStatus.Success
            ? Theme.Success
            : Theme.Danger,
      });
      return;
    }

    setModalShow({
      ...modalShow,
      alteracaoFormaPagamento: true,
      alteracaoFormaPagamentoValor: titulospagar.data,
    });
  };

  const onOpenAgrupamentoTitulo = async () => {
    const nrSeqTituloPagarS = gridView.current
      .getCheckBoxValuesAt(0)
      ?.map((row) => row[0].value);

    if (nrSeqTituloPagarS.length === 0) {
      onSetMessage(ResponseStatus.Error, 'Selecione um item para agrupar');
      return;
    }

    const searchTituloPagar = { nrSeqTituloPagarS };

    const titulospagar = await SearchTituloAgrupamentoFinanceiro(
      searchTituloPagar
    );

    if (titulospagar.data.length === 0) {
      setMessage({
        message: titulospagar.message,
        theme:
          titulospagar.status === ResponseStatus.Success
            ? Theme.Success
            : Theme.Danger,
      });
      return;
    }

    setModalShow({
      ...modalShow,
      agrupamentoTitulo: true,
      agrupamentoTituloValor: titulospagar.data,
    });
  };

  const onPrint = async () => {
    setLoading(true);
    const value = await printTituloPagar(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onLimpaCampo = async () => {
    setFiltros({
      tipoTituloPagar: 'A',
      vlrTitulo: 0,
    });
    setSelectedItems({});
  };

  const onPrintFornecedorAgrupado = async () => {
    setLoading(true);
    const value = await printTituloPagarFornecedorAgrupado(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onPrintFornecedor = async () => {
    setLoading(true);
    const value = await printTituloPagarFornecedor(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onPrintFornecedorDetalhado = async () => {
    setLoading(true);
    const value = await printTituloPagarFornecedorDetalhado(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onPrintFornecedorCheque = async () => {
    setLoading(true);
    const value = await printTituloPagarFornecedorCheque(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onPrintVencimento = async () => {
    setLoading(true);
    const value = await printTituloPagarVencimento(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onPrintVencimentoSemObs = async () => {
    setLoading(true);
    const value = await printTituloPagarVencimentoSemObs(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onPrintTipoDespesa = async () => {
    setLoading(true);
    const value = await printTituloPagarTipoDespesa(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onPrintTipoDespesaTotais = async () => {
    setLoading(true);
    const value = await printTituloPagarTipoDespesaTotais(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onPrintTipoDespesaDetalhado = async () => {
    setLoading(true);
    const value = await printTituloPagarTipoDespesaDetalhado(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onPrintDetalhadoComInformacaoBancaria = async () => {
    setLoading(true);

    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    const nrSeqTituloPagarS = selecteds.map((row) => row[0].value);

    filtros.nrSeqTituloPagarS = nrSeqTituloPagarS;

    const value = await printDetalhadoComInformacaoBancaria(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onPrintContabil = async () => {
    setLoading(true);
    const value = await onPrintDataContabil(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onPrintPlanoConta = async () => {
    setLoading(true);
    const value = await onPrintPlanoDeConta(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onPrintVencimentoCheque = async () => {
    setLoading(true);
    const value = await printTituloPagarVencimentoCheque(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onExcel = async () => {
    setLoading(true);
    const value = await excelTituloPagar(filtros);
    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `TituloPagar.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };
  const onContabilExcel = async () => {
    setLoading(true);
    const value = await excelContabilTituloPagar(filtros);
    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `TituloPagar.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };

  const onPrintPlanoContaExcel = async () => {
    setLoading(true);
    const value = await excelPlanoContaTituloPagar(filtros);
    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `TituloPagar.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };

  const onExcelFornecedorAgrupado = async () => {
    setLoading(true);
    const value = await ExcelTituloPagarFornecedorAgrupado(filtros);

    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `TituloPagar.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };
  const onExcelFornecedor = async () => {
    setLoading(true);
    const value = await ExcelTituloPagarFornecedor(filtros);
    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `TituloPagar.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };
  const onExcelFornecedorDetalhado = async () => {
    setLoading(true);
    const value = await ExcelTituloPagarFornecedorDetalhado(filtros);
    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `TituloPagar.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };
  const onExcelFornecedorCheque = async () => {
    setLoading(true);
    const value = await ExcelTituloPagarFornecedorCheque(filtros);
    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `TituloPagar.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };
  const onExcelVencimento = async () => {
    setLoading(true);
    const value = await ExcelTituloPagarVencimento(filtros);
    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `TituloPagar.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };
  const onExcelVencimentoSemObs = async () => {
    setLoading(true);
    const value = await ExcelTituloPagarVencimentoSemObs(filtros);
    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `TituloPagar.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };
  const onExcelTipoDespesa = async () => {
    setLoading(true);
    const value = await ExcelTituloPagarTipoDespesa(filtros);
    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `TituloPagar.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };
  const onExcelVencimentoCheque = async () => {
    setLoading(true);
    const value = await ExcelTituloPagarVencimentoCheque(filtros);
    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `TituloPagar.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };

  const onDelete = async (flgConfirmaExclusao = false) => {
    setLoading(true);

    let selecteds = [];
    let valores = [];

    selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (flgConfirmaExclusao === true) {
      valores = itensDelete.valores;
    }

    if (selecteds.length > 0 || valores.length > 0) {
      if (flgConfirmaExclusao === false) {
        valores = selecteds.map((row) => row[0].value);
      }
      setItensDelete({ valores });

      const { status, message: msg } = await deleteAllTituloPagar(
        valores,
        flgConfirmaExclusao
      );
      if (msg === 'ConfirmaExclusaoCustoFixo') {
        setShowConfirmaDeleteCustoFixo(true);
      } else {
        setShowConfirmaDeleteCustoFixo(false);
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });
      }

      setLoading(false);

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };
  const onSearchTipoDespesa = async (e) => {
    const { tipoDespesas } = await getTipoDespesaAutoComplete({
      noTipoDespesa: e,
    });

    return tipoDespesas;
  };
  const onSearchFormaPagamento = async (e) => {
    const { formasPagamento } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgTitPag: true,
    });

    return formasPagamento;
  };
  const onSearchCentroDeCusto = async (e) => {
    const { data: CentroDeCusto } = await getCentroDeCustoAutoComplete({
      noCentroDeCusto: e,
    });

    return CentroDeCusto;
  };
  const onSearchEmpresa = async (e) => {
    const { empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });

    return empresas;
  };
  useEffect(() => {
    dropDownEmpresa.current.loadDataSource(onSearchEmpresa);
    dropDownFormaPagamento.current.loadDataSource(onSearchFormaPagamento);
    dropDownTipoDespesa.current.loadDataSource(onSearchTipoDespesa);
    dropDownCentroDeCusto.current.loadDataSource(onSearchCentroDeCusto);
  }, []);

  const onSearchPlanoDeConta = async (e) => {
    const { planodecontas } = await getPlanoDeContaAutoComplete({
      noPlanoDeConta: e,
    });

    return planodecontas;
  };
  const onSearchTipo = async (e) => {
    const {
      data: tituloPagarTipoDocumento,
    } = await getTituloPagarTipoDocumentoAutoComplete({
      noTituloPagarTipoDocumento: e,
    });

    return tituloPagarTipoDocumento;
  };
  const onSearchFornecedor = async (e) => {
    const {
      status,
      message: msg,
      data: pessoaFors,
    } = await getFornecedorAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return pessoaFors;
  };

  const onSearchUsuario = async (e) => {
    const response = await getUsuarioAutoComplete({
      noLogin: e,
    });
    if (response.message) onSetMessage(response.status, response.message);
    return response.data;
  };

  const onCalculaTotais = () => {
    const nrSeqTituloPagarS = gridView.current
      .getCheckBoxValuesAt(0)
      ?.map((row) => row[0].value);

    const Listatitulos = gridView.current.getDataSource();

    const total = nrSeqTituloPagarS.reduce((acc, numero) => {
      const titulo = Listatitulos.find((t) => t.nrSeqTituloPagar === numero);
      return titulo ? acc + titulo.vlrTitulo : acc;
    }, 0);

    setTotalSelecionadoSaldo(total);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      transaction={transaction}
      title='Seleção de Titulo Pagar'
      loading={loading}
      onNew={onOpenMaintenance}
      onSearch={() => search()}
      onDelete={onDelete}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Agrupamento Titulo'
          icon='user'
          onClick={onOpenAgrupamentoTitulo}
        />
        <ToolbarButtons.Button
          text='Imprimir'
          icon='print'
          onClick={() => onPrint('Padrao')}
        />
        <ToolbarButtons.ButtonList
          onPrintFornecedorAgrupado
          icon='caret-down'
          items={[
            {
              text: 'Fornecedor Agrupado',
              onClick: () => onPrintFornecedorAgrupado('Agrupado'),
            },
            {
              text: 'Fornecedor ',
              onClick: () => onPrintFornecedor('Agrupado'),
            },
            {
              text: 'Fornecedor Detalhado',
              onClick: () => onPrintFornecedorDetalhado('Agrupado'),
            },
            {
              text: 'Fornecedor, Cheque Compensar ',
              onClick: () => onPrintFornecedorCheque('Agrupado'),
            },
            {
              text: 'Vencimento',
              onClick: () => onPrintVencimento('Agrupado'),
            },
            {
              text: 'Vencimento, Cheque Compensar',
              onClick: () => onPrintVencimentoCheque('Agrupado'),
            },
            {
              text: 'Vencimento Sem Observação',
              onClick: () => onPrintVencimentoSemObs('Agrupado'),
            },
            {
              text: 'Data Contabil',
              onClick: () => onPrintContabil('Agrupado'),
            },
            {
              text: 'Plano Conta',
              onClick: () => onPrintPlanoConta('Agrupado'),
            },
            {
              text: 'Tipo Despesa',
              onClick: () => onPrintTipoDespesa('Agrupado'),
            },
            {
              text: 'Tipo Despesa - Totais',
              onClick: () => onPrintTipoDespesaTotais('Agrupado'),
            },
            {
              text: 'Tipo Despesa - Detalhado',
              onClick: () => onPrintTipoDespesaDetalhado('Agrupado'),
            },
            {
              text: 'Detalhado com Informação Bancária',
              onClick: () => onPrintDetalhadoComInformacaoBancaria(),
            },
          ]}
        />
        <ToolbarButtons.Button
          text='Excel'
          icon={['far', 'file-excel']}
          onClick={() => onExcel('Padrao')}
        />
        <ToolbarButtons.ButtonList
          onExcelFornecedorAgrupado
          icon='caret-down'
          items={[
            {
              text: 'Fornecedor Agrupado',
              onClick: () => onExcelFornecedorAgrupado('Agrupado'),
            },
            {
              text: 'Fornecedor ',
              onClick: () => onExcelFornecedor('Agrupado'),
            },
            {
              text: 'Fornecedor Detalhado',
              onClick: () => onExcelFornecedorDetalhado('Agrupado'),
            },
            {
              text: 'Fornecedor, Cheque Compensar ',
              onClick: () => onExcelFornecedorCheque('Agrupado'),
            },
            {
              text: 'Vencimento',
              onClick: () => onExcelVencimento('Agrupado'),
            },
            {
              text: 'Vencimento, Cheque Compensar',
              onClick: () => onExcelVencimentoCheque('Agrupado'),
            },
            {
              text: 'Vencimento Sem Observação',
              onClick: () => onExcelVencimentoSemObs('Agrupado'),
            },
            {
              text: 'Data Contabil',
              onClick: () => onContabilExcel('Agrupado'),
            },
            {
              text: 'Plano Conta',
              onClick: () => onPrintPlanoContaExcel('Agrupado'),
            },
            {
              text: 'Tipo Despesa',
              onClick: () => onExcelTipoDespesa('Agrupado'),
            },
          ]}
        />
        <ToolbarButtons.Button
          text='Pagamento Massivo'
          icon='money'
          onClick={onOpenPagamentoMassivo}
        />
        <ToolbarButtons.Button
          text='Alteração Vencimento'
          onClick={onOpenVencimentoMassivo}
        />
        <ToolbarButtons.Button
          text='Alteração Forma Pagamento'
          onClick={onOpenFormaPagamento}
        />
      </ToolbarButtons>
      <Confirm
        show={showConfirmaDeleteCustoFixo}
        title='Confirmar Delete Custo Fixo'
        titleIcon='exclamation-triangle'
        text='O titulo a pagar esta vinculado a um custo fixo, deseja exclui-lo mesmo assim?'
        handleConfirm={() => {
          onDelete(true);
        }}
        handleCancel={() => setShowConfirmaDeleteCustoFixo(false)}
      />

      <Panel>
        <div className='row'>
          <div className='col-12 mb-4'>
            <Panel.Header
              icon={['fas', 'user']}
              title='Filtros'
              theme={Theme.Primary}
              onClickCollapseButton={onLimpaCampo}
            />
          </div>
        </div>
        <Panel.Body>
          <div className='row'>
            <div className='col-4 mb-4'>
              <Autocomplete
                label='Fornecedor'
                searchDataSource={onSearchFornecedor}
                selectedItem={filtros.fornecedor}
                onSelectItem={(fornecedor) => {
                  setFiltros({
                    ...filtros,
                    fornecedor,
                    nrSeqPessoaFor: fornecedor.nrSeqPessoaFor,
                  });
                }}
                dataSourceTextProperty='nomeDocumentoCidadeEstado'
              />
            </div>
            <div className='col-4 mb-4'>
              <Autocomplete
                label='Tipo'
                searchDataSource={onSearchTipo}
                selectedItem={filtros.tituloPagarTipoDocumento}
                onSelectItem={(tituloPagarTipoDocumento) => {
                  setFiltros({
                    ...filtros,
                    tituloPagarTipoDocumento,
                    nrSeqTituloPagarTipoDocumento:
                      tituloPagarTipoDocumento.nrSeqTituloPagarTipoDocumento,
                  });
                }}
                dataSourceTextProperty='noTituloPagarTipoDocumento'
              />
            </div>
            <div className='col-4 mb-4'>
              <RadioButton
                label='Tipos de Titulo Pagar'
                outline
                size={BootstrapSizes.Small}
                theme={Theme.Primary}
                selectedButton={filtros.tipoTituloPagar}
                buttons={tipoTitulo}
                onChange={(tipoTituloPagar) => {
                  setFiltros({
                    ...filtros,
                    tipoTituloPagar,
                  });
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-4 mb-4'>
              <DropdownMulti
                label='Empresa'
                ref={dropDownEmpresa}
                dataSourcePropertyText='noPessoa'
                dataSourcePropertyValue='nrSeqEmpresa'
                selectedItems={selectedItems.empresa ?? []}
                onSelectItem={(empresa) => {
                  setSelectedItems({ ...selectedItems, empresa });
                  setFiltros({
                    ...filtros,
                    nrSeqEmpresas: empresa?.map((p) => p.nrSeqEmpresa),
                  });
                }}
              />
            </div>

            <div className='col-4'>
              <DropdownMulti
                label='Forma De Pagamento'
                ref={dropDownFormaPagamento}
                dataSourcePropertyText='noFormaPagamento'
                dataSourcePropertyValue='nrSeqFormaPagamento'
                selectedItems={selectedItems.formaPagamento ?? []}
                onSelectItem={(formaPagamento) => {
                  setSelectedItems({ ...selectedItems, formaPagamento });
                  setFiltros({
                    ...filtros,
                    nrSeqFormaPagamentos: formaPagamento?.map(
                      (p) => p.nrSeqFormaPagamento
                    ),
                  });
                }}
              />
            </div>

            <div className='col-4'>
              <DropdownMulti
                label='Tipo Despesa'
                ref={dropDownTipoDespesa}
                dataSourcePropertyText='noTipoDespesa'
                dataSourcePropertyValue='nrSeqTipoDespesa'
                selectedItems={selectedItems.tipoDespesa ?? []}
                onSelectItem={(tipoDespesa) => {
                  setSelectedItems({ ...selectedItems, tipoDespesa });
                  setFiltros({
                    ...filtros,
                    nrSeqTipoDespesaS: tipoDespesa?.map(
                      (p) => p.nrSeqTipoDespesa
                    ),
                  });
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-2 mb-3'>
              <Textbox
                maxLength={20}
                label='Nr. Documento'
                text={filtros.nrTit}
                onChangedValue={(nrTit) =>
                  setFiltros({
                    ...filtros,
                    nrTit,
                  })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <Textbox
                maxLength={20}
                label='Nr. Compromisso'
                text={filtros.cdTituloPagar}
                onChangedValue={(cdTituloPagar) =>
                  setFiltros({
                    ...filtros,
                    cdTituloPagar,
                  })
                }
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                maxLength={20}
                label='Vlr Título'
                textRight
                text={filtros.vlrTitulo}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrTitulo) =>
                  setFiltros({
                    ...filtros,
                    vlrTitulo,
                  })
                }
              />
            </div>
            <div className='col-1'>
              <Switch
                formControl
                label='Entregue'
                checked={filtros?.flgBoletoEntregue}
                onChange={(flgBoletoEntregue) =>
                  setFiltros({
                    ...filtros,
                    flgBoletoEntregue,
                  })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <Textbox
                maxLength={20}
                label='Nota'
                text={filtros.nota}
                mask={MaskTypes.Integer}
                onChangedValue={(nota) =>
                  setFiltros({
                    ...filtros,
                    nota,
                  })
                }
              />
            </div>

            <div className='col-2'>
              <Autocomplete
                label='Veículo'
                searchDataSource={onSearchVeiculo}
                selectedItem={filtros.veiculo}
                onSelectItem={(veiculo) => {
                  setFiltros({
                    ...filtros,
                    veiculo,
                    nrSeqVeiculo: veiculo.nrSeqVeiculo,
                  });
                }}
                dataSourceTextProperty='placa'
              />
            </div>
            <div className='row'>
              <div className='col-2 mb-3'>
                <DatePicker
                  label='Vencimento De'
                  text={filtros.dtVencimentoDe}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtVencimentoDe) =>
                    setFiltros({ ...filtros, dtVencimentoDe })
                  }
                />
              </div>
              <div className='col-2 mb-3'>
                <DatePicker
                  label='Vencimento Até'
                  text={filtros.dtVencimentoAt}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtVencimentoAt) =>
                    setFiltros({ ...filtros, dtVencimentoAt })
                  }
                />
              </div>
              <div className='col-2 mb-3'>
                <DatePicker
                  label='Contabil De'
                  text={filtros.contabilDe}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(contabilDe) =>
                    setFiltros({ ...filtros, contabilDe })
                  }
                />
              </div>
              <div className='col-2 mb-3'>
                <DatePicker
                  label='Contabil Até'
                  text={filtros.contabilAte}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(contabilAte) =>
                    setFiltros({ ...filtros, contabilAte })
                  }
                />
              </div>
              <div className='col-2 mb-3'>
                <DatePicker
                  label='Pagamento De'
                  text={filtros.pagamentoDe}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(pagamentoDe) =>
                    setFiltros({ ...filtros, pagamentoDe })
                  }
                />
              </div>
              <div className='col-2 mb-3'>
                <DatePicker
                  label='Pagamento Até'
                  text={filtros.pagamentoAte}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(pagamentoAte) =>
                    setFiltros({ ...filtros, pagamentoAte })
                  }
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-2 mb-3'>
                <DatePicker
                  label='Cadastro De'
                  text={filtros.dtCadastro}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtCadastro) =>
                    setFiltros({ ...filtros, dtCadastro })
                  }
                />
              </div>
              <div className='col-2 mb-3'>
                <DatePicker
                  label='Cadastro Até'
                  text={filtros.dtCadastroAte}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtCadastroAte) =>
                    setFiltros({ ...filtros, dtCadastroAte })
                  }
                />
              </div>
              <div className='col-4 mb-4'>
                <Autocomplete
                  label='Plano De Conta'
                  searchDataSource={onSearchPlanoDeConta}
                  selectedItem={filtros.planoDeConta}
                  onSelectItem={(planoDeConta) => {
                    setFiltros({
                      ...filtros,
                      planoDeConta,
                      nrSeqPlanoDeConta: planoDeConta.nrSeqPlanoDeConta,
                    });
                  }}
                  dataSourceTextProperty='cdCaminhoCompleto'
                />
              </div>

              <div className='col-4'>
                <DropdownMulti
                  label='Centro De Custo'
                  ref={dropDownCentroDeCusto}
                  dataSourcePropertyText='noCentroDeCusto'
                  dataSourcePropertyValue='nrSeqCentroDeCusto'
                  selectedItems={selectedItems.centroDeCusto ?? []}
                  onSelectItem={(centroDeCusto) => {
                    setSelectedItems({ ...selectedItems, centroDeCusto });
                    setFiltros({
                      ...filtros,
                      nrSeqCentroDeCustoS: centroDeCusto?.map(
                        (p) => p.nrSeqCentroDeCusto
                      ),
                    });
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-6 mb-3'>
                <Textbox
                  label='Obs'
                  text={filtros?.noObs}
                  onChangedValue={(noObs) =>
                    setFiltros({
                      ...filtros,
                      noObs,
                    })
                  }
                />
              </div>
              <div className='col-3 mb-4'>
                <RadioButton
                  label='Pesquisar Planos de Conta'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filtros.pesquisaPlanoConta}
                  buttons={PesquisaPlano}
                  onChange={(pesquisaPlanoConta) => {
                    setFiltros({
                      ...filtros,
                      pesquisaPlanoConta,
                    });
                  }}
                />
              </div>
              <div className='col-3 mb-4'>
                <Autocomplete
                  label='Cadastrado Por'
                  searchDataSource={onSearchUsuario}
                  selectedItem={filtros.usuarioCad}
                  onSelectItem={(usuarioCad) => {
                    setFiltros({
                      ...filtros,
                      usuarioCad,
                      nrSeqUsuarioCad: usuarioCad.nrSeqUsuario,
                    });
                  }}
                  dataSourceTextProperty='noLogin'
                />
              </div>
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <div className='row'>
        <div className='col-sm-12'>
          <div className='alert alert-info fs-8' role='alert'>
            <div className='row'>
              <div className='col-3 mb-3 float-right'>
                <RenderTextMask label='Valor Total' value={filtros?.vlrTotal} />
              </div>
              <div className='col-3 mb-3 float-right'>
                <RenderTextMask label='Total Pago' value={filtros?.totalPago} />
              </div>
              <div className='col-3 mb-3 float-right'>
                <RenderTextMask
                  label='Saldo Total'
                  value={filtros?.saldoTotal}
                />
              </div>
              <div className='col-3 mb-3 float-right'>
                <RenderTextMask
                  label='Saldo Total Selecionado'
                  value={totalSelecionadoSaldo}
                />
              </div>
            </div>
          </div>
          .
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            transaction={transaction}
            canControlVisibility
            enableExcelExport
            ref={gridView}
            gridSizeList={[2000, 4000, 8000, 50000]}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            onRowRender={onCalculaTotais}
            sumFields
          />
        </div>
      </div>
      <ModalPagamentoMassivo
        show={modalShow.enviarFaturasPorEmail}
        transaction={transaction}
        pagamentoMassivo={modalShow.pagamentoMassivo}
        onSave={(pagamentoMassivo) => {
          setData({ ...data, pagamentoMassivo });
          setModalShow({
            ...modalShow,
            pagamentoMassivo: modalShow.pagamentoMassivo,
          });
        }}
        onClose={({ message: msg, theme }) => {
          setModalShow({
            ...modalShow,
            enviarFaturasPorEmail: false,
          });
          setMessage({
            message: msg,
            theme,
          });
        }}
      />
      <ModalAgrupamentoTitulo
        show={modalShow.agrupamentoTitulo}
        agrupamentoTituloValor={modalShow.agrupamentoTituloValor}
        onSave={(agrupamentoTituloValor) => {
          setData({ ...data, agrupamentoTituloValor });
          setModalShow({
            ...modalShow,
            agrupamentoTituloValor: modalShow.agrupamentoTituloValor,
          });
        }}
        onClose={({ message: msg, theme }) => {
          setModalShow({
            ...modalShow,
            agrupamentoTitulo: false,
          });
          setMessage({
            message: msg,
            theme,
          });
        }}
      />
      <ModalAlteracaoVencimento
        show={modalShow.alteracaoVencimento}
        alteracaoVencimentoValor={modalShow.alteracaoVencimentoValor}
        onSave={(alteracaoVencimentoValor) => {
          setData({ ...data, alteracaoVencimentoValor });
          setModalShow({
            ...modalShow,
            alteracaoVencimentoValor: modalShow.alteracaoVencimentoValor,
          });
        }}
        onClose={({ message: msg, theme }) => {
          setModalShow({
            ...modalShow,
            alteracaoVencimento: false,
          });
          setMessage({
            message: msg,
            theme,
          });
        }}
      />
      <ModalAlteracaoFormaPagamento
        show={modalShow.alteracaoFormaPagamento}
        alteracaoFormaPagamentoValor={modalShow.alteracaoFormaPagamentoValor}
        onSave={(alteracaoFormaPagamentoValor) => {
          setData({ ...data, alteracaoFormaPagamentoValor });
          setModalShow({
            ...modalShow,
            alteracaoFormaPagamentoValor:
              modalShow.alteracaoFormaPagamentoValor,
          });
        }}
        onClose={({ message: msg, theme }) => {
          setModalShow({
            ...modalShow,
            alteracaoFormaPagamento: false,
          });
          setMessage({
            message: msg,
            theme,
          });
        }}
      />
    </CSDSelPage>
  );
}
