import {
  ContaCorrente,
  ChequeSituacao,
  Banco,
  Agencia,
  Cliente,
} from 'core/models/FIN';

import { Empresa } from 'core/models/SEG';

export default class Cheque {
  constructor(jsonObject = {}) {
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqCheque = jsonObject.nrSeqCheque;
    this.flgReceber = jsonObject.flgReceber;
    this.dtDocumento = jsonObject.dtDocumento;
    this.dtVencimento = jsonObject.dtVencimento;
    this.dtCredito = jsonObject.dtCredito;
    this.nrSeqBanco = jsonObject.nrSeqBanco;
    this.noAgencia = jsonObject.noAgencia;
    this.noConta = jsonObject.noConta;
    this.nrCheque = jsonObject.nrCheque;
    this.vlrCheque = jsonObject.vlrCheque;
    this.nrSeqChequeSituacao = jsonObject.nrSeqChequeSituacao;
    this.noCheque = jsonObject.noCheque;
    this.noPessoaCheque = jsonObject.noPessoaCheque;
    this.nrSeqContaCorrente = jsonObject.nrSeqContaCorrente;
    this.nrSeqPessoa = jsonObject.nrSeqPessoa;
    this.nrSeqTitulo = jsonObject.nrSeqTitulo;
    this.banco = jsonObject.banco;
    this.cliente = jsonObject.cliente;
    this.numero = jsonObject.numero;
    this.tipo = jsonObject.tipo;
    this.dtCompensacao = jsonObject.dtCompensacao;
    this.flgImpresso = jsonObject.flgImpresso;
    this.flgPermiteImpressao = jsonObject.flgPermiteImpressao;
    this.noValorPorExtenso = jsonObject.noValorPorExtenso;
    this.bancoCheque = new Banco(jsonObject.bancoCheque ?? {});
    this.agenciaCheque = new Agencia(jsonObject.agenciaCheque ?? {});
    this.contaCorrente = new ContaCorrente(jsonObject.contaCorrente ?? {});
    this.chequeSituacao = new ChequeSituacao(jsonObject.chequeSituacao ?? {});
    this.clienteCheque = new Cliente(jsonObject.clienteCheque ?? {});
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.flgTipo = jsonObject.flgTipo ?? '';
    this.dtRecebimentoDe = jsonObject.dtRecebimentoDe;
    this.dtRecebimentoAte = jsonObject.dtRecebimentoAte;
    this.dtVencimentoDe = jsonObject.dtVencimentoDe;
    this.dtVencimentoAte = jsonObject.dtVencimentoAte;
    this.dtCompensacaoDe = jsonObject.dtCompensacaoDe;
    this.dtCompensacaoAte = jsonObject.dtCompensacaoAte;
    this.noDescricaoCheque = jsonObject.noDescricaoCheque;
    this.noBanco = jsonObject.noBanco;
    this.noChequeSituacao = jsonObject.noChequeSituacao;
    this.flgPendenteCompensar = jsonObject.flgPendenteCompensar;
    this.noMotivoCancelamento = jsonObject.noMotivoCancelamento;
    // #region props auxiliares
    this.flgOrdenar = jsonObject.flgOrdenar;
    // #endregion
  }

  get dayFormatted() {
    return new Date(this.dtDocumento).getDate();
  }

  get monthFormatted() {
    return new Date(this.dtDocumento).toLocaleString('pt-BT', {
      month: 'long',
    });
  }

  get yearFormatted() {
    return new Date(this.dtDocumento).getFullYear();
  }

  toJson = () => JSON.stringify(this);
}
