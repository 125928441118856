import React, { useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  CSDSelPage,
  DatePicker,
  GridView,
  RadioButton,
  Textbox,
} from 'ui/components';
import { BootstrapSizes, PageTypes } from 'ui/Helpers/utils';
import { Message, Page, GridviewColumns } from 'ui/Helpers/interfaces';
import {
  ColumnTypes,
  Theme,
  ResponseStatus,
  ColumnDataTypes,
} from 'ui/Helpers/enums';

import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';

import {
  getControleMercadoriaPagined,
  deleteAllControleMercadoria,
} from 'core/services/FRO/controleMercadoria';
import { ControleMercadoriaFilters } from 'core/interfaces/FRO/controleMercadoria';

import {
  getRemetenteAutoComplete,
  getDestinatarioAutoComplete,
} from 'core/services/FRO/remetente_Destinatario';
import { ColetaDestinatario, ColetaRemetente } from 'core/models/FRO';
import { Empresa } from 'core/models/SEG';

import { getProdutoAgrupadorAutoComplete } from 'core/services/EST/produtoAgrupador';

const columns: Array<GridviewColumns> = [
  { key: 'nrSeqControleMercadoria', type: ColumnTypes.Checkbox },
  { key: 'nrSeqControleMercadoria', title: 'Código' },
  { key: 'empresa.noEmpresa', title: 'Empresa' },
  { key: 'dtCad', title: 'Data', format: 5 },
  { key: 'noTipo', title: 'Tipo', format: 0 },
  { key: 'freteMercadoria.noFreteMercadoria', title: 'Frete Mercadoria' },
  { key: 'cliente.noPessoa', title: 'Cliente', format: 0 },
  { key: 'noPlacaEntrega', title: 'Veículo Chegada', format: 0 },
  { key: 'veiculo.noVeiculo', title: 'Veículo Saída', format: 0 },
  { key: 'motorista.noPessoa', title: 'Motorista', format: 0 },
  { key: 'vlrFrete', title: 'Valor Frete', format: ColumnDataTypes.Decimal },
  { key: 'flgExigeConferencia', title: 'Exige Conf.', format: 3 },
  {
    key: 'dtConferencia',
    title: 'Data Conf.',
    format: 5,
  },
  {
    key: 'noStatusConferencia',
    title: 'Status Conferência',
    format: 0,
    colorText: 'noColorStatusConferencia',
  },
];

export default function ControleEmbarqueMercadorias({
  transaction,
  onOpenPage,
  isActive,
}: Page) {
  const gridView = useRef<any>();

  const [filtros, setFiltros] = useState<ControleMercadoriaFilters | any>({
    noTipo: 'D',
    noStatusConferencia: 'Em conferência',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const statusControleMercadoria = [
    { text: 'Em conferência', value: 'Em conferência' },
    { text: 'Com pendência', value: 'Com pendência' },
    { text: 'Conferência OK', value: 'Conferência OK' },
    { text: 'Não precisa conferir', value: 'Não precisa conferir' },
    { text: 'Todos', value: 'Todos' },
  ];

  const search = async (): Promise<void> => {
    setLoading(true);

    const { data, pagination } = await getControleMercadoriaPagined({
      noTipo: 'D',
      ...filtros,
    });

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load(): Promise<void> {
      setLoading(true);

      const { data, pagination } = await getControleMercadoriaPagined({
        tipo: 'D',
        noStatusConferencia: 'Em conferência',
        ...filtros,
      });

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (SortBy: number): Promise<void> => {
    setFiltros({ ...filtros, SortBy });
    await search();
  };
  const onPageSizeChange = async (totalByPage: number): Promise<void> => {
    setFiltros({ ...filtros, totalByPage });
    await search();
  };
  const onPageChange = async (page: any): Promise<void> => {
    setFiltros({ ...filtros, page });
    await search();
  };
  const onOpenMaintenance = (registryKey = null): void => {
    onOpenPage(
      transaction.noTransacao,
      'ControleMercadoriaDesembarque/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow: any): void => {
    const nrSeqProfissao = sourceRow.find(
      (e: any) => e.key === 'nrSeqControleMercadoria'
    );
    onOpenMaintenance(nrSeqProfissao.value);
  };

  const onAutoCompleteEmpresa = async (e: string) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    return empresas;
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllControleMercadoria(
        selecteds.map((row: any) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onAutoCompleteRemetente = async (e: string) => {
    const {
      status,
      message: msg,
      data: remetentes,
    } = await getRemetenteAutoComplete({
      noPessoa: e,
    });

    if (msg) setMessage({ theme: status, message: msg });

    return remetentes;
  };

  const onSearchProdutoAgrupador = async (e) => {
    const {
      status,
      message: msg,
      data: produtoAgrupador,
    } = await getProdutoAgrupadorAutoComplete({
      NoSearchField: e,
      nrSeqProdutoGrupo: filtros.nrSeqProdutoGrupo,
      nrSeqAlmoxarifado: filtros.nrSeqAlmoxarifado,
    });
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    return produtoAgrupador;
  };

  const onAutoCompleteDestinatario = async (e: string) => {
    const {
      status,
      message: msg,
      data: destinatarios,
    } = await getDestinatarioAutoComplete({
      flgTodos: true,
      noPessoa: e,
    });

    if (msg) setMessage({ theme: status, message: msg });

    return destinatarios;
  };

  const tipo = [
    {
      text: 'Desembarque',
      value: 'D',
    },
  ];
  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Mercadorias'
      loading={loading}
      onSearch={search}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-4'>
          <RadioButton
            label='Tipo'
            outline
            disabled={false}
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={filtros.noTipo}
            buttons={tipo}
            onChange={(noTipo: string) => {
              setFiltros({
                ...filtros,
                noTipo,
              });
            }}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-5'>
          <Autocomplete
            label='Empresa'
            searchDataSource={onAutoCompleteEmpresa}
            selectedItem={'empresa' in filtros ? filtros.empresa : null}
            onSelectItem={async (empresa: Empresa) => {
              setFiltros({
                ...filtros,
                empresa,
                nrSeqEmpresa: empresa.nrSeqEmpresa,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Remetente'
            searchDataSource={onAutoCompleteRemetente}
            selectedItem={filtros.remetente}
            onSelectItem={async (remetente: ColetaRemetente) => {
              setFiltros({
                ...filtros,
                remetente,
                nrSeqPessoaRem: remetente.nrSeqPessoaRem,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>

        <div className='col-4'>
          <Autocomplete
            label='Destinatário'
            searchDataSource={onAutoCompleteDestinatario}
            selectedItem={filtros.destinatario}
            onSelectItem={async (destinatario: ColetaDestinatario) => {
              setFiltros({
                ...filtros,
                destinatario,
                nrSeqPessoaDes: destinatario.nrSeqPessoaDes,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-4 '>
          {/* @ts-expect-error */}
          <Textbox
            label='Número Nota'
            text={filtros.nrForNf}
            onChangedValue={(nrForNf: string) => {
              setFiltros({ ...filtros, nrForNf });
            }}
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Cadastro De'
            text={filtros.dtCadInicial}
            onChange={(dtCadInicial: any) =>
              setFiltros({ ...filtros, dtCadInicial })
            }
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Cadastro Até'
            text={filtros.dtCadFinal}
            onChange={(dtCadFinal: any) =>
              setFiltros({ ...filtros, dtCadFinal })
            }
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Produto'
            searchDataSource={onSearchProdutoAgrupador}
            selectedItem={filtros.produtoAgrupador}
            onSelectItem={(produtoAgrupador: any) => {
              setFiltros({
                ...filtros,
                produtoAgrupador,
                nrSeqProdutoAgrupador: produtoAgrupador.nrSeqProdutoAgrupador,
              });
            }}
            dataSourceTextProperty='noProduto'
          />
        </div>

        <div className='col-5'>
          <RadioButton
            outline
            label='Status Conferência'
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            buttons={statusControleMercadoria}
            selectedButton={filtros.noStatusConferencia}
            onChange={(noStatusConferencia: string) =>
              setFiltros({ ...filtros, noStatusConferencia })
            }
          />
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            dataColumns={columns}
            onColumnSort={onColumnSort}
            enableExcelExport
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
