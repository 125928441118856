import React, { useRef, useState } from 'react';
import { ResponseStatus, Theme, BootstrapSizes } from 'ui/Helpers/utils';
import {
  getConsultaMovimentoEstoquePaged,
  printConsultaMovEstoque,
  excelConsultaMovimentos,
} from 'core/services/EST/estoqueMovimento';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  CSDSelPage,
  GridView,
  Autocomplete,
  RadioButton,
  DatePicker,
  Panel,
  Switch,
  Textbox,
  ToolbarButtons,
} from 'ui/components';
import { getAlmoxarifadoAutoComplete } from 'core/services/EST/almoxarifado';
import { getProdutoGrupoAutoComplete } from 'core/services/EST/produtoGrupo';
import { getEstoqueTipoAutoComplete } from 'core/services/EST/estoqueTipo';
import { getProdutoAutoComplete } from 'core/services/EST/produto';
import { getFornecedorAutoComplete } from 'core/services/FIN/fornecedor';
import { getAlmoxarifadoLocalAutoComplete } from 'core/services/EST/almoxarifadoLocal';
import ModalProdutoAgrupadoDetalhado from 'ui/pages/EST/ConsultaMovimentacaoEstoque/modalProdutoAgrupadoDetalhado';

export default function ConsultaMovimentacaoEstoque({
  transaction,
  isActive,
  findTransaction,
  onOpenTransaction,
  onOpenReport,
}) {
  const gridView = useRef();
  const gridViewGroup = useRef();
  const ProdutoAgrupadoDetalhadoRef = useRef({});

  const [filtros, setFiltros] = useState({
    statusProduto: 'T',
    flgAgrupaMovAlmoxarifado: false,
  });
  const [isVisible, setIsVisible] = useState({ visible: false, group: false });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [
    modalProdutoAgrupadoDetalhado,
    setModalProdutoAgrupadoDetalhado,
  ] = useState({
    show: false,
  });

  const StatusProdutos = [
    { text: 'Todos ', value: 'T' },
    { text: 'Ativos ', value: 'A' },
    { text: 'Inativos', value: 'I' },
    { text: 'Saldo Negativo', value: 'N' },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params = null) => {
    setLoading(true);

    const objFiltros = { ...filtros };

    if (filtros?.statusProduto === 'N') {
      setFiltros({ ...filtros, flgAgrupaMovAlmoxarifado: true });
      objFiltros.flgAgrupaMovAlmoxarifado = true;
    }
    if (!objFiltros.nrSeqAlmoxarifado) {
      onSetMessage(false, 'Necessário selecionar o Almoxarifado.');
    } else {
      const {
        status,
        message: msg,
        data,
        pagination,
      } = await getConsultaMovimentoEstoquePaged(params ?? objFiltros);

      if (status !== ResponseStatus.Success) {
        onSetMessage(status, msg);
        gridView?.current?.setDataSource([]);
        gridViewGroup?.current?.setDataSource([]);
      } else {
        if (data?.length > 0)
          setIsVisible({
            visible: true,
            group: objFiltros?.flgAgrupaMovAlmoxarifado,
          });

        if (objFiltros.flgAgrupaMovAlmoxarifado) {
          if (gridViewGroup && gridViewGroup.current)
            gridViewGroup.current.setDataSource(data, pagination);
        } else {
          gridView?.current?.setDataSource(data, pagination);
        }
      }
    }

    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  const onSearchAlmoxarifadoAutoComplete = async (e) => {
    const {
      status,
      message: msg,
      data: almoxarifados,
    } = await getAlmoxarifadoAutoComplete({
      noAlmoxarifado: e,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return almoxarifados;
  };

  const onSearchProdutoGrupo = async (e) => {
    const {
      status,
      message: msg,
      data: produtoGrupo,
    } = await getProdutoGrupoAutoComplete({
      noProdutoGrupo: e,
    });
    if (msg) onSetMessage(status, msg);
    return produtoGrupo;
  };

  const onSearchEstoqueTipo = async (e) => {
    const {
      status,
      message: msg,
      data: estoqueTipo,
    } = await getEstoqueTipoAutoComplete({ noEstoqueTipo: e });
    if (msg) onSetMessage(status, msg);
    return estoqueTipo;
  };

  const onSearchProduto = async (e) => {
    const {
      status,
      message: msg,
      data: produtos,
    } = await getProdutoAutoComplete({
      noSearchField: !filtros.flgBuscaCodigoPeca ? e : null,
      flgAtivo: true,
      cdProdutoCodigo: filtros.flgBuscaCodigoPeca ? e : null,
      nrSeqAlmoxarifado: filtros.nrSeqAlmoxarifado,
    });
    if (msg) onSetMessage(status, msg);
    return produtos;
  };

  const onSearchInfoCompleteProduto = async (produto) => {
    setLoading(true);

    if (produto?.nrSeqProduto > 0) {
      const {
        almoxarifados: almoxarifadoLocal,
      } = await getAlmoxarifadoLocalAutoComplete({
        nrSeqAlmoxarifado: filtros.nrSeqAlmoxarifado,
        nrSeqProduto: produto.nrSeqProduto,
        flgAtivo: true,
      });

      const infoproduto = {
        produto: {
          nrSeqProduto: produto.nrSeqProduto,
          noProduto: produto.noProduto,
          nrSeqProdutoAgrupador: produto.nrSeqProdutoAgrupador,
          nrSeqProdutoUnidadeMedida: produto.nrSeqProdutoUnidadeMedida,
        },
        nrSeqProduto: produto.nrSeqProduto,
      };

      if (almoxarifadoLocal?.length > 0) {
        if (almoxarifadoLocal.length === 1) {
          const [almoxarifadol] = almoxarifadoLocal;
          infoproduto.almoxarifadoLocal = almoxarifadol;
          infoproduto.nrSeqAlmoxarifadoLocal =
            almoxarifadol.nrSeqAlmoxarifadoLocal;
        } else infoproduto.almoxarifadoLocal = almoxarifadoLocal;
      }

      setFiltros({ ...filtros, ...infoproduto });
    } else {
      setFiltros({
        ...filtros,
        produto: null,
        almoxarifadoLocal: null,
        nrSeqAlmoxarifadoLocal: null,
        nrSeqProduto: null,
      });
    }
    setLoading(false);
  };

  const onSearchAlmoxarifadoLocal = async (e) => {
    const {
      status,
      message: msg,
      almoxarifados: almoxarifadoLocal,
    } = await getAlmoxarifadoLocalAutoComplete({
      noAlmoxarifado: e,
      nrSeqAlmoxarifado: filtros.nrSeqAlmoxarifado,
      nrSeqProduto: filtros.nrSeqProduto,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return almoxarifadoLocal;
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printConsultaMovEstoque(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDownloadExcel = async () => {
    setLoading(true);

    if (filtros.dataInicial) {
      const { value: base64 } = await excelConsultaMovimentos(filtros);
      if (base64) {
        const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = 'ConsultaMovimentos.xls';
        downloadLink.click();
      }
    } else {
      onSetMessage(false, 'Preencha os filtros de data para gerar o relatório');
    }

    setLoading(false);
  };

  const onClickOpenDocMovimento = async (selectedValue) => {
    if (selectedValue.estoqueTipo?.cdTransacao !== (null || '')) {
      const idDocMovimento =
        selectedValue.nrSeqDocumento > 0
          ? selectedValue.nrSeqDocumento
          : Number(selectedValue.nrDoc);

      setLoading(true);

      const transactionMovimento = findTransaction(
        selectedValue.estoqueTipo?.cdTransacao
      );

      if (transactionMovimento) {
        transactionMovimento.registryKey = idDocMovimento;

        onOpenTransaction(transactionMovimento, true);
      }
      setLoading(false);
    } else {
      onSetMessage(
        false,
        'Não foi possível encontrar a transação para o movimento selecionado'
      );
    }
  };
  const onClickModalProdutoAgrupadoDetalhado = async (e, datasource) => {
    const { nrSeqAlmoxarifado, dataInicial, dataFinal } = filtros;
    const gridItem = datasource.find((el) => el === e);
    const item = {
      nrSeqAlmoxarifado,
      dataInicial,
      dataFinal,
      nrSeqProduto: gridItem.produto.nrSeqProduto,
    };

    ProdutoAgrupadoDetalhadoRef.current = item;
    setModalProdutoAgrupadoDetalhado({
      show: true,
    });
  };
  const closeModalProdutoAgrupadoDetalhado = () => {
    setModalProdutoAgrupadoDetalhado({ show: false });
  };

  const columns = [
    {
      key: 'nrSeqEstoqueMovimento',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'dtMovimento',
      title: 'Data Movimento',
      sortKey: 'dtMovimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'produto.noProduto',
      title: 'Produto',
      sortKey: 'noProduto',
      format: GridView.DataTypes.Text,
    },
    {
      key: 'produto.cdProdutoUnidadeMedida',
      title: 'Unid. Medida',
      sortKey: 'cdProdutoUnidadeMedida',
      visible: false,
    },
    {
      key: 'almoxarifadoLocal.noAlmoxarifadoLocal',
      title: 'Almoxarifado',
      sortKey: 'noAlmoxarifadoLocal',
      format: GridView.DataTypes.Text,
    },
    {
      key: 'noPessoaForCli',
      title: 'Fornecedor/ Cliente',
    },
    {
      key: 'placa',
      title: 'Placa',
      visible: false,
    },
    {
      key: 'estoqueTipo.noEstoqueTipo',
      title: 'Tipo',
      sortKey: 'noEstoqueTipo',
    },
    { key: 'nrDoc', title: 'Nr Documento' },
    { key: 'obsMov', title: 'Observação' },
    {
      key: 'estoqueTipo.noSinal',
      title: 'Sinal',
      sortable: false,
    },
    {
      key: 'quantidade',
      title: 'Quantidade',
      format: GridView.DataTypes.Decimal,
    },

    {
      key: 'vlrUnit',
      title: 'Valor Unitário',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrTotal',
      title: 'Valor Total',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'saldo',
      title: 'Saldo',
      format: GridView.DataTypes.Decimal,
      sortable: false,
    },
    {
      key: 'nrSeqEstoqueMovimento',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickOpenDocMovimento(e),
      theme: Theme.Success,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Exibir Detalhes do Movimento',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsGroup = [
    {
      key: 'nrSeqAlmoxarifadoLocal',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    {
      key: 'dtMovimento',
      title: '1° Movimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrMovimentos',
      title: 'Nr. Movimentos',
      sortable: false,
      format: GridView.DataTypes.Integer,
    },
    {
      key: 'produto.noProduto',
      title: 'Produto',
      format: GridView.DataTypes.Text,
      sortKey: 'noProduto',
      sortable: false,
    },
    {
      key: 'produto.cdProdutoUnidadeMedida',
      title: 'Unid. Medida',
      sortKey: 'cdProdutoUnidadeMedida',
      sortable: false,
      visible: false,
    },
    {
      key: 'almoxarifadoLocal.noAlmoxarifadoLocal',
      title: 'Almoxarifado',
      sortKey: 'noAlmoxarifadoLocal',
      sortable: false,
    },
    {
      key: 'quantidadeInicial',
      title: 'Quantidade Inicial',
      format: GridView.DataTypes.Decimal,
      visible: false,
    },
    {
      key: 'quantidadeEntrada',
      title: 'Quantidade Entrada',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'quantidadeSaida',
      title: 'Quantidade Saída',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrUnit',
      title: 'Maior Valor Unitário',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrSaldoTotal',
      title: 'Valor Saldo Total',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'saldo',
      title: 'Saldo',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'nrSeqAlmoxarifadoLocal',
      type: GridView.ColumnTypes.Button,
      onClick: (e, dataSource) =>
        onClickModalProdutoAgrupadoDetalhado(e, dataSource),
      theme: Theme.Success,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Exibir Detalhes do Movimento',
      tooltipDirection: 'bottom',
    },
  ];

  const onSearchFornecedor = async (e) => {
    const {
      status,
      message: msg,
      data: fornecedores,
    } = await getFornecedorAutoComplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);

    return fornecedores;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Consulta de Movimentação de Estoque'
      loading={loading}
      onSearch={() => search()}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Excel'
          icon='file-excel'
          onClick={onDownloadExcel}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col'>
          <Panel>
            <Panel.Header
              icon={['fas', 'user']}
              title='Filtros'
              theme={Theme.Primary}
            />
            <Panel.Body>
              <div className='row mt-3'>
                <div className='col-4 mb-3'>
                  <Autocomplete
                    label='Almoxarifado'
                    searchDataSource={onSearchAlmoxarifadoAutoComplete}
                    required
                    selectedItem={filtros.almoxarifado}
                    onSelectItem={(almoxarifado) => {
                      setFiltros({
                        ...filtros,
                        almoxarifado,
                        nrSeqAlmoxarifado: almoxarifado.nrSeqAlmoxarifado,
                        noAlmoxarifado: almoxarifado.noAlmoxarifado,
                      });
                    }}
                    dataSourceTextProperty='noAlmoxarifado'
                  />
                </div>

                <div className='col-4'>
                  <div className='row'>
                    <div className='col-6 mb-3'>
                      <DatePicker
                        label='Data Inicial'
                        required
                        text={filtros.dataInicial}
                        maxLength={10}
                        mask={MaskTypes.Date}
                        onChange={(dataInicial) =>
                          setFiltros({ ...filtros, dataInicial })
                        }
                      />
                    </div>

                    <div className='col-6 mb-3'>
                      <DatePicker
                        label='Data Final'
                        text={filtros.dataFinal}
                        maxLength={10}
                        mask={MaskTypes.Date}
                        onChange={(dataFinal) =>
                          setFiltros({ ...filtros, dataFinal })
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className='col-2 mb-3'>
                  <Switch
                    formControl
                    label='Buscar por Cód. Produto'
                    size={BootstrapSizes.Medium}
                    checked={filtros.flgBuscaCodigoPeca}
                    onChange={(flgBuscaCodigoPeca) =>
                      setFiltros({ ...filtros, flgBuscaCodigoPeca })
                    }
                  />
                </div>

                <div className='col-2 mb-3'>
                  <Switch
                    formControl
                    label='Agrupar Por Produto'
                    size={BootstrapSizes.Medium}
                    checked={filtros.flgAgrupaMovAlmoxarifado}
                    onChange={(flgAgrupaMovAlmoxarifado) =>
                      setFiltros({ ...filtros, flgAgrupaMovAlmoxarifado })
                    }
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col-4 mb-3'>
                  <Autocomplete
                    label='Produto'
                    enabled={!!filtros.nrSeqAlmoxarifado}
                    searchDataSource={onSearchProduto}
                    selectedItem={filtros.produto}
                    onSelectItem={(produto) => {
                      onSearchInfoCompleteProduto(produto);
                    }}
                    dataSourceTextProperty='noProduto'
                  />
                </div>
                <div className='col-4 mb-3'>
                  <Autocomplete
                    label='Local'
                    searchDataSource={onSearchAlmoxarifadoLocal}
                    selectedItem={filtros.almoxarifadoLocal}
                    onSelectItem={(almoxarifadoLocal) => {
                      setFiltros({
                        ...filtros,
                        almoxarifadoLocal,
                        nrSeqAlmoxarifadoLocal:
                          almoxarifadoLocal.nrSeqAlmoxarifadoLocal,
                      });
                    }}
                    dataSourceTextProperty='noAlmoxarifadoLocal'
                  />
                </div>

                <div className='col-4 justify-content-sm-center mb-3'>
                  <RadioButton
                    outline
                    label='Status Produtos'
                    size={BootstrapSizes.Small}
                    theme={Theme.Primary}
                    buttons={StatusProdutos}
                    selectedButton={filtros.statusProduto}
                    onChange={(status) => {
                      if (status === 'N') {
                        setFiltros({
                          ...filtros,
                          statusProduto: status,
                          flgAgrupaMovAlmoxarifado: true,
                        });
                      } else {
                        setFiltros({
                          ...filtros,
                          statusProduto: status,
                        });
                      }
                    }}
                  />
                </div>
              </div>

              <div className='row'>
                <div className='col-4 mb-3'>
                  <Autocomplete
                    label='Estoque Tipo'
                    searchDataSource={onSearchEstoqueTipo}
                    selectedItem={filtros.estoqueTipo}
                    onSelectItem={(estoqueTipo) => {
                      setFiltros({
                        ...filtros,
                        estoqueTipo,
                        nrSeqEstoqueTipo: estoqueTipo.nrSeqEstoqueTipo,
                        noEstoqueTipo: estoqueTipo.noEstoqueTipo,
                      });
                    }}
                    dataSourceTextProperty='noEstoqueTipo'
                  />
                </div>
                <div className='col-4 mb-3'>
                  <Textbox
                    maxLength={80}
                    label='Observação'
                    text={filtros.obsMov}
                    onChangedValue={(obsMov) =>
                      setFiltros({
                        ...filtros,
                        obsMov,
                      })
                    }
                  />
                </div>

                <div className='col-3 mb-3'>
                  <Autocomplete
                    label='Grupo'
                    searchDataSource={onSearchProdutoGrupo}
                    selectedItem={filtros.produtoGrupo}
                    onSelectItem={(produtoGrupo) => {
                      setFiltros({
                        ...filtros,
                        produtoGrupo,
                        nrSeqProdutoGrupo: produtoGrupo.nrSeqProdutoGrupo,
                      });
                    }}
                    dataSourceTextProperty='noProdutoGrupo'
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-5'>
                  <Autocomplete
                    label='Forecedor'
                    searchDataSource={onSearchFornecedor}
                    selectedItem={filtros.fornecedor}
                    onSelectItem={(fornecedor) => {
                      setFiltros({
                        ...filtros,
                        fornecedor,
                        nrSeqPessoaFor: fornecedor.nrSeqPessoaFor,
                      });
                    }}
                    dataSourceTextProperty='noPessoa'
                  />
                </div>
                <div className='col-2'>
                  <Switch
                    formControl
                    label='Somente com Saldo'
                    size={BootstrapSizes.Medium}
                    checked={filtros.flgSomenteComSaldo}
                    onChange={(flgSomenteComSaldo) => {
                      setFiltros({
                        ...filtros,
                        flgSomenteComSaldo,
                      });
                    }}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      {isVisible.visible && !isVisible?.group && (
        <div className='row'>
          <div className='col'>
            <GridView
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
              onColumnSort={onColumnSort}
              onPageSizeChange={onPageSizeChange}
              onPageChange={onPageChange}
              canControlVisibility
              transaction={transaction}
              sumFields
            />
          </div>
        </div>
      )}
      {isVisible.visible && isVisible?.group && (
        <div className='row'>
          <div className='col'>
            <GridView
              ref={gridViewGroup}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columnsGroup}
              offlineData
              sortOffline
              showSelectSizes={false}
              showPagination
              canControlVisibility
              transaction={transaction}
              sumFields
            />
          </div>
        </div>
      )}
      <ModalProdutoAgrupadoDetalhado
        show={modalProdutoAgrupadoDetalhado.show}
        ProdutoAgrupadoDetalhadoRef={ProdutoAgrupadoDetalhadoRef.current}
        onClose={closeModalProdutoAgrupadoDetalhado}
        transaction={transaction}
      />
    </CSDSelPage>
  );
}
