import React, { useEffect } from 'react';
import { Panel, Switch, Autocomplete, Textbox } from 'ui/components';
// @ts-expect-error
import { Theme } from 'ui/Helpers/enums.ts';
import { getConsignatarioAutoComplete } from 'core/services/FRO/coletaConsignatario';
import { getColetaTipoDocumentoAutoComplete } from 'core/services/FRO/coletaTipoDocumento';
import { getTipoEmbarqueAutoComplete } from 'core/services/FRO/tipoEmbarque';
import { getTipoFreteAutoComplete } from 'core/services/FRO/tipoFrete';
import { getEspecieFreteAutoComplete } from 'core/services/FRO/especieFrete';
import { getFreteMercadoriaAutoComplete } from 'core/services/FRO/freteMercadoria';

interface Props {
  remetenteData: any;
  setRemetenteData: (object: any) => void;
}

const RemetentePanel = ({ remetenteData, setRemetenteData }: Props) => {
  useEffect(() => {
    const objTransportadora = { ...remetenteData };

    setRemetenteData(objTransportadora);
  }, [remetenteData.nrSeqPessoaRem]);

  const onSearchTipoDocumento = async (e: any) => {
    const {
      data: coletaTiposDocumento,
    } = await getColetaTipoDocumentoAutoComplete({ noTipoDocumento: e });

    return coletaTiposDocumento;
  };

  const onSearchPessoaConsig = async (e: any) => {
    const { data: pessoaConsigs } = await getConsignatarioAutoComplete({
      noPessoaConsig: e,
    });

    return pessoaConsigs;
  };

  const onAutoCompleteTipoEmbarque = async (e: any) => {
    const { data: tiposEmbarque } = await getTipoEmbarqueAutoComplete({
      noTipoEmbarque: e,
    });

    return tiposEmbarque;
  };

  const onAutoCompleteTipoFrete = async (e: any) => {
    const { tiposFrete } = await getTipoFreteAutoComplete({
      noTipoFrete: e,
    });

    return tiposFrete;
  };

  const onSearchEspecieFrete = async (e: string) => {
    const { data: especiesFrete } = await getEspecieFreteAutoComplete({
      noEspecieFrete: e,
    });

    return especiesFrete;
  };

  const onSearchFreteMercadoria = async (e: any) => {
    const { data: freteMercadorias } = await getFreteMercadoriaAutoComplete({
      noFreteMercadoria: e,
    });

    return freteMercadorias;
  };

  return (
    <div className='col mt-3'>
      {/* @ts-expect-error */}
      <Panel>
        {/* @ts-expect-error */}
        <Panel.Header title='Remetente' theme={Theme.Primary} />

        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-1 mb-3'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                label='Ativo'
                checked={remetenteData.flgAtivo}
                onChange={(flgAtivo: boolean) =>
                  setRemetenteData({ ...remetenteData, flgAtivo })
                }
              />
            </div>
            <div className='col-3 mb-3'>
              <Autocomplete
                maxLength={3}
                label='Tipo de Documento Padrão'
                searchDataSource={onSearchTipoDocumento}
                selectedItem={remetenteData.coletaTipoDocumento}
                onSelectItem={(coletaTipoDocumento: any) => {
                  setRemetenteData({
                    ...remetenteData,
                    coletaTipoDocumento,
                    cdTipoDocumento: coletaTipoDocumento.cdTipoDocumento,
                  });
                }}
                dataSourceTextProperty='noTipoDocumento'
              />
            </div>
            <div className='col-6 mb-3'>
              <Autocomplete
                label='Consignatário'
                searchDataSource={onSearchPessoaConsig}
                selectedItem={remetenteData.coletaConsignatario}
                onSelectItem={(coletaConsignatario: any) => {
                  setRemetenteData({
                    ...remetenteData,
                    coletaConsignatario,
                    nrSeqPessoaConsig: coletaConsignatario.nrSeqPessoaConsig,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-4'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={remetenteData?.flgRemessa}
                label='Obriga Remessa?'
                onChange={(flgRemessa: boolean) =>
                  setRemetenteData({
                    ...remetenteData,
                    flgRemessa,
                  })
                }
              />
            </div>
            <div className='col-4'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={remetenteData?.flgAdiantamentoPedagio}
                label='Adiantamento Pedágio'
                onChange={(flgAdiantamentoPedagio: boolean) =>
                  setRemetenteData({
                    ...remetenteData,
                    flgAdiantamentoPedagio,
                  })
                }
              />
            </div>
            <div className='col-4'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={remetenteData?.flgOrdemTransporte}
                label='Obriga Ordem Transporte?'
                onChange={(flgOrdemTransporte: boolean) =>
                  setRemetenteData({
                    ...remetenteData,
                    flgOrdemTransporte,
                  })
                }
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-4'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={remetenteData?.flgPesoLiquidoMercad}
                label='Apropriar pelo peso líquido das mercadorias'
                onChange={(flgPesoLiquidoMercad: boolean) =>
                  setRemetenteData({
                    ...remetenteData,
                    flgPesoLiquidoMercad,
                  })
                }
              />
            </div>
            <div className='col-4'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={remetenteData?.flgIncluirCodProdutoObserv}
                label='Incluir código dos produtos nas observações do CTE'
                onChange={(flgIncluirCodProdutoObserv: boolean) =>
                  setRemetenteData({
                    ...remetenteData,
                    flgIncluirCodProdutoObserv,
                  })
                }
              />
            </div>
            <div className='col-4'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={remetenteData?.flgEdi}
                label='Remetente EDI'
                onChange={(flgEdi: boolean) =>
                  setRemetenteData({
                    ...remetenteData,
                    flgEdi,
                  })
                }
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-4'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={remetenteData?.flgFreteMercadoriaDaNota}
                label='Produto Mercadoria da Nota'
                onChange={(flgFreteMercadoriaDaNota: boolean) =>
                  setRemetenteData({
                    ...remetenteData,
                    flgFreteMercadoriaDaNota,
                  })
                }
              />
            </div>
            <div className='col-4'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                checked={remetenteData?.flgBuscaQtdProdutos}
                label='Apropriar pela Quantidade dos Produtos da Nota'
                onChange={(flgBuscaQtdProdutos: boolean) => {
                  setRemetenteData({
                    ...remetenteData,
                    flgBuscaQtdProdutos,
                  });
                }}
              />
            </div>
            <div className='col-4 mb-3'>
              {/* @ts-expect-error */}
              <Switch
                formControl
                label='Não Incluir Valor XML (xCaracSer)'
                checked={remetenteData.flgRetiraInfoCaracServXmlCTE}
                onChange={(flgRetiraInfoCaracServXmlCTE: boolean) =>
                  setRemetenteData({
                    ...remetenteData,
                    flgRetiraInfoCaracServXmlCTE,
                  })
                }
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-4'>
              {/* @ts-expect-error */}
              <Textbox
                label='Padrão OT Na Nota'
                id='txtPadraoOTNota'
                text={remetenteData?.noPadraoOrdemTransporteNota}
                onChangedValue={(noPadraoOrdemTransporteNota: string) =>
                  setRemetenteData({
                    ...remetenteData,
                    noPadraoOrdemTransporteNota,
                  })
                }
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-3'>
              <Autocomplete
                label='Tipo Embarque'
                searchDataSource={onAutoCompleteTipoEmbarque}
                selectedItem={remetenteData.tipoEmbarque}
                onSelectItem={(tipoEmbarque: any) => {
                  setRemetenteData({
                    ...remetenteData,
                    tipoEmbarque,
                    nrSeqTipoEmbarqueRemetente: tipoEmbarque.nrSeqTipoEmbarque,
                  });
                }}
                dataSourceTextProperty='noTipoEmbarque'
              />
            </div>
            <div className='col-3'>
              <Autocomplete
                label='Tipo Frete'
                searchDataSource={onAutoCompleteTipoFrete}
                selectedItem={remetenteData.tipoFrete}
                onSelectItem={(tipoFrete: any) => {
                  setRemetenteData({
                    ...remetenteData,
                    tipoFrete,
                    nrSeqTipoFreteRemetente: tipoFrete.nrSeqTipoFrete,
                  });
                }}
                dataSourceTextProperty='noTipoFrete'
              />
            </div>
            <div className='col-3'>
              <Autocomplete
                label='Espécie Frete'
                searchDataSource={onSearchEspecieFrete}
                selectedItem={remetenteData.especieFrete}
                onSelectItem={(especieFrete: any) => {
                  setRemetenteData({
                    ...remetenteData,
                    especieFrete,
                    nrSeqEspecieFreteRemetente: especieFrete.nrSeqEspecieFrete,
                  });
                }}
                dataSourceTextProperty='noEspecieFrete'
              />
            </div>
            <div className='col-3'>
              <Autocomplete
                label='Frete Mercadoria'
                searchDataSource={onSearchFreteMercadoria}
                selectedItem={remetenteData.freteMercadoria}
                onSelectItem={(freteMercadoria: any) => {
                  setRemetenteData({
                    ...remetenteData,
                    freteMercadoria,
                    nrSeqFreteMercadoriaRemetente:
                      freteMercadoria.nrSeqFreteMercadoria,
                  });
                }}
                dataSourceTextProperty='noFreteMercadoria'
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </div>
  );
};

export default RemetentePanel;
