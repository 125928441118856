import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import EnergiaContaConsumo from 'core/models/ENE/energiaContaConsumo';
import EnergiaConta, { formatAnoMesRef } from 'core/models/ENE/energiaConta';
import { Button, GridView, Modal, Notification, Textbox } from 'ui/components';
import {
  BootstrapSizes,
  ColumnTypes,
  MaskTypes,
  StatusGrid,
  Theme,
} from 'ui/Helpers/enums';
import { GridviewColumns, Message } from 'ui/Helpers/interfaces';
import EnergiaUnidadeConsumidora from 'core/models/ENE/energiaUnidadeConsumidora';

interface IProps {
  data: EnergiaUnidadeConsumidora | {};
  setData: Dispatch<SetStateAction<EnergiaUnidadeConsumidora | {}>>;
  conta: EnergiaConta | undefined;
  setConta: Dispatch<SetStateAction<EnergiaConta | undefined>>;
  show: boolean;
  gridView: any;
  onClose: () => void;
}

export const ModalDetalhamentoConsumoFatura = ({
  data,
  setData,
  conta,
  setConta,
  gridView,
  onClose,
  show,
}: IProps) => {
  const [dias, setDias] = useState<number>(0);
  const [anoMes, setAnoMes] = useState<number>(0);
  const [message, setMessage] = useState<Message>();

  useEffect(() => {
    if (gridView && gridView.current && conta !== undefined) {
      gridView.current.setDataSource(conta.consumos);
    }

    setDias(0);
    setAnoMes(0);
  }, [show]);

  const onSetMessage = (msg: string, theme: string) => {
    setMessage({
      message: msg,
      theme,
    });
  };

  const handleChangeConsumo = (key: string, value: number) => {
    const consumos: EnergiaContaConsumo[] = gridView.current.getDataSource();

    consumos.forEach((item) => {
      if (item.anoMesRefFormated === key) {
        item.vlrConsumo = value;
      }
    });

    if (gridView && gridView.current) {
      gridView.current.setDataSource(consumos);
    }
  };

  const handleAdd = () => {
    if (conta) {
      if (!dias) {
        onSetMessage('Necessário informar quantidade de dias.', Theme.Warning);

        return;
      }

      if (!anoMes || anoMes.toString().length < 6) {
        onSetMessage(
          'Necessário informar ano/mês referência no formato esperado.',
          Theme.Warning
        );

        return;
      }

      if (
        anoMes &&
        (Number(anoMes.toString().slice(-2)) < 1 ||
          Number(anoMes.toString().slice(-2)) > 12)
      ) {
        onSetMessage(
          'Mês de referência precisa ser entre 01 e 12.',
          Theme.Warning
        );

        return;
      }

      const jaExiste = conta.consumos.find((c) => c.anoMesRef === anoMes);

      if (jaExiste) {
        onSetMessage(
          'Já existe um consumo para esse ano/mês referência.',
          Theme.Warning
        );

        return;
      }

      const consumo = new EnergiaContaConsumo({
        nrSeqEnergiaConsumo: 0,
        nrSeqEnergiaConta: 0,
        anoMesRef: anoMes,
        anoMesRefFormated: formatAnoMesRef(anoMes.toString()),
        nrDias: dias,
        vlrConsumo: 0,
        status: '',
        toJson: (): string => JSON.stringify(consumo),
      });

      gridView.current.setDataSource([
        ...gridView.current.getDataSource(),
        consumo,
      ]);

      setConta({ ...conta, consumos: [...conta.consumos, consumo] });

      if (data && 'contas' in data) {
        const todasContas = data.contas;

        todasContas.forEach((c) => {
          if (c.anoMesRef === conta.anoMesRef) {
            c.consumos = [...conta.consumos, consumo];
          }
        });

        setData({ ...data, contas: todasContas });
      }
    }
  };

  const onRemoveConsumo = (
    e: EnergiaContaConsumo,
    datasource: EnergiaContaConsumo[]
  ): void => {
    datasource = datasource.map((consumo) => {
      if (consumo.anoMesRef === e.anoMesRef) {
        consumo.status = StatusGrid.Remover;
      }

      return consumo;
    });

    if (gridView && gridView.current) {
      gridView.current.setDataSource(datasource);
    }

    if (conta) {
      setConta({ ...conta, consumos: datasource });

      if (data && 'contas' in data) {
        const todasContas = data.contas;

        todasContas.forEach((c) => {
          if (c.anoMesRef === conta.anoMesRef) {
            c.consumos = datasource;
          }
        });

        setData({ ...data, contas: todasContas });
      }
    }
  };

  const columnsConsumos: GridviewColumns[] = [
    {
      key: 'anoMesRefFormated',
      title: 'Ano/Mês Ref.',
      sortKey: 'anoMesRef',
      sortable: false,
    },
    { key: 'nrDias', title: 'Qtd. Dias Consumo', sortable: false },
    {
      key: 'vlrConsumo',
      title: 'Consumo (kWh)',
      sortable: false,
      type: conta !== undefined ? ColumnTypes.Textbox : undefined,
      onBlur: (key: string, value: number) => handleChangeConsumo(key, value),
    },
    {
      key: 'nrSeqEnergiaConsumo',
      title: 'Excluir',
      type: ColumnTypes.Button,
      onClick: (e: EnergiaContaConsumo, datasource: EnergiaContaConsumo[]) =>
        onRemoveConsumo(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      visible: conta !== undefined,
    },
  ];

  return (
    // @ts-expect-error
    <Modal
      show={show}
      title='Consumos da fatura'
      onClose={onClose}
      size={BootstrapSizes.Large}
    >
      <Notification
        floatable
        message={message?.message}
        theme={message?.theme}
        onClose={() => setMessage(undefined)}
      />
      {/* @ts-expect-error */}
      <Modal.Body>
        {conta !== undefined && (
          <div className='row mb-3'>
            <div className='col-4'>
              {/* @ts-expect-error */}
              <Textbox
                label='Qtd. Dias consumo'
                mask={MaskTypes.Integer}
                maxLength={2}
                text={dias}
                onChangedValue={(qtdDiasConsumo: number) =>
                  setDias(qtdDiasConsumo)
                }
                required
              />
            </div>
            <div className='col-4'>
              {/* @ts-expect-error */}
              <Textbox
                label='Ano/Mês Ref.'
                mask={MaskTypes.Integer}
                maxLength={6}
                text={anoMes}
                onChangedValue={(anoMesRef: number) => setAnoMes(anoMesRef)}
                required
                placeholder='Ex: 202501'
              />
            </div>
            <div className='col-4 align-self-end'>
              <Button
                text='Adicionar'
                icon='plus'
                theme={Theme.Primary}
                onClick={() => handleAdd()}
              />
            </div>
          </div>
        )}

        <GridView
          ref={gridView}
          // @ts-expect-error
          dataColumns={columnsConsumos}
          showSelectSizes={false}
          showPagination={false}
        />
      </Modal.Body>
    </Modal>
  );
};
