import React, { useState } from 'react';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  Autocomplete,
  Button,
  Loader,
  Modal,
  Notification,
  Textbox,
} from 'ui/components';
import { gerarLancamentosDmed } from 'core/services/ASS/lancamentoAssociado';
import { getConvenioAutoComplete } from 'core/services/ASS/convenio';

export default function ModalGerarDmed({ show, onClose }) {
  const [dataModalDmed, setDataModalDmed] = useState({});
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const onClickGerarDmed = async () => {
    if (!dataModalDmed.nrSeqConvenio) {
      setMessage({
        message: 'Necessário preencher o campo Convênio.',
        theme: Theme.Danger,
      });
      return;
    }
    if (!dataModalDmed.anoReferenciaAnoCalendario) {
      setMessage({
        message: 'Necessário preencher o Ano Base / Ano Referencia.',
        theme: Theme.Danger,
      });
      return;
    }

    setLoading(true);

    const { status, message: msg, value: base64 } = await gerarLancamentosDmed(
      dataModalDmed
    );
    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'LancamentosDmed.txt';
      downloadLink.click();

      setDataModalDmed({});
      onClose();
    }

    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    setLoading(false);
  };

  const onSearchConvenio = async (e) => {
    const { status, message: msg, convenios } = await getConvenioAutoComplete({
      noConvenio: e,
    });
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    return convenios;
  };

  return (
    <Modal
      show={show}
      title='Gerar Dmed'
      onClose={onClose}
      size={BootstrapSizes.Large}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row justify-content-between'>
          <div className='col-4 mb-3'>
            <Textbox
              maxLength={9}
              label='Ano Referencia/Ano Calendario'
              text={dataModalDmed.anoReferenciaAnoCalendario}
              mask={MaskTypes.anoReferenciaAnoCalendario}
              onChangedValue={(anoReferenciaAnoCalendario) => {
                setDataModalDmed({
                  ...dataModalDmed,
                  anoReferenciaAnoCalendario,
                });
              }}
            />
          </div>

          <div className='col-4 mb-3'>
            <Autocomplete
              label='Convênio'
              searchDataSource={onSearchConvenio}
              selectedItem={dataModalDmed.convenio}
              onSelectItem={(convenio) => {
                setDataModalDmed({
                  ...dataModalDmed,
                  convenio,
                  nrSeqConvenio: convenio.nrSeqConvenio,
                });
              }}
              dataSourceTextProperty='noConvenio'
            />
          </div>
        </div>

        <div className='row justify-content-end mt-3 mb-4'>
          <div className='col-3 mb-3'>
            <Button
              outline
              theme={Theme.Success}
              text='Gerar Dmed'
              icon='fa-solid fa-cloud-check'
              onClick={onClickGerarDmed}
            />
          </div>
          <div className='col-3 mb-3'>
            <Button
              outline
              text='Cancelar'
              theme={Theme.Danger}
              icon='fas fa-times'
              onClick={() => {
                setDataModalDmed({});
                onClose();
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
