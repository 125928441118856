import Cheque from './cheque';
import Extrato from './extrato';
// eslint-disable-next-line camelcase
export default class ChequeDeposito_Item {
  constructor(jsonObject = {}) {
    this.nrSeqChequeDeposito = jsonObject.nrSeqChequeDeposito;
    this.nrSeqCheque = jsonObject.nrSeqCheque;
    this.nrSeqExtrato = jsonObject.nrSeqExtrato;
    this.cheque = new Cheque(jsonObject.cheque ?? {});
    this.extrato = new Extrato(jsonObject.extrato ?? {});
  }

  toJson = () => JSON.stringify(this);
}
