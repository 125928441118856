import qs from 'qs';
import Pagination from 'core/models/pagination';
// eslint-disable-next-line camelcase
import ChequeDeposito_Item from 'core/models/FIN/chequeDeposito_Item';
import api from '../api';

const baseURL = `/FIN/ChequeDeposito_Item`;
// eslint-disable-next-line camelcase
export const getChequeDeposito_ItemList = async (filters) => {
  const response = await api.get(`/$ChequeDeposito_Item/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ChequeDeposito_Item(json)) ?? [],
  };
};
// eslint-disable-next-line camelcase
export const getChequeDeposito_ItemPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ChequeDeposito_Item(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};
// eslint-disable-next-line camelcase
export const getChequeDeposito_Item = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ChequeDeposito_Item(value) : {};
};
// eslint-disable-next-line camelcase
export const getChequeDeposito_ItemCheque = async (id) => {
  const response = await api.get(`${baseURL}/GetCheque`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ChequeDeposito_Item(value) : {};
};
// eslint-disable-next-line camelcase
export const saveChequeDeposito_Item = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};
// eslint-disable-next-line camelcase
export const deleteChequeDeposito_Item = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};
// eslint-disable-next-line camelcase
export const printChequeDeposito_Item = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};
// eslint-disable-next-line camelcase
export const excelChequeDeposito_Item = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};
// eslint-disable-next-line camelcase
export const deleteAllChequeDeposito_Item = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
