import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getProdutoUnidadeMedidaPagined,
  printProdutoUnidadeMedida,
  deleteAllProdutoUnidadeMedida,
  excelProdutoUnidadeMedida,
  getProdutoUnidadeMedidaList,
} from 'core/services/EST/produtoUnidadeMedida';
import { Autocomplete, CSDSelPage, GridView } from 'ui/components';

const columns = [
  { key: 'nrSeqProdutoUnidadeMedida', type: GridView.ColumnTypes.Checkbox },
  {
    key: 'cdProdutoUnidadeMedida',
    title: 'Codigo Produto Unidade Medida',
    sortBy: 'produtounidademedida.cdprodutounidademedida',
  },
  { key: 'noProdutoUnidadeMedida', title: 'Nome Produto Unidade Medida' },
  { key: 'flgDecimal', title: 'FlgDecimal' },
  { key: 'qtdeDecimal', title: 'Quantidade' },
];

export default function ProdutoUnidadeMedida({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getProdutoUnidadeMedidaPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getProdutoUnidadeMedidaPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'ProdutoUnidadeMedida/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqProdutoUnidadeMedida = sourceRow.find(
      (e) => e.key === 'nrSeqProdutoUnidadeMedida'
    );
    onOpenMaintenance(nrSeqProdutoUnidadeMedida.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printProdutoUnidadeMedida(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onSearchProdutoUnidadeMedida = async (e) => {
    const { data } = await getProdutoUnidadeMedidaList({
      NoProdutoUnidadeMedida: e,
    });

    return data;
  };

  const onDownloadExcel = async () => {
    setLoading(true);

    const { value: base64 } = await excelProdutoUnidadeMedida(filtros);

    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'ProdutoUnidadeMedida.csv';
      downloadLink.click();
    }

    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllProdutoUnidadeMedida(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Produto Unidade Medida'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      onDownloadExcel={onDownloadExcel}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-4 mb-3'>
          <Autocomplete
            label='Produto Unidade Medida'
            searchDataSource={onSearchProdutoUnidadeMedida}
            selectedItem={filtros.data}
            onSelectItem={(data) => {
              setFiltros({
                ...filtros,
                data,
                nrSeqProdutoUnidadeMedida: data.nrSeqProdutoUnidadeMedida,
              });
            }}
            dataSourceTextProperty='noProdutoUnidadeMedida'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
