export default class OrdemServicoManutencaoSearchPaginedModel {
  constructor(jsonObject = {}) {
    this.nrSeqManutencaoVeiculoOS = jsonObject.nrSeqManutencaoVeiculoOS;
    this.cdManutencaoVeiculoOS = jsonObject.cdManutencaoVeiculoOS;
    this.nrSeqManutencaoVeiculo = jsonObject.nrSeqManutencaoVeiculo;
    this.status = jsonObject.status;
    this.flgConcluido = jsonObject.flgConcluido;
    this.dtAbertura = jsonObject.dtAbertura;
    this.dtCad = jsonObject.dtCad;
    this.dtSaida = jsonObject.dtSaida;
    this.dtPrevistaRetorno = jsonObject.dtPrevistaRetorno;
    this.dtAlteracao = jsonObject.dtAlteracao;
    this.dtFechamento = jsonObject.dtFechamento;
    this.vlrOs = jsonObject.vlrOs;
    this.vlrFechamentoOS = jsonObject.vlrFechamentoOS;
    this.vlrDeslocamento = jsonObject.vlrDeslocamento;
    this.nrOdometro = jsonObject.nrOdometro;
    this.obsOS = jsonObject.obsOS;
    this.noDataTempoPendente = jsonObject.noDataTempoPendente;
    this.flgRegistraDataMovimento = jsonObject.flgRegistraDataMovimento;
    this.nrSeqPessoaMot = jsonObject.nrSeqPessoaMot;
    this.noPessoaMot = jsonObject.noPessoaMot;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.noUsuarioCad = jsonObject.noUsuarioCad;
    this.nrSeqUsuarioAlt = jsonObject.nrSeqUsuarioAlt;
    this.noUsuarioAlt = jsonObject.noUsuarioAlt;
    this.nrSeqUsuarioAut = jsonObject.nrSeqUsuarioAut;
    this.noUsuarioAut = jsonObject.noUsuarioAut;
    this.nrSeqUsuarioSol = jsonObject.nrSeqUsuarioSol;
    this.noUsuarioSol = jsonObject.noUsuarioSol;
    this.nrSeqCondicaoPagamento = jsonObject.nrSeqCondicaoPagamento;
    this.noCondicaoPagamento = jsonObject.noCondicaoPagamento;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.placa = jsonObject.placa;
    this.noVeiculosVinculados = jsonObject.noVeiculosVinculados;
    this.nrSeqPessoaOfi = jsonObject.nrSeqPessoaOfi;
    this.noOficina = jsonObject.noOficina;
    this.flgControlaEstoque = jsonObject.flgControlaEstoque;
    this.cidadeUF = jsonObject.cidadeUF;
    this.noStatusManutencao = jsonObject.noStatusManutencao;
    this.noColorPendencia = jsonObject.noColorPendencia;
    this.noProdutosConcat = jsonObject.noProdutosConcat;
    this.obsProdutos = jsonObject.obsProdutos;
  }

  toJson = () => JSON.stringify(this);
}
