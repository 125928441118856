import Pessoa from 'core/models/SEG/pessoa';
import PessoaDocumento from 'core/models/SEG/pessoaDocumento';
import ColetaConsignatario from 'core/models/FRO/coletaConsignatario';
import ColetaTipoDocumento from 'core/models/FRO/coletaTipoDocumento';
import TipoEmbarque from 'core/models/FRO/tipoEmbarque';
import TipoFrete from 'core/models/FRO/tipoFrete';
import EspecieFrete from 'core/models/FRO/especieFrete';
import FreteMercadoria from 'core/models/FRO/freteMercadoria';
import EstadoCivil from 'core/models/SEG/estadocivil';
import ContatoAdicional from 'core/models/SEG/contatoAdicional';
import Sexo from 'core/models/SEG/sexo';
import PessoaInformacaoBancaria from 'core/models/FIN/pessoaInformacaoBancaria';
import Estado from 'core/models/SEG/estado';
import EnvioPadrao from '../TEL/envioPadrao';
import Profissao from '../SEG/profissao';

export default class ColetaRemetente extends Pessoa {
  constructor(jsonObject = {}) {
    super(jsonObject);
    this.nrSeqPessoaRem = jsonObject.nrSeqPessoaRem;
    this.cdColetaRemetente = jsonObject.cdColetaRemetente;
    this.flgAtivo = jsonObject.flgAtivo;
    this.flgRetiraInfoCaracServXmlCTE = jsonObject.flgRetiraInfoCaracServXmlCTE;
    this.nrSeqPessoaConsig = jsonObject.nrSeqPessoaConsig;
    this.flgEdi = jsonObject.flgEdi;
    this.flgRemessa = jsonObject.flgRemessa;
    this.flgOrdemTransporte = jsonObject.flgOrdemTransporte;
    this.cdTipoDocumento = jsonObject.cdTipoDocumento;
    this.flgPesoLiquidoMercad = jsonObject.flgPesoLiquidoMercad;
    this.flgIncluirCodProdutoObserv = jsonObject.flgIncluirCodProdutoObserv;
    this.flgFreteMercadoriaDaNota = jsonObject.flgFreteMercadoriaDaNota;
    this.flgBuscaQtdProdutos = jsonObject.flgBuscaQtdProdutos;
    this.noPadraoOrdemTransporteNota = jsonObject.noPadraoOrdemTransporteNota;
    this.flgAdiantamentoPedagio = jsonObject.flgAdiantamentoPedagio;
    this.noLoginCiot = jsonObject.noLoginCiot;
    this.noSenhaCiot = jsonObject.noSenhaCiot;
    this.nrSequencialCiot = jsonObject.nrSequencialCiot;
    this.nrSequenciaCiot = jsonObject.nrSequenciaCiot;
    this.noImagemBase64 = jsonObject.noImagemBase64 ?? '';
    this.dtNascimentoFormat = jsonObject.dtNascimentoFormat;
    this.nrEnvioPadrao = jsonObject.nrEnvioPadrao;
    this.nrCNPJ = jsonObject.nrCNPJ;
    this.nrCPF = jsonObject.nrCPF;
    this.nrRG = jsonObject.nrRG;
    this.rgOrgaoEmissor = jsonObject.rgOrgaoEmissor;
    this.celular = jsonObject.celular;
    this.possuiWhatsApp = jsonObject.possuiWhatsApp;
    this.email = jsonObject.email;
    this.emailNfe = jsonObject.emailNfe;
    this.fone = jsonObject.fone;
    this.voip = jsonObject.voip;
    this.site = jsonObject.site;
    this.iEstadual = jsonObject.iEstadual;
    this.ieMunicipal = jsonObject.ieMunicipal;
    this.nrSeqSexo = jsonObject.nrSeqSexo;
    this.nrSeqTipoEmbarqueRemetente = jsonObject.nrSeqTipoEmbarqueRemetente;
    this.nrSeqTipoFreteRemetente = jsonObject.nrSeqTipoFreteRemetente;
    this.nrSeqEspecieFreteRemetente = jsonObject.nrSeqEspecieFreteRemetente;
    this.nrSeqFreteMercadoriaRemetente =
      jsonObject.nrSeqFreteMercadoriaRemetente;
    this.coletaConsignatario = new ColetaConsignatario(
      jsonObject.coletaConsignatario ?? {}
    );
    this.tipoEmbarque = new TipoEmbarque(jsonObject.tipoEmbarque ?? {});
    this.tipoFrete = new TipoFrete(jsonObject.tipoFrete ?? {});
    this.especieFrete = new EspecieFrete(jsonObject.especieFrete ?? {});
    this.freteMercadoria = new FreteMercadoria(
      jsonObject.freteMercadoria ?? {}
    );
    this.coletaTipoDocumento = new ColetaTipoDocumento(
      jsonObject.coletaTipoDocumento ?? {}
    );
    this.envioPadrao = new EnvioPadrao(jsonObject.envioPadrao ?? {});
    this.estadoCivil = new EstadoCivil(jsonObject.estadoCivil ?? {});
    this.profissao = new Profissao(jsonObject.profissao ?? {});
    this.contatosAdicionais = jsonObject.contatosAdicionais
      ? jsonObject.contatosAdicionais.map(
          (item) => new ContatoAdicional(item ?? {})
        )
      : [];
    this.informacaoBancaria = new PessoaInformacaoBancaria(
      jsonObject.informacaoBancaria ?? {}
    );
    this.sexo = new Sexo(jsonObject.sexo ?? {});
    this.ufIe = new Estado(jsonObject.ufIe ?? {});
    this.remetenteDocumentos =
      jsonObject.remetenteDocumentos?.map((el) => new PessoaDocumento(el)) ??
      [];
    // propriedades auxiliares
    this.noPessoaRem = jsonObject.noPessoaRem;
  }

  toJson = () => JSON.stringify(this);
}
