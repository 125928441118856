import React, { useState, useRef, useCallback, useEffect } from 'react';
import { CSDManutPage, Notification } from 'ui/components';
import { PageTypes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Message, Page } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { Theme, ResponseStatus } from 'ui/Helpers/enums.ts';

// eslint-disable-next-line import/no-unresolved
import CustomerRegistration from 'ui/components/CustomerRegistration';
import {
  getColetaRemetente,
  saveColetaRemetente,
  deleteColetaRemetente,
} from 'core/services/FRO/coletaRemetente';
import { IRemetente } from 'core/interfaces/FRO';
// @ts-expect-error
import RemetentePanel from './remetentePanel.tsx';
// @ts-expect-error
import DocumentsPanel from './documentsPanel.tsx';

interface IRemetenteState extends IRemetente {
  noTipoTransporte?: string;
  noTipoTransportadora?: number;
}

export default function Remetente({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}: Page) {
  const formSubmit = useRef<any>();
  const customerRegistrationRef = useRef<any>(null);
  const documentPanel = useRef<any>(null);

  const [remetenteData, setRemetenteData] = useState<IRemetenteState | any>({
    flgFisica: true,
    flgAtivo: true,
    flgRetiraInfoCaracServXmlCTE: false,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [warning, setWarning] = useState<Message | null>(null);

  const clearChildCustomer = () => {
    customerRegistrationRef?.current?.clear();
  };

  const clearDocumentPanel = () => {
    documentPanel?.current?.clear();
  };

  const fillDocumentPanel = () => {
    documentPanel?.current?.fillGrid();
  };

  const getDataCustomer = () => customerRegistrationRef?.current?.getData();

  const getDataDocumentsGrid = () => documentPanel?.current?.getDataGrid();

  const onNew = (): void => {
    clearChildCustomer();
    clearDocumentPanel();

    setRemetenteData({
      flgFisica: true,
      flgAtivo: true,
      flgRetiraInfoCaracServXmlCTE: false,
      pessoa: {
        nrSeqPessoa: undefined,
      },
      remetenteDocumentos: [],
    });
  };

  const searchById = async (nrSeqPessoa: any) => {
    if (nrSeqPessoa && Number.isInteger(nrSeqPessoa)) {
      setLoading(true);

      setRemetenteData((prevData) => ({
        ...prevData,
        pessoa: { nrSeqPessoa: undefined },
      }));

      const retornoRemetente: IRemetente | any = await getColetaRemetente(
        nrSeqPessoa
      );

      if ('nrSeqPessoaRem' in retornoRemetente) {
        setRemetenteData({
          ...retornoRemetente,
          pessoa: { nrSeqPessoa: retornoRemetente.nrSeqPessoaRem },
        });

        if (
          retornoRemetente.remetenteDocumentos &&
          retornoRemetente.remetenteDocumentos.length > 0
        ) {
          fillDocumentPanel();
        } else clearDocumentPanel();
      }
    } else {
      onNew();
    }
    setMessage(null);
  };

  const load = useCallback(async () => {
    await searchById(registryKey);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const onSetMessage = (status: ResponseStatus, msg: string = ''): void => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const save = async () => {
    setLoading(true);
    const pessoa = getDataCustomer();
    const documentos = getDataDocumentsGrid();
    const { status, message: msg, value } = await saveColetaRemetente({
      ...remetenteData,
      ...pessoa,
      flgFisica: pessoa.flgFisica,
      remetenteDocumentos: documentos,
    });

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    const text = value && value.length > 0 ? value.join() : null;

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();

      setWarning({ message: text, theme: Theme.Warning });
      onNew();
    }

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteColetaRemetente(registryKey);

      setRemetenteData(status === ResponseStatus.Success ? {} : remetenteData);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção Remetente'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onNew()}
      onSave={save}
      onDelete={remetenteData?.nrSeqPessoaRem > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      {warning && (
        <div className='mb-3'>
          {/* @ts-expect-error */}
          <Notification
            message={warning.message}
            theme={warning.theme}
            onClose={() => setWarning(null)}
          />
        </div>
      )}
      <div className='row mt-2'>
        <div className='col mb-2'>
          <CustomerRegistration
            nrSeqPessoa={remetenteData.pessoa?.nrSeqPessoa}
            data={remetenteData}
            setData={setRemetenteData}
            setLoading={setLoading}
            onSetMessage={onSetMessage}
            config={{
              panelTitle: 'Informações Remetente',
            }}
            getDataOrigem={searchById}
            ref={customerRegistrationRef}
          >
            {[
              <>
                <div className='row mb-2'>
                  <div className='col'>
                    <RemetentePanel
                      remetenteData={remetenteData}
                      setRemetenteData={setRemetenteData}
                    />
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col'>
                    <DocumentsPanel
                      remetenteData={remetenteData}
                      setRemetenteData={setRemetenteData}
                      onSetMessage={onSetMessage}
                      ref={documentPanel}
                    />
                  </div>
                </div>
              </>,
            ]}
          </CustomerRegistration>
        </div>
      </div>
    </CSDManutPage>
  );
}
