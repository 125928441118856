import { GeraContratoClickSign, geraTTClickSign } from 'core/services/HELP';
import { getPessoa } from 'core/services/SEG';
import {
  getRepresentantesLegais,
  saveContatoAdicional,
} from 'core/services/SEG/contatoAdicional';
import React, { useEffect, useRef, useState } from 'react';
import { MaskTypes } from 'ui/Helpers/masks';

import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  Button,
  DatePicker,
  GridView,
  Loader,
  Modal,
  Notification,
  Panel,
  RadioButton,
  Textbox,
} from 'ui/components';

export default function ModalContratoClickSign({
  demanda,
  show,
  onClose,
  onSetMessage,
  docType,
}) {
  const gridView = useRef();
  const [data, setData] = useState({
    auth: 'whatsapp',
    selectedPainel: 'addRep',
  });
  const [representantes, setRepresentantes] = useState([]);
  const [message, setMessage] = useState(null);
  const [contatoAdicional, setContatoAdicional] = useState({});
  const [loading, setLoading] = useState(false);

  const validaAssinantesClientePJ = () => {
    const dataSource = gridView?.current?.getDataSource() ?? [];

    if (dataSource.length === 0) {
      setMessage({
        message: 'Adicione ao menos 1 representante para assinar o contrato',
        theme: Theme.Danger,
      });

      return false;
    }

    return true;
  };

  const gerarContratoClickSign = async () => {
    if (!loading) {
      setLoading(true);

      if (!demanda.cliente?.flgFisica) {
        if (!validaAssinantesClientePJ()) {
          return;
        }
      }

      let { nrSeqDemanda } = demanda;

      if (demanda.tipoDemanda?.flgGeraMassivo) {
        if (demanda.nrSeqDemandaPrincipal != null) {
          nrSeqDemanda = demanda.nrSeqDemandaPrincipal;
        }
      }

      const assinantes = demanda.cliente?.flgFisica
        ? [data]
        : gridView?.current?.getDataSource();

      const { status, message: msg } = await GeraContratoClickSign({
        nrSeqDemanda,
        assinantes,
      });

      onSetMessage(status, msg);

      setLoading(false);

      onClose();
    }
  };

  const onGeraTTClickSign = async () => {
    if (!loading) {
      setLoading(true);

      if (!demanda.cliente?.flgFisica) {
        if (!validaAssinantesClientePJ()) {
          return;
        }
      }

      let { nrSeqDemanda } = demanda;

      if (demanda.tipoDemanda?.flgGeraMassivo) {
        if (demanda.nrSeqDemandaPrincipal != null) {
          nrSeqDemanda = demanda.nrSeqDemandaPrincipal;
        }
      }

      const assinantes = demanda.cliente?.flgFisica
        ? [data]
        : gridView?.current?.getDataSource();

      const { status, message: msg } = await geraTTClickSign({
        nrSeqDemanda,
        assinantes,
      });

      onSetMessage(status, msg);

      setLoading(false);

      onClose();
    }
  };

  const authButtons = [
    {
      text: 'Email',
      value: 'email',
    },
    {
      text: 'SMS',
      value: 'sms',
    },
    {
      text: 'WhatsApp',
      value: 'whatsapp',
    },
  ];

  const searchRepresentantesLegais = async () => {
    let nrSeqSignatario = demanda.nrSeqPessoaCli;

    if (
      docType === 'TT' &&
      (demanda.tipoDemanda?.cdTipoDemanda === 'Migracao PF para PJ Massivo' ||
        demanda.tipoDemanda?.cdTipoDemanda === 'Migracao de PF para PJ') &&
      demanda.nrSeqPessoaOld
    ) {
      nrSeqSignatario = demanda.nrSeqPessoaOld;
    }

    const { status, data: result } = await getRepresentantesLegais({
      nrSeqPessoa: nrSeqSignatario,
    });

    if (status === ResponseStatus.Success) {
      result.forEach((rep) => {
        rep.auth = 'whatsapp';
      });

      setRepresentantes(result);

      if (result.length > 0) {
        setData({ ...data, selectedPainel: 'addRep' });
      } else {
        setData({ ...data, selectedPainel: 'createRep' });
      }
    }
  };

  useEffect(async () => {
    if (show) {
      let nrSeqSignatario = demanda.nrSeqPessoaCli;

      if (
        docType === 'TT' &&
        (demanda.tipoDemanda?.cdTipoDemanda === 'Migracao PF para PJ Massivo' ||
          demanda.tipoDemanda?.cdTipoDemanda === 'Migracao de PF para PJ') &&
        demanda.nrSeqPessoaOld
      ) {
        nrSeqSignatario = demanda.nrSeqPessoaOld;
      }

      if (nrSeqSignatario) {
        setLoading(true);

        if (!demanda.cliente?.flgFisica) {
          searchRepresentantesLegais();
        } else {
          const cliente = await getPessoa(nrSeqSignatario);

          setData({
            ...data,
            nome: cliente.noPessoa,
            email: cliente.contato?.email,
            celular: cliente.contato?.celular,
            nrCpf: cliente.pessoaFisica?.nrCpf,
            dtNascimento: cliente.pessoaFisica?.dtNascimento,
          });
        }

        setLoading(false);
      }
    }
  }, [show]);

  const addRepresentante = (index) => {
    if (representantes[index].auth) {
      if (
        (representantes[index].auth === 'email' &&
          !representantes[index].email) ||
        ((representantes[index].auth === 'sms' ||
          representantes[index].auth === 'whatsapp') &&
          !representantes[index].celular)
      ) {
        setMessage({
          message:
            'Representante não possui um contato compatível com a forma de envio de notificação selecionada',
          theme: Theme.Danger,
        });
      } else {
        const gridItens = gridView?.current?.getDataSource() ?? [];

        if (
          gridItens.every(
            (e) =>
              e.nrSeqContatoAdicional !==
              representantes[index].nrSeqContatoAdicional
          )
        ) {
          gridItens.push(representantes[index]);

          gridView?.current?.setDataSource(gridItens);
        } else {
          setMessage({
            message: 'Representante já foi adicionado',
            theme: Theme.Danger,
          });
        }
      }
    } else {
      setMessage({
        message: 'Selecione a forma que o Representante sera notificado',
        theme: Theme.Danger,
      });
    }
  };

  const validaRepresentanteLegal = () => {
    if (!contatoAdicional.nome) {
      onSetMessage(ResponseStatus.Error, 'Informa o nome do Contato');

      return false;
    }

    if (!contatoAdicional.nrCPF) {
      onSetMessage(ResponseStatus.Error, 'Informa o CPF do Contato');

      return false;
    }

    if (!contatoAdicional.dtNascimento) {
      onSetMessage(
        ResponseStatus.Error,
        'Informa a Data de Nascimento do Contato'
      );

      return false;
    }

    if (!contatoAdicional.email && !contatoAdicional.celular) {
      onSetMessage(
        ResponseStatus.Error,
        'Informe o email ou celular do contato'
      );

      return false;
    }

    return true;
  };

  const salvarRepresentanteLegal = async () => {
    if (!loading) {
      setLoading(true);

      if (validaRepresentanteLegal) {
        const { status, message: msg } = await saveContatoAdicional({
          ...contatoAdicional,
          nrSeqPessoa: demanda.nrSeqPessoaCli,
          nrCpf: contatoAdicional.nrCpf.replaceAll('.', ''),
          flgRepresentanteLegal: true,
        });

        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });

        if (status === ResponseStatus.Success) {
          setContatoAdicional({});
          searchRepresentantesLegais();
        }
      }

      setLoading(false);
    }
  };

  const removerGridItem = (e, datasource) => {
    const list = datasource.filter((el) => el !== e);

    gridView?.current?.setDataSource(list);
  };

  const columns = [
    { key: 'nome', title: 'Nome' },
    { key: 'nrCpf', title: 'CPF' },
    { key: 'email', title: 'Email' },
    { key: 'celular', title: 'Celular' },
    {
      key: 'dtNascimento',
      title: 'Data de Nascimento',
      format: GridView.DataTypes.Date,
    },
    { key: 'auth', title: 'Notificação' },
    {
      key: 'nrSeqContatoAdicional',
      type: GridView.ColumnTypes.Button,
      onClick: (e, dataSource) => removerGridItem(e, dataSource),
      title: 'Excluir',
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const selectPainelButtons = [
    {
      text: 'Adicionar Representante',
      value: 'addRep',
    },
    {
      text: 'Criar Representante',
      value: 'createRep',
    },
  ];

  return (
    <Modal
      show={show}
      title='Contrato Click Sign'
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
      icon={['far', 'comment-alt']}
    >
      <Modal.Body className='p-4 pt-3'>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              floatable
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='col'>
            <Panel>
              <Panel.Header title='Contatos do Cliente' theme={Theme.Primary} />
              <Panel.Body>
                {demanda.cliente?.flgFisica ? (
                  <>
                    <div className='row mb-3'>
                      <div className='col'>
                        <Textbox label='Nome' readOnly text={data.nome} />
                      </div>
                      <div className='col'>
                        <Textbox label='Email' readOnly text={data.email} />
                      </div>
                      <div className='col'>
                        <Textbox
                          label='Telefone'
                          readOnly
                          text={data.celular}
                          mask={MaskTypes.CellPhone}
                        />
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col'>
                        <RadioButton
                          outline
                          label='Enviar Notificação'
                          size={BootstrapSizes.Small}
                          theme={Theme.Primary}
                          buttons={authButtons}
                          selectedButton={data.auth}
                          onChange={(auth) => {
                            setData({
                              ...data,
                              auth,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='row mb-3'>
                      <div className='col d-flex justify-content-center'>
                        <RadioButton
                          outline
                          size={BootstrapSizes.Large}
                          theme={Theme.Primary}
                          buttons={selectPainelButtons}
                          selectedButton={data.selectedPainel}
                          onChange={(selectedPainel) => {
                            setData({
                              ...data,
                              selectedPainel,
                            });
                          }}
                        />
                      </div>
                    </div>
                    {data.selectedPainel === 'addRep' ? (
                      <>
                        <div className='row mb-3'>
                          <div className='col'>
                            <Panel>
                              <Panel.Header
                                title='Adicionar Representante Legal para Assinar Contrato'
                                theme={Theme.Primary}
                              />
                              <Panel.Body>
                                {representantes.length > 0 ? (
                                  representantes.map((rep, index) => (
                                    <>
                                      <div className='row mb-3'>
                                        <div className='col'>
                                          <Textbox
                                            label='Nome'
                                            readOnly
                                            text={rep.nome}
                                          />
                                        </div>
                                        <div className='col'>
                                          <Textbox
                                            label='Email'
                                            readOnly
                                            text={rep.email}
                                          />
                                        </div>
                                        <div className='col'>
                                          <Textbox
                                            label='Telefone'
                                            readOnly
                                            text={rep.celular}
                                            mask={MaskTypes.CellPhone}
                                          />
                                        </div>
                                        <div className='col'>
                                          <RadioButton
                                            outline
                                            label='Enviar Notificação'
                                            size={BootstrapSizes.Small}
                                            theme={Theme.Primary}
                                            buttons={authButtons}
                                            selectedButton={
                                              representantes[index].auth
                                            }
                                            onChange={(auth) => {
                                              representantes[index].auth = auth;
                                              setRepresentantes([
                                                ...representantes,
                                              ]);
                                            }}
                                          />
                                        </div>
                                        <div className='col'>
                                          <Button
                                            outline
                                            icon='plus'
                                            className='mt-4'
                                            size={BootstrapSizes.Medium}
                                            theme={Theme.Success}
                                            onClick={() =>
                                              addRepresentante(index)
                                            }
                                            text='Adicionar Assinante'
                                          />
                                        </div>
                                      </div>
                                    </>
                                  ))
                                ) : (
                                  <>
                                    <div className='row mb-3'>
                                      <div className='col text-center'>
                                        <span>
                                          Adicione um Representante para assinar
                                          via ClickSign
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </Panel.Body>
                            </Panel>
                          </div>
                        </div>
                        <div className='row mb-3'>
                          <div className='col'>
                            <GridView
                              ref={gridView}
                              dataColumns={columns}
                              showPagination={false}
                              showSelectSizes={false}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='row mb-3'>
                          <div className='col'>
                            <Panel>
                              <Panel.Header
                                title='Adicionar Representante Legal'
                                theme={Theme.Primary}
                              />
                              <Panel.Body>
                                <div className='row mb-3'>
                                  <div className='col'>
                                    <Textbox
                                      label='Nome'
                                      required
                                      text={contatoAdicional.nome}
                                      onChangedValue={(nome) =>
                                        setContatoAdicional({
                                          ...contatoAdicional,
                                          nome,
                                        })
                                      }
                                    />
                                  </div>
                                  <div className='col'>
                                    <Textbox
                                      label='CPF'
                                      required
                                      text={contatoAdicional.nrCpf}
                                      mask={MaskTypes.CPF}
                                      onChangedValue={(nrCpf) =>
                                        setContatoAdicional({
                                          ...contatoAdicional,
                                          nrCpf,
                                        })
                                      }
                                    />
                                  </div>
                                  <div className='col'>
                                    <DatePicker
                                      label='Data de Nascimento'
                                      required
                                      text={contatoAdicional.dtNascimento}
                                      maxLength={10}
                                      mask={MaskTypes.Date}
                                      onChange={(dtNascimento) =>
                                        setContatoAdicional({
                                          ...contatoAdicional,
                                          dtNascimento,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                                <div className='row mb-3'>
                                  <div className='col'>
                                    <Textbox
                                      label='Email'
                                      text={contatoAdicional.email}
                                      onChangedValue={(email) =>
                                        setContatoAdicional({
                                          ...contatoAdicional,
                                          email,
                                        })
                                      }
                                    />
                                  </div>
                                  <div className='col'>
                                    <Textbox
                                      label='Celular'
                                      text={contatoAdicional.celular}
                                      mask={MaskTypes.CellPhone}
                                      onChangedValue={(celular) =>
                                        setContatoAdicional({
                                          ...contatoAdicional,
                                          celular,
                                        })
                                      }
                                    />
                                  </div>
                                  <div className='col'>
                                    <Button
                                      outline
                                      text='Salvar Representante Legal'
                                      className='mt-4'
                                      size={BootstrapSizes.Medium}
                                      theme={Theme.Success}
                                      onClick={salvarRepresentanteLegal}
                                    />
                                  </div>
                                </div>
                              </Panel.Body>
                            </Panel>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </Panel.Body>
            </Panel>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Enviar Documento'
          theme={Theme.Success}
          onClick={
            docType === 'Contrato' ? gerarContratoClickSign : onGeraTTClickSign
          }
        />
        <Button
          text='Fechar'
          theme={Theme.Danger}
          icon='ban'
          onClick={() => {
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
