import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
  JustifyContent,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  DatePicker,
  Switch,
  GridView,
  Button,
  Panel,
} from 'ui/components';

import {
  getChequeDeposito,
  saveChequeDeposito,
  deleteChequeDeposito,
  getChequesPaginated,
} from 'core/services/FIN/chequeDeposito';
// eslint-disable-next-line camelcase
import { getChequeDeposito_ItemCheque } from 'core/services/FIN/chequeDeposito_Item';
import { getContaCorrenteTransferenciaAutoComplete } from 'core/services/FIN/contaCorrenteTransferencia';
import { MaskTypes } from 'ui/Helpers/masks';
import { getBancoAutoComplete } from 'core/services/FIN/banco';

export default function ChequeDepositoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const gridChequesSelecionar = useRef();
  const gridChequesSelecionados = useRef();

  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [filtroCheques, setFiltroCheques] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params = null) => {
    setLoading(true);

    // Verifique se data.contaCorrenteTransferencia está definido
    if (
      data.contaCorrenteTransferencia &&
      data.contaCorrenteTransferencia.nrSeqContaCorrenteOri != null
    ) {
      filtroCheques.nrSeqContaCorrenteOri =
        data.contaCorrenteTransferencia.nrSeqContaCorrenteOri;

      // Obtendo os dados paginados
      const {
        dataGridChequesSelecionar,
        pagination,
      } = await getChequesPaginated(params ?? filtroCheques);

      // Verificação para evitar cheques duplicados
      const chequesDeposito = Array.isArray(data.chequeDeposito_Item)
        ? data.chequeDeposito_Item.map((item) => item.cheque.nrSeqCheque)
        : [];

      const filteredDataGridChequesSelecionar = dataGridChequesSelecionar.filter(
        (cheque) => !chequesDeposito.includes(cheque.nrSeqCheque)
      );

      // Atualizando a grid somente com os cheques não duplicados
      if (gridChequesSelecionar && gridChequesSelecionar.current) {
        gridChequesSelecionar.current.setDataSource(
          filteredDataGridChequesSelecionar,
          pagination
        );
      }
    } else {
      setMessage({
        message: 'Selecione uma Descrição Transferência!',
        theme: Theme.Danger,
      });
    }

    setLoading(false);
  };

  const onSearchBanco = async (e) => {
    const { status, message: msg, bancos } = await getBancoAutoComplete({
      noBanco: e,
    });
    onSetMessage(status, msg);
    return bancos;
  };

  const load = useCallback(async () => {
    if (gridChequesSelecionar.current && gridChequesSelecionados.current) {
      gridChequesSelecionar.current.setDataSource([]);
      gridChequesSelecionados.current.setDataSource([]);
    }
    setFiltroCheques({});
    if (registryKey) {
      setLoading(true);
      const chequeDeposito = await getChequeDeposito(registryKey);
      setData(chequeDeposito);

      if (gridChequesSelecionados && gridChequesSelecionados.current)
        gridChequesSelecionados.current.setDataSource(
          chequeDeposito.chequeDeposito_Item
        );

      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  const onSearchContaCorrenteTransferenciaAutoComplete = async (e) => {
    const {
      data: contaCorrenteTransferencia,
    } = await getContaCorrenteTransferenciaAutoComplete({
      noContaCorrenteTransferencia: e,
    });

    return contaCorrenteTransferencia;
  };

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveChequeDeposito(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    gridChequesSelecionar.current.setDataSource([]);
    gridChequesSelecionados.current.setDataSource([]);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteChequeDeposito(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onClickAddCheque = async () => {
    setLoading(true);

    const selecteds = gridChequesSelecionar.current.getCheckBoxValuesAt(0);
    await search();
    if (selecteds.length > 0 && data.dtCredito != null) {
      const nrSeqChequeValues = selecteds.map((row) => row[0].value); // Extrair nrSeqCheque

      // Usando map para criar promessas, e Promise.all para aguardar todas as respostas
      const results = await Promise.all(
        nrSeqChequeValues.map(
          async (nrSeqCheque) => getChequeDeposito_ItemCheque(nrSeqCheque) // Retorna diretamente o resultado
        )
      );

      // Modificando os dados diretamente no array 'results'
      results.forEach((item) => {
        const { cheque } = item; // Acessa o objeto cheque

        // Modificando as datas de acordo com a lógica
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Isso define a data atual para 00:00:00

        if (new Date(cheque.dtVencimento).getTime() < today.getTime()) {
          cheque.dtCredito = data.dtCredito; // Atualiza dtVencimento
        } else {
          cheque.dtCredito = cheque.dtVencimento; // Atualiza dtDocumento
        }

        // Adicionando o status no item
        item.status = GridView.EnumStatus.Inserir;
      });

      // Obter os cheques que já estão no gridSelecionados
      const currentData = Array.isArray(
        gridChequesSelecionados.current.getDataSource()
      )
        ? gridChequesSelecionados.current.getDataSource()
        : [];

      // Obter os nrSeqCheque que já estão no gridChequesSelecionados
      const chequesNoGridSelecionados = currentData.map(
        (item) => item.cheque.nrSeqCheque
      );

      // Filtrando os cheques de gridChequesSelecionar que já estão em gridChequesSelecionados
      const filteredDataGridChequesSelecionar = results.filter(
        (item) => !chequesNoGridSelecionados.includes(item.cheque.nrSeqCheque)
      );

      if (filteredDataGridChequesSelecionar.length === 0) {
        setMessage({
          message: 'Este cheque já foi adicionado!',
          theme: Theme.Danger,
        });
        setLoading(false);
        return;
      }

      // Adicionando apenas os cheques não duplicados ao gridChequesSelecionados
      const updatedData = [
        ...currentData,
        ...filteredDataGridChequesSelecionar,
      ];

      // Atualizando o grid com os cheques não duplicados
      gridChequesSelecionados.current.setDataSource(updatedData);

      // Atualizando o estado com os novos cheques
      setData((prevData) => ({
        ...prevData,
        chequeDeposito_Item: updatedData,
      }));

      await search();

      setMessage({
        message: 'Cheque adicionado com sucesso!',
        theme: Theme.Success,
      });
    } else if (selecteds.length === 0) {
      setMessage({
        message: 'Selecione uma ou mais opções da lista para adicionar!',
        theme: Theme.Danger,
      });
    } else if (data.dtCredito == null) {
      setMessage({
        message: 'Adicione uma Data de Crédito!',
        theme: Theme.Danger,
      });
    }

    setLoading(false);
  };

  const onClickRemoveCheque = async (e, datasource) => {
    const listaCheques = datasource.filter((el) => el !== e);

    if (e.status !== GridView.EnumStatus.Inserir) {
      e.status = GridView.EnumStatus.Remover;

      listaCheques.push(e);
    }

    if (gridChequesSelecionados && gridChequesSelecionados.current)
      gridChequesSelecionados.current.setDataSource(listaCheques);

    setData((prevData) => ({
      ...prevData,
      chequeDeposito_Item: listaCheques,
    }));
  };

  const chequesSelecionar = [
    { key: 'nrSeqCheque', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'noPessoaCheque',
      title: 'Nome',
    },
    {
      key: 'dtDocumento',
      title: 'Data',
      format: GridView.DataTypes.Date,
      visible: false,
    },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'noBanco',
      title: 'Banco',
    },
    {
      key: 'vlrCheque',
      title: 'Valor',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'noChequeSituacao',
      title: 'Situação',
    },
  ];

  const chequesSelecionados = [
    {
      key: 'cheque.dtDocumento',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'cheque.dtCredito',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'cheque.bancoCheque.noBanco',
      title: 'Banco',
    },
    {
      key: 'cheque.vlrCheque',
      title: 'Valor',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'cheque.chequeSituacao.noChequeSituacao',
      title: 'Situação',
    },
    {
      key: 'nrSeqCheque',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e, dataSource) => onClickRemoveCheque(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const onColumnSort = async (sortBy) => {
    const dataFilters = { ...filtroCheques, sortBy };
    setFiltroCheques(dataFilters);
    await search(dataFilters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const dataFilters = { ...filtroCheques, totalByPage };
    setFiltroCheques(dataFilters);
    await search(dataFilters);
  };
  const onPageChange = async (page) => {
    const dataFilters = { ...filtroCheques, page };
    setFiltroCheques(dataFilters);
    await search(dataFilters);
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Cheque Deposito'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqChequeDeposito > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <Panel>
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-3'>
                <Autocomplete
                  label='Descrição Transferência'
                  required
                  searchDataSource={
                    onSearchContaCorrenteTransferenciaAutoComplete
                  }
                  selectedItem={data?.contaCorrenteTransferencia}
                  onSelectItem={(contaCorrenteTransferencia) => {
                    setData({
                      ...data,
                      contaCorrenteTransferencia,
                      nrSeqContaCorrenteTransferencia:
                        contaCorrenteTransferencia.nrSeqContaCorrenteTransferencia,
                    });
                  }}
                  dataSourceTextProperty='noContaCorrenteTransferencia'
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-3'>
                <Textbox
                  label='Conta Corrente Origem'
                  text={
                    data?.contaCorrenteTransferencia?.contaCorrenteOri
                      ?.noContaCorrente ?? ' '
                  }
                  readOnly
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-3'>
                <Textbox
                  label='Conta Corrente Destino'
                  text={
                    data?.contaCorrenteTransferencia?.contaCorrenteDest
                      ?.noContaCorrente ?? ' '
                  }
                  readOnly
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-2'>
                <DatePicker
                  label='Data de Crédito'
                  text={data.dtCredito}
                  required
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtCredito) => {
                    setData({ ...data, dtCredito });
                  }}
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-2'>
                <Switch
                  formControl
                  required
                  label='Desconto'
                  checked={data.flgDesconto}
                  onChange={(flgDesconto) => setData({ ...data, flgDesconto })}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header
            title='Filtros'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-2'>
                <DatePicker
                  label='Recebimento Inicial'
                  text={filtroCheques.dtRecebimentoDe}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtRecebimentoDe) => {
                    if (dtRecebimentoDe > filtroCheques.dtRecebimentoAte) {
                      setMessage({
                        message:
                          'A Data Inicial de Recebimento não pode ser maior que a Data Final.',
                        theme: Theme.Danger,
                      });
                    } else {
                      setFiltroCheques({ ...filtroCheques, dtRecebimentoDe });
                    }
                  }}
                />
              </div>

              <div className='col-2'>
                <DatePicker
                  label='Recebimento Final'
                  text={filtroCheques.dtRecebimentoAte}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtRecebimentoAte) => {
                    if (filtroCheques.dtRecebimentoDe > dtRecebimentoAte) {
                      setMessage({
                        message:
                          'A Data Final de Recebimento não pode ser menor que a Data Inicial.',
                        theme: Theme.Danger,
                      });
                    } else {
                      setFiltroCheques({ ...filtroCheques, dtRecebimentoAte });
                    }
                  }}
                />
              </div>
              <div className='col-4'>
                <Autocomplete
                  label='Banco'
                  searchDataSource={onSearchBanco}
                  selectedItem={filtroCheques.banco}
                  onSelectItem={(banco) =>
                    setFiltroCheques({
                      ...filtroCheques,
                      banco,
                      nrSeqBanco: banco.nrSeqBanco,
                    })
                  }
                  dataSourceTextProperty='noBanco'
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-2'>
                <DatePicker
                  label='Vencimento Inicial'
                  text={filtroCheques.dtVencimentoDe}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtVencimentoDe) => {
                    if (dtVencimentoDe > filtroCheques.dtVencimentoAte) {
                      setMessage({
                        message:
                          'A Data Inicial de Vencimento não pode ser maior que a Data Final.',
                        theme: Theme.Danger,
                      });
                    } else {
                      setFiltroCheques({ ...filtroCheques, dtVencimentoDe });
                    }
                  }}
                />
              </div>

              <div className='col-2'>
                <DatePicker
                  label='Vencimento Final'
                  text={filtroCheques.dtVencimentoAte}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtVencimentoAte) => {
                    if (filtroCheques.dtVencimentoDe > dtVencimentoAte) {
                      setMessage({
                        message:
                          'A Data Final de Vencimento não pode ser menor que a Data Inicial.',
                        theme: Theme.Danger,
                      });
                    } else {
                      setFiltroCheques({ ...filtroCheques, dtVencimentoAte });
                    }
                  }}
                />
              </div>
              <div className='col-2 d-flex align-items-end'>
                <Button
                  outline
                  icon='search'
                  style={{ flexGrow: 1 }}
                  size={BootstrapSizes.Medium}
                  theme={Theme.Primary}
                  onClick={() => search()}
                  text='Buscar Cheques'
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridChequesSelecionar}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={chequesSelecionar}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-2 d-flex align-items-end'>
          <Button
            outline
            icon='plus'
            style={{ flexGrow: 1 }}
            size={BootstrapSizes.Medium}
            theme={Theme.Primary}
            visible={
              gridChequesSelecionar.current &&
              gridChequesSelecionar.current.getDataSource &&
              gridChequesSelecionar.current.getDataSource().length > 0
            }
            onClick={onClickAddCheque}
            text='Adicionar'
          />
        </div>
      </div>

      <Panel>
        <Panel.Header
          title='Cheques'
          theme={Theme.Primary}
          align={JustifyContent.Start}
        />
        <Panel.Body>
          <div className='row'>
            <div className='col'>
              <GridView
                ref={gridChequesSelecionados}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={chequesSelecionados}
                showSelectSizes={false}
                showPagination={false}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </CSDManutPage>
  );
}
