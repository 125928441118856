import React, { useRef, useState } from 'react';
import {
  Autocomplete,
  Button,
  CSDSelPage,
  GridView,
  RadioButton,
  Textbox,
} from 'ui/components';
import UnidadeConsumidora from 'core/models/ENE/energiaUnidadeConsumidora';
import { getEnergiaUnidadeConsumidoraAutoComplete } from 'core/services/ENE/energiaUnidadeConsumidora';
import { importarFaturaEnergiaPelaDigitalGrid } from 'core/services/ENE/importaFaturaEnergia';
import { GridviewColumns, Message, Page } from 'ui/Helpers/interfaces';
import {
  BootstrapSizes,
  ColumnTypes,
  MaskTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/enums';

const meses = [
  {
    text: 'JAN',
    value: '01',
  },
  {
    text: 'FEV',
    value: '02',
  },
  {
    text: 'MAR',
    value: '03',
  },
  {
    text: 'ABR',
    value: '04',
  },
  {
    text: 'MAI',
    value: '05',
  },
  {
    text: 'JUN',
    value: '06',
  },
  {
    text: 'JUL',
    value: '07',
  },
  {
    text: 'AGO',
    value: '08',
  },
  {
    text: 'SET',
    value: '09',
  },
  {
    text: 'OUT',
    value: '10',
  },
  {
    text: 'NOV',
    value: '11',
  },
  {
    text: 'DEZ',
    value: '12',
  },
];

export default function ImportaFaturaEnergia({ transaction, isActive }: Page) {
  const gridView: any = useRef();

  const [filtros, setFiltros] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [uc, setUc] = useState<UnidadeConsumidora>();
  const [showGrid, setShowGrid] = useState<boolean>(false);

  const onClickDownload = async (e: any) => {
    const linkSource = `data:${e.boleto.noTipoArquivo};base64, ${e.boleto.noImagem}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = e.boleto.noArquivo;
    downloadLink.click();
  };

  const columns: GridviewColumns[] = [
    {
      title: 'Cliente',
      key: 'cliente',
      sortable: false,
    },
    {
      title: 'UC',
      key: 'uc',
      sortable: false,
    },
    {
      title: 'Mensagem',
      key: 'message',
      sortable: false,
    },
    {
      title: 'Download Boleto',
      key: 'boleto',
      sortable: false,
      icon: 'download',
      tooltip: 'Download',
      type: ColumnTypes.Button,
      theme: Theme.Primary,
      size: BootstrapSizes.Small,
      onClick: (e: any) => onClickDownload(e),
      visibleDynamic: 'flgBoleto',
    },
  ];

  const onSetMessage = (status: number, msg: string) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const handleImport = async () => {
    setLoading(true);

    if (gridView && gridView.current) {
      gridView.current.setDataSource([]);
    }

    if (!filtros.mesFatura) {
      setMessage({
        message: 'É necessário selecionar o mês de referência a ser importado.',
        theme: Theme.Warning,
      });

      setLoading(false);
      return;
    }

    if (!filtros.anoFatura || filtros.anoFatura.toString().length !== 4) {
      setMessage({
        message: 'É necessário informar o ano de referência com 4 digitos.',
        theme: Theme.Warning,
      });

      setLoading(false);
      return;
    }

    const {
      value,
      status,
      message: msg,
    } = await importarFaturaEnergiaPelaDigitalGrid(filtros);

    if (value) {
      setShowGrid(true);

      gridView.current.setDataSource(value);
    } else {
      setShowGrid(false);
    }

    onSetMessage(status, msg);

    setLoading(false);
  };

  const onSearchUc = async (e: string): Promise<UnidadeConsumidora[]> => {
    const {
      status,
      message: msg,
      ucs,
    }: {
      status: number;
      message: string;
      ucs: UnidadeConsumidora[];
    } = await getEnergiaUnidadeConsumidoraAutoComplete({
      nrUc: e,
    });

    onSetMessage(status, msg);

    return ucs;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      transaction={transaction}
      title='Importa Fatura Energia'
      loading={loading}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <div className='row mb-5'>
        <div className='col-2'>
          <Autocomplete
            label='UC'
            searchDataSource={onSearchUc}
            selectedItem={uc}
            onSelectItem={(unidade: UnidadeConsumidora) => {
              setFiltros({
                ...filtros,
                nrSeqEnergiaUnidadeConsumidora:
                  unidade.nrSeqEnergiaUnidadeConsumidora,
              });
              setUc(unidade);
            }}
            dataSourceTextProperty='nrUc'
          />
        </div>
        <div className='col-5'>
          <RadioButton
            label='Selecione o mês a ser importado'
            theme={Theme.Primary}
            outline
            size={BootstrapSizes.Small}
            buttons={meses}
            selectedButton={filtros?.mesFatura}
            onChange={(value: string) =>
              setFiltros({ ...filtros, mesFatura: value })
            }
            disabled={false}
          />
        </div>
        <div className='col-2'>
          {/* @ts-expect-error */}
          <Textbox
            label='Ano'
            mask={MaskTypes.Integer}
            maxLength={4}
            text={filtros?.anoFatura}
            onChangedValue={(value: string) =>
              setFiltros({ ...filtros, anoFatura: value })
            }
            required
          />
        </div>
      </div>
      <div className='row mb-3 d-flex justify-content-center'>
        <div className='row col-6'>
          <Button
            text='Importar'
            theme={Theme.Primary}
            size={BootstrapSizes.Large}
            onClick={() => handleImport()}
          />
        </div>
      </div>
      {showGrid && (
        <div>
          <GridView
            ref={gridView}
            // @ts-expect-error
            dataColumns={columns}
            disableColumnCheck={false}
            notAllowChangePage
            showSelectSizes={false}
            showPagination={false}
            enableExcelExport
          />
        </div>
      )}
    </CSDSelPage>
  );
}
