import qs from 'qs';
import Pagination from 'core/models/pagination';
import AgrupamentoFinanceiro from 'core/models/FIN/agrupamentoFinanceiro';
import { ResponseStatus } from 'ui/Helpers/utils';
import api from '../api';

const baseURL = `/FIN/AgrupamentoFinanceiro`;

export const getAgrupamentoFinanceiroList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AgrupamentoFinanceiro(json)) ?? [],
  };
};

export const getAgrupamentoFinanceiroPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AgrupamentoFinanceiro(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getAgrupamentoFinanceiro = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new AgrupamentoFinanceiro(value) : {};
};

export const saveAgrupamentoFinanceiro = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteAgrupamentoFinanceiro = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printAgrupamentoFinanceiro = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelAgrupamentoFinanceiro = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllAgrupamentoFinanceiro = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getSaldoConsolidado = async (filters) => {
  const response = await api.get(`${baseURL}/SearchSaldoConsolidado`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? [],
  };
};

const getDatasPorPeriodoDias = (dataInicio, dataFim) => {
  const dataInicial = new Date(`${dataInicio}T00:00:00`);
  const dataFinal = new Date(`${dataFim}T00:00:00`);
  const periodoDias = [];

  const diaCorrente = new Date(dataInicial.getTime());

  while (diaCorrente <= dataFinal) {
    periodoDias.push(new Date(diaCorrente).toLocaleDateString('pt-BR'));
    diaCorrente.setDate(diaCorrente.getDate() + 1);
  }
  return periodoDias;
};

const resultadoEmDias = (filters, valueResponse) => {
  const rangeDatas = getDatasPorPeriodoDias(
    filters.dataInicial,
    filters.dataFinal
  );
  const resultadoTratado = [];

  valueResponse.forEach((json) => {
    const index = resultadoTratado.findIndex(
      (item) =>
        item.categoria === json.categoria &&
        item.nrSeqCentroDeCusto === json.nrSeqCentroDeCusto &&
        item.nrSeqVeiculo === json.nrSeqVeiculo &&
        item.noContaCorrente === json.noContaCorrente &&
        item.noFormaPagamento === json.noFormaPagamento
    );

    // se achou na lista
    if (index !== -1) {
      const saldo = parseFloat(json.valor);
      const diajson = new Date(json.data).toLocaleDateString('pt-BR');

      resultadoTratado[index][diajson] = saldo;
      resultadoTratado[index].saldoTotalCategoria += saldo;
      resultadoTratado[index].noColorVlresultadoTratadorTitulo =
        json.noColorVlrTitulo;
      resultadoTratado[index].noContaCorrente = json.noContaCorrente;
      resultadoTratado[index].noFormaPagamento = json.noFormaPagamento;
      resultadoTratado[index].flgPrevisao = json.flgPrevisao;
    }
    //  se não achou na lista
    else {
      const diajson = new Date(json.data).toLocaleDateString('pt-BR');
      const saldo = parseFloat(json.valor);

      const noColorCategoria =
        json.grupoCategoria === 'DESPESA'
          ? 'bg-danger text-white'
          : 'bg-info text-white';

      const obj = {
        nrSeqCentroDeCusto: json.nrSeqCentroDeCusto,
        centroDeCusto: json.centroDeCusto,
        nrSeqVeiculo: json.nrSeqVeiculo,
        placa: json.placa,
        categoria: json.categoria,
        nrSeqExtratoCategoria: json.nrSeqExtratoCategoria,
        grupoCategoria: json.grupoCategoria,
        noColorCategoria,
        noColorVlrTitulo: json.noColorVlrTitulo,
        [diajson]: saldo,
        saldoTotalCategoria: saldo,
        noContaCorrente: json.noContaCorrente,
        noFormaPagamento: json.noFormaPagamento,
        flgPrevisao: json.flgPrevisao,
      };

      resultadoTratado.push(obj);
    }
  });

  rangeDatas.forEach((dia) => {
    resultadoTratado.forEach((item) => {
      if (!hasOwnProperty.call(item, dia)) {
        item[dia] = 0;
      }
    });
  });

  resultadoTratado.push(rangeDatas);

  return resultadoTratado ?? [];
};

const getDatasPorPeriodoMesAno = (dataInicio, dataFim) => {
  const dataInicialObj = new Date(`${dataInicio}T00:00:00`);
  dataInicialObj.setDate(1);
  const dataFinalObj = new Date(`${dataFim}T00:00:00`);
  dataFinalObj.setDate(1);

  const datas = [];

  while (dataInicialObj <= dataFinalObj) {
    const mes = dataInicialObj.getMonth() + 1;
    const ano = dataInicialObj.getFullYear();

    const dataFormatada = `${mes.toString().padStart(2, '0')}/${ano}`;
    datas.push(dataFormatada);

    dataInicialObj.setMonth(dataInicialObj.getMonth() + 1);
  }

  return datas;
};

const resultadoEmMesAno = (filters, valueResponse) => {
  const resultadoTratado = [];

  valueResponse.forEach((json) => {
    const index = resultadoTratado.findIndex(
      (item) =>
        item.categoria === json.categoria &&
        item.nrSeqCentroDeCusto === json.nrSeqCentroDeCusto &&
        item.nrSeqVeiculo === json.nrSeqVeiculo &&
        item.noContaCorrente === json.noContaCorrente &&
        item.noFormaPagamento === json.noFormaPagamento
    );

    // se achou na lista
    if (index !== -1) {
      const mesSplit = json.dataMesAno.slice(4, 6);
      const anoSplit = json.dataMesAno.slice(0, 4);
      const dataMesAnoFormatada = `${mesSplit}/${anoSplit}`;
      const saldo = parseFloat(json.valor);
      resultadoTratado[index].noContaCorrente = json.noContaCorrente;
      resultadoTratado[index].noFormaPagamento = json.noFormaPagamento;
      resultadoTratado[index].flgPrevisao = json.flgPrevisao;

      if (isNaN(resultadoTratado[index][dataMesAnoFormatada]))
        resultadoTratado[index][dataMesAnoFormatada] = 0;

      if (isNaN(resultadoTratado[index].saldoTotalCategoria))
        resultadoTratado[index][dataMesAnoFormatada] = 0;

      resultadoTratado[index][dataMesAnoFormatada] += saldo;

      resultadoTratado[index].saldoTotalCategoria += saldo;
    }
    //  se não achou na lista
    else {
      const mesSplit = json.dataMesAno.slice(4, 6);
      const anoSplit = json.dataMesAno.slice(0, 4);
      const dataMesAnoFormatada = `${mesSplit}/${anoSplit}`;
      const saldo = parseFloat(json.valor);

      const noColorCategoria =
        json.grupoCategoria === 'DESPESA'
          ? 'bg-danger text-white'
          : 'bg-info text-white';

      const obj = {
        nrSeqCentroDeCusto: json.nrSeqCentroDeCusto,
        centroDeCusto: json.centroDeCusto,
        nrSeqVeiculo: json.nrSeqVeiculo,
        placa: json.placa,
        categoria: json.categoria,
        nrSeqExtratoCategoria: json.nrSeqExtratoCategoria,
        grupoCategoria: json.grupoCategoria,
        noColorCategoria,
        [dataMesAnoFormatada]: saldo,
        saldoTotalCategoria: saldo,
        noContaCorrente: json.noContaCorrente,
        noFormaPagamento: json.noFormaPagamento,
        flgPrevisao: json.flgPrevisao,
      };

      resultadoTratado.push(obj);
    }
  });

  const rangeDatas = getDatasPorPeriodoMesAno(
    filters.dataInicial,
    filters.dataFinal
  );

  rangeDatas.forEach((dia) => {
    resultadoTratado.forEach((item) => {
      if (!hasOwnProperty.call(item, dia)) {
        item[dia] = 0;
      }
    });
  });

  resultadoTratado.push(rangeDatas);

  return resultadoTratado ?? [];
};

export const getFluxoCaixa = async (filters) => {
  const response = await api.get(
    `${baseURL}/SearchFluxoCaixaByAgrupamentoFinanceiro`,
    {
      params: filters,
      paramsSerializer: (params) => qs.stringify(params),
    }
  );

  const { status, message, value, pagination } = response.data ?? {};

  if (status === ResponseStatus.Error) {
    return {
      status,
      message,
    };
  }

  return {
    status,
    message,
    fluxoDeCaixa:
      filters.periodoBusca === 'Mes'
        ? resultadoEmMesAno(filters, value)
        : resultadoEmDias(filters, value),
    pagination: new Pagination(pagination),
  };
};

export const getFluxoCaixaByCentroDeCusto = async (filters) => {
  const response = await api.get(`${baseURL}/SearchFluxoCaixaByCentroDeCusto`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });

  const { status, message, value, pagination } = response.data ?? {};

  if (status === ResponseStatus.Error) {
    return {
      status,
      message,
    };
  }

  return {
    status,
    message,
    fluxoDeCaixa:
      filters.periodoBusca === 'Mes'
        ? resultadoEmMesAno(filters, value)
        : resultadoEmDias(filters, value),
    pagination: new Pagination(pagination),
  };
};

export const getFluxoCaixaByVeiculo = async (filters) => {
  const response = await api.get(`${baseURL}/SearchFluxoCaixaByVeiculo`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });

  const { status, message, value, pagination } = response.data ?? {};

  if (status === ResponseStatus.Error) {
    return {
      status,
      message,
    };
  }

  return {
    status,
    message,
    fluxoDeCaixa:
      filters.periodoBusca === 'Mes'
        ? resultadoEmMesAno(filters, value)
        : resultadoEmDias(filters, value),
    pagination: new Pagination(pagination),
  };
};

export const onPrintFluxoDeCaixa = async (filters) => {
  const response = await api.get(`${baseURL}/onPrintFluxoDeCaixa`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data;
};

export const getFluxoCaixaDetalhado = async (filters) => {
  const response = await api.get(`${baseURL}/SearchFluxoCaixaDetalhado`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? [],
  };
};

export const SearchFluxoCaixaDetalhadoVeiculoCentroCusto = async (filters) => {
  const response = await api.get(
    `${baseURL}/SearchFluxoCaixaDetalhadoVeiculoCentroCusto`,
    {
      params: filters,
      paramsSerializer: (params) => qs.stringify(params),
    }
  );
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? [],
  };
};
