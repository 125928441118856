import { Empresa, Usuario } from '../SEG';
// eslint-disable-next-line camelcase
import ChequeDeposito_Item from './chequeDeposito_Item';
import ContaCorrenteTransferencia from './contaCorrenteTransferencia';

export default class ChequeDeposito {
  constructor(jsonObject = {}) {
    this.nrSeqChequeDeposito = jsonObject.nrSeqChequeDeposito;
    this.dtCad = jsonObject.dtCad;
    this.dtCredito = jsonObject.dtCredito;
    this.flgDesconto = jsonObject.flgDesconto;
    this.nrSeqContaCorrenteTransferenci =
      jsonObject.nrSeqContaCorrenteTransferenci;
    this.nrSeqUsuario = jsonObject.nrSeqUsuario;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqContaCorrenteTransferencia =
      jsonObject.nrSeqContaCorrenteTransferencia;

    this.contaCorrenteTransferencia = new ContaCorrenteTransferencia(
      jsonObject.contaCorrenteTransferencia ?? {}
    );
    this.usuario = new Usuario(jsonObject.usuario ?? {});
    this.empresa = new Empresa(jsonObject.empresa ?? {});

    this.chequeDeposito_Item = (jsonObject.chequeDeposito_Item ?? []).map(
      // eslint-disable-next-line camelcase
      (chequeDeposito_Item) => new ChequeDeposito_Item(chequeDeposito_Item)
    );
  }

  toJson = () => JSON.stringify(this);
}
