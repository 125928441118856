import React, { useState, useEffect, useRef } from 'react';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import {
  Button,
  Modal,
  Notification,
  DatePicker,
  Textbox,
  GridView,
  Panel,
  Autocomplete,
  RadioButton,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getCondicaoPagamentoAutoComplete,
  getFormaPagamentoAutoComplete,
} from '../../../../core/services/FIN';
import {
  postGerarFaturamento,
  geraParcelasAutomatico,
} from '../../../../core/services/FRO/conhecimentosFaturar';

// #region COLUNAS
const columnsConhecimentos = [
  { key: 'nrSeqViagem', title: 'Viagem' },
  { key: 'cdColetaEmbarque', title: 'Número' },
  { key: 'empresa.noPessoa', title: 'Empresa' },
  { key: 'nrNotaFiscal', title: 'Nota' },
  { key: 'remetente.noPessoa', title: 'Origem' },
  { key: 'destinatario.noPessoa', title: 'Destino' },
  {
    key: 'dtEmissao',
    title: 'Data de Emissão',
    format: GridView.DataTypes.Date,
  },
  { key: 'vlrTotal', title: 'Valor', format: GridView.DataTypes.Money },
  { key: 'faturasConcat', title: 'Nr Fatura' },
];
// #endregion

export default function ModalGerarFaturamentoAcerto({
  show,
  onClose,
  conhecimentos,
  cliente,
  retornoSave,
}) {
  // #region Inicializadores
  const [message, setMessage] = useState(null);
  const [data, setData] = useState({});
  const [fatura, setFatura] = useState({});
  const [faturasLista, setFaturasLista] = useState([]);

  const gridViewConhecimentos = useRef();
  const gridViewParcelas = useRef();
  // #endregion

  // #region Funções
  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  useEffect(async () => {
    if (show) {
      const vlrTotalFatura = conhecimentos.reduce(
        (acc, currentValue) => acc + currentValue.vlrTotal,
        0
      );

      setData({
        vlrTotalFatura,
        vlrSaldoFaturar: vlrTotalFatura,
        conhecimentos,
        empresa: conhecimentos[0].empresa,
        cliente,
        quantidade: conhecimentos.length,
        flgAutomatico: 'S',
        flgBtnBoleto: false,
      });
      onSetMessage();

      if (gridViewConhecimentos && gridViewConhecimentos.current)
        gridViewConhecimentos.current.setDataSource(conhecimentos);
    }
  }, [show]);

  const onGerarFaturamentoAcertoModal = async () => {
    const faturaObj = {
      cliente: data.cliente,
      formaPagamento: data.formaPagamento,
    };

    const obj = {
      conhecimentos,
      parcelas: faturasLista,
      vlrTotalFatura: data.vlrTotalFatura,
      fatura: faturaObj,
      nrSeqEmpresa: data.empresa.nrSeqEmpresa,
      empresa: data.empresa,
      nrSeqPessoacli: data.cliente.nrSeqPessoaCli,
      cliente: data.cliente,
      nrSeqCondicaoPagamento: data.nrSeqCondicaoPagamento,
      condicaoPagamento: data.condicaoPagamento,
      nrSeqFormaPagamento: data.nrSeqFormaPagamento,
      formaPagamento: data.formaPagamento,
    };

    const response = await postGerarFaturamento(obj);

    if (response.status === ResponseStatus.Success) {
      if (gridViewConhecimentos && gridViewConhecimentos.current)
        gridViewConhecimentos.current.setDataSource([]);

      if (gridViewParcelas && gridViewParcelas.current)
        gridViewParcelas.current.setDataSource([]);

      setData({ ...data, flgBtnBoleto: true });
      // setFaturasLista({});
      setFatura({ ...fatura, nrSeqFatura: response.value.nrSeqFatura });
      retornoSave(fatura.nrSeqFatura);
    }

    setMessage({
      message: response.message,
      theme:
        response.status === ResponseStatus.Success
          ? Theme.Success
          : Theme.Danger,
    });
  };
  // #endregion

  // #region FORMAS DE PAGAMENTO
  const btnAutomatico = [
    { text: 'Sim', value: 'S' },
    { text: 'Não', value: 'N' },
  ];

  const onSearchCondicaoPagamento = async (e) => {
    const {
      status,
      message: msg,
      condicoesPagamento,
    } = await getCondicaoPagamentoAutoComplete({
      noCondicaoPagamento: e,
      flgTitPag: true,
    });
    if (msg) onSetMessage(status, msg);
    return condicoesPagamento;
  };

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noformaPagamento: e,
    });
    onSetMessage(status, msg);
    return formasPagamento;
  };

  const onGerarParcelaAutomatica = async () => {
    if (data.vlrTotalFatura <= 0) {
      return onSetMessage(
        ResponseStatus.Warning,
        'Valor da fatura deve ser mais que zero.'
      );
    }

    if (!data.nrSeqCondicaoPagamento) {
      return onSetMessage(
        ResponseStatus.Warning,
        'Selecione uma condição de pagamento.'
      );
    }

    const obj = {
      vlrTotalFatura: data.vlrTotalFatura,
      nrSeqEmpresa: data.empresa.nrSeqEmpresa,
      nrSeqPessoacli: data.cliente.nrSeqPessoaCli,
      nrseqCondicaoPagamento: data.nrSeqCondicaoPagamento,
      nrSeqFormaPagamento: data.nrSeqFormaPagamento,
    };

    const response = await geraParcelasAutomatico(obj);

    if (gridViewParcelas && gridViewParcelas.current) {
      gridViewParcelas.current.setDataSource(response.value);
    }

    setData({ ...data, vlrSaldoFaturar: 0 });
    return setFaturasLista(response.value);
  };

  const onAdicionarParcela = () => {
    if (!fatura.nrSeqFormaPagamento) {
      return onSetMessage(
        ResponseStatus.Warning,
        'Selecione uma forma de pagamento.'
      );
    }

    const faturasAux = [...faturasLista];

    const parcela = {
      vlrParcela: fatura.vlrParcela,
      dtVencimento: fatura.dtVencimento,
      formaPagamento: fatura.formaPagamento,
      nrSeqFormaPagamento: fatura.nrSeqFormaPagamento,
    };

    faturasAux.push(parcela);

    if (gridViewParcelas && gridViewParcelas.current) {
      gridViewParcelas.current.setDataSource(faturasAux);
    }

    let vlrSaldo = data.vlrTotalFatura;
    for (let i = 0; i < faturasAux.length; i += 1) {
      vlrSaldo -= faturasAux[i].vlrParcela;
    }

    setFatura({});
    setData({ ...data, vlrSaldoFaturar: vlrSaldo });
    return setFaturasLista(faturasAux);
  };
  // #endregion

  // #region PARCELAS
  const onClickEditFatura = async (selectedValue, datasource) => {
    const obj = datasource.find((item) => item === selectedValue);

    const faturas = datasource.filter((item) => item !== selectedValue);

    if (gridViewParcelas && gridViewParcelas.current) {
      gridViewParcelas.current.setDataSource(faturas);
    }

    setFatura({ ...obj, vlrParcela: obj.vlrTitulo });
    setFaturasLista(faturas);
  };

  const onClickRemoveFatura = async (selectedValue, datasource) => {
    const faturas = datasource.filter((item) => item !== selectedValue);

    if (gridViewParcelas && gridViewParcelas.current) {
      gridViewParcelas.current.setDataSource(faturas);
    }

    setFaturasLista(faturas);
  };

  const columnsParcelas = [
    { key: 'dtVencimento', title: 'Data', format: GridView.DataTypes.Date },
    { key: 'vlrParcela', title: 'Valor da Parcela' },
    { key: 'formaPagamento.noFormaPagamento', title: 'Forma de Pagamento' },
    {
      key: 'nrSeq',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditFatura(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeq',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveFatura(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];
  // #endregion

  return (
    <Modal
      show={show}
      title='Gerar Faturamento / Acerto'
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body className='p-4 pt-3'>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}

        {/* CONHECIMENTOS GRID */}
        <div className='row mb-3'>
          <Panel>
            <Panel.Header title='Conhecimentos' theme={Theme.Primary} />
            <Panel.Body>
              <div className='col'>
                <GridView
                  ref={gridViewConhecimentos}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsConhecimentos}
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </Panel.Body>
          </Panel>
        </div>

        {/* FORMAS DE PAGAMENTO */}
        <div className='row mb-3'>
          <Panel>
            <Panel.Header title='Formas de Pagamento' theme={Theme.Primary} />
            <Panel.Body>
              {/* Linha 1 */}
              <div className='row mb-3'>
                <div className='col-2 d-flex flex-column align-items-start flex-wrap-nowrap'>
                  <RadioButton
                    label='Gerar Automático?'
                    outline
                    size={BootstrapSizes.Small}
                    theme={Theme.Primary}
                    selectedButton={data.flgAutomatico}
                    buttons={btnAutomatico}
                    onChange={(flgAutomatico) => {
                      setData({
                        ...data,
                        flgAutomatico,
                      });
                    }}
                  />
                </div>
                <div className='col'>
                  <Autocomplete
                    label='Condições de Pagamento:'
                    enabled={data?.flgAutomatico === 'S'}
                    searchDataSource={onSearchCondicaoPagamento}
                    selectedItem={fatura?.condicaoPagamento}
                    onSelectItem={(condicaoPagamento) => {
                      setFatura({
                        ...fatura,
                        condicaoPagamento,
                        nrSeqCondicaoPagamento:
                          condicaoPagamento.nrSeqCondicaoPagamento,
                      });
                      setData({
                        ...data,
                        condicaoPagamento,
                        nrSeqCondicaoPagamento:
                          condicaoPagamento.nrSeqCondicaoPagamento,
                      });
                    }}
                    dataSourceTextProperty='noCondicaoPagamento'
                  />
                </div>
                <div className='col'>
                  <Autocomplete
                    label='Forma de Pagamento:'
                    searchDataSource={onSearchFormaPagamento}
                    selectedItem={fatura?.formaPagamento}
                    onSelectItem={(formaPagamento) => {
                      setFatura({
                        ...fatura,
                        formaPagamento,
                        nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                      });
                      setData({
                        ...data,
                        formaPagamento,
                        nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                      });
                    }}
                    dataSourceTextProperty='noFormaPagamento'
                  />
                </div>
                <div className='col-3 btn-group mr-2'>
                  <Button
                    text='Gerar Parcelas Automáticas'
                    className='mb-3 mt-4'
                    size={BootstrapSizes.Small}
                    theme={
                      data?.flgAutomatico === 'S'
                        ? Theme.Success
                        : Theme.Inactive
                    }
                    template={Button.Templates.Default}
                    onClick={
                      data?.flgAutomatico === 'S'
                        ? onGerarParcelaAutomatica
                        : null
                    }
                  />
                </div>
              </div>

              {/* Linha 2 */}
              <div className='row mb-3'>
                <div className='col-2'>
                  <DatePicker
                    label='Vencimento:'
                    readOnly={data?.flgAutomatico === 'S'}
                    text={fatura?.dtVencimento}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChange={(dtVencimento) => {
                      setFatura({ ...fatura, dtVencimento });
                    }}
                  />
                </div>
                <div className='col-2'>
                  <Textbox
                    label='Valor da Parcela:'
                    readOnly={data?.flgAutomatico === 'S'}
                    maxLength={20}
                    text={fatura?.vlrParcela}
                    mask={MaskTypes.Currency}
                    onChangedValue={(vlrParcela) =>
                      setFatura({ ...fatura, vlrParcela })
                    }
                  />
                </div>
                <div className='col-2 btn-group'>
                  <Button
                    text='Adicionar'
                    className='mb-3 mt-4'
                    icon='plus'
                    size={BootstrapSizes.Small}
                    theme={
                      data?.flgAutomatico === 'N'
                        ? Theme.Success
                        : Theme.Inactive
                    }
                    template={Button.Templates.Default}
                    onClick={
                      data?.flgAutomatico === 'N' ? onAdicionarParcela : null
                    }
                    // outline
                  />
                </div>

                <div className='col-2 mb-3'>
                  <Textbox
                    label='Valor Total a Faturar:'
                    maxLength={20}
                    readOnly
                    text={data.vlrTotalFatura}
                    mask={MaskTypes.Currency}
                    onChangedValue={(vlrTotal) =>
                      setData({
                        ...data,
                        vlrTotal,
                      })
                    }
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    label='Selecionados:'
                    maxLength={20}
                    readOnly
                    text={data.quantidade}
                    mask={MaskTypes.Integer}
                    onChangedValue={(quantidade) =>
                      setData({
                        ...data,
                        quantidade,
                      })
                    }
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    label='Saldo a Faturar:'
                    maxLength={20}
                    readOnly
                    text={data.vlrSaldoFaturar}
                    mask={MaskTypes.Currency}
                  />
                </div>
              </div>

              <div className='row'>
                {data?.flgBtnBoleto && (
                  <div className='d-flex justify-content-end'>
                    <Button
                      text='Imprimir Boleto'
                      theme={Theme.Success}
                      icon={['far', 'print']}
                    />
                  </div>
                )}
              </div>
            </Panel.Body>
          </Panel>
        </div>

        {/* PARCELAS GRID */}
        <div className='row mb-3'>
          <Panel>
            <Panel.Header title='Parcelas' theme={Theme.Primary} />
            <Panel.Body>
              <div className='col'>
                <GridView
                  ref={gridViewParcelas}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsParcelas}
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Gerar Faturamento'
          theme={data?.flgBtnBoleto ? Theme.Inactive : Theme.Success}
          icon={['far', 'save']}
          onClick={() =>
            !data?.flgBtnBoleto
              ? onGerarFaturamentoAcertoModal()
              : onSetMessage(
                  ResponseStatus.Warning,
                  'Faturamente já foi gerado.'
                )
          }
        />
        <Button
          text='Fechar'
          theme={Theme.Danger}
          icon='ban'
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
}
