import React, { useState } from 'react';
import { Loader, Modal, Notification, Textbox, Button } from 'ui/components';
import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/enums';
import { Message } from 'ui/Helpers/interfaces';
import { IPneuDimensao } from 'core/interfaces/FRO/pneuDimensao';
import { savePneuDimensao } from 'core/services/FRO/pneuDimensao';

type Props = {
  show: boolean;
  onClose: () => void;
};

export default function ModalPneuDimensao({ show, onClose }: Props) {
  const [message, setMessage] = useState<Message | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [pneuDimensao, setPneuDimensao] = useState<IPneuDimensao | null>(null);

  const onClickCadastrar = async () => {
    setLoading(true);

    const { status, message: msg } = await savePneuDimensao(pneuDimensao);

    if (status === ResponseStatus.Success) {
      setMessage({ theme: Theme.Success, message: msg });
      setPneuDimensao(null);
    } else {
      setMessage({ theme: Theme.Danger, message: msg });
    }

    setLoading(false);
  };

  return (
    // @ts-expect-error
    <Modal
      show={show}
      title='Cadastro Rápido de Pneu - Medida/Dimensão'
      size={BootstrapSizes.Medium}
      onClose={onClose}
    >
      <Loader loading={loading} />
      {message && (
        <div className='m-3'>
          {/* @ts-expect-error */}
          <Notification
            message={message.message}
            theme={message.theme}
            onClose={() => setMessage(null)}
          />
        </div>
      )}

      {/* @ts-expect-error */}
      <Modal.Body>
        <div className='row'>
          <div className='col-9'>
            {/* @ts-expect-error */}
            <Textbox
              label='Medida/Dimensão'
              text={pneuDimensao?.noPneuDimensao}
              onChangedValue={(noPneuDimensao: string) =>
                setPneuDimensao({ noPneuDimensao })
              }
            />
          </div>
          <div className='col-2 d-flex align-items-end'>
            <Button
              text='Cadastrar'
              theme={Theme.Success}
              onClick={onClickCadastrar}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
