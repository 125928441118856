import React, { useState } from 'react';
import { setTransaction } from 'core/services/api';
import {
  Confirm,
  Loader,
  Notification,
  Panel,
  ToolbarButtons,
} from 'ui/components';

export default function CSDSelPage(props) {
  const {
    children,
    loading,
    isActive,
    title,
    message,
    onMessagerClose,
    onSearch,
    onNew,
    onDelete,
    onPrint,
    onDownloadExcel,
    transaction,
    disableSearchEnter,
  } = props;

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const handleDelete = async ({ confirmed, canceled }) => {
    if (!confirmed && !canceled) setShowConfirmDelete(true);
    else if (confirmed) {
      await onDelete();
      setShowConfirmDelete(false);
      setTransaction(transaction ? transaction.nrSeqTransacao : 0);
    } else setShowConfirmDelete(false);
  };

  const handleSubmit = () => {
    if (onSearch) onSearch();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Panel className={!isActive ? 'd-none' : 'CSDPage'}>
        <Loader loading={loading} />

        {onDelete && transaction.permissao.flgExcluir && (
          <Confirm
            show={showConfirmDelete}
            title='Confirmar a exclusão do(s) registro(s)'
            titleIcon='exclamation-triangle'
            text='Você confirma a exclusão do(s) registro(s) selecionados ?'
            handleConfirm={async () => {
              await handleDelete({ confirmed: true });
            }}
            handleCancel={() => handleDelete({ canceled: true })}
          />
        )}

        <Panel.Header title={title} classSize='display-7'>
          <ToolbarButtons fixedOnTop>
            {onNew && transaction.permissao.flgNovo && (
              <ToolbarButtons.Button text='Novo' icon='plus' onClick={onNew} />
            )}
            {onSearch &&
              !disableSearchEnter &&
              transaction.permissao.flgPesquisar && (
                <ToolbarButtons.Button
                  text='Pesquisar'
                  icon='search'
                  onClick={async (event) => {
                    event.preventDefault();
                    await onSearch();
                  }}
                  type='submit'
                />
              )}

            {onSearch &&
              disableSearchEnter &&
              transaction.permissao.flgPesquisar && (
                <ToolbarButtons.Button
                  text='Pesquisar'
                  icon='search'
                  onClick={async (event) => {
                    event.preventDefault();
                    await onSearch();
                  }}
                />
              )}
            {onDelete && transaction.permissao.flgExcluir && (
              <ToolbarButtons.Button
                text='Excluir'
                icon='trash-alt'
                onClick={handleDelete}
              />
            )}
            {onPrint && transaction.permissao.flgImprimir && (
              <ToolbarButtons.Button
                text='Imprimir'
                icon='print'
                onClick={async () => {
                  await onPrint();
                }}
              />
            )}
            {onDownloadExcel && transaction.permissao.flgImprimir && (
              <ToolbarButtons.Button
                text='Excel'
                icon={['far', 'file-excel']}
                onClick={async () => {
                  const { base64, fileName } = await onDownloadExcel();
                  const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
                  const downloadLink = document.createElement('a');
                  downloadLink.href = linkSource;
                  downloadLink.download = fileName;
                  downloadLink.click();
                }}
              />
            )}
            {children &&
              React.Children.map(children, (child) =>
                child && child.type.name === ToolbarButtons.name
                  ? child.props.children
                  : null
              )}
          </ToolbarButtons>
        </Panel.Header>
        <Panel.Body>
          {message && (
            <div className='mb-3'>
              <Notification
                message={message.message}
                theme={message.theme}
                onClose={onMessagerClose}
              />
            </div>
          )}
          {children &&
            React.Children.map(children, (child) =>
              child && child.type.name !== ToolbarButtons.name ? child : null
            )}
        </Panel.Body>
      </Panel>
    </form>
  );
}
