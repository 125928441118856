import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getChequeDepositoPagined,
  printChequeDeposito,
  deleteAllChequeDeposito,
  excelChequeDeposito,
} from 'core/services/FIN/chequeDeposito';
import { CSDSelPage, GridView, DatePicker } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';

const columns = [
  { key: 'nrSeqChequeDeposito', type: GridView.ColumnTypes.Checkbox },
  {
    key: 'contaCorrenteTransferencia.noContaCorrenteTransferencia',
    title: 'Descrição Transferência',
  },
  { key: 'dtCredito', title: 'Data Crédito', format: GridView.DataTypes.Date },
  { key: 'dtCad', title: 'Data Cadastro' },
  { key: 'usuario.noLogin', title: 'Usuário' },
  {
    key: 'empresa.noPessoa',
    title: 'Empresa',
  },
];

export default function ChequeDeposito({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const formatDateGrid = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getChequeDepositoPagined(
      params ?? filtros
    );
    const formattedData = data.map((item) => ({
      ...item,
      dtCad: formatDateGrid(item.dtCad),
    }));

    if (gridView && gridView.current)
      gridView.current.setDataSource(formattedData, pagination);

    setLoading(false);
  };

  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getChequeDepositoPagined({});

      const formattedData = data.map((item) => ({
        ...item,
        dtCad: formatDateGrid(item.dtCad),
      }));

      if (gridView && gridView.current)
        gridView.current.setDataSource(formattedData, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'ChequeDeposito/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqChequeDeposito = sourceRow.find(
      (e) => e.key === 'nrSeqChequeDeposito'
    );
    onOpenMaintenance(nrSeqChequeDeposito.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printChequeDeposito(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onDownloadExcel = async () => {
    setLoading(true);

    const { value: base64 } = await excelChequeDeposito(filtros);

    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'ChequeDeposito.csv';
      downloadLink.click();
    }

    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllChequeDeposito(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Cheque Deposito'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      onDownloadExcel={onDownloadExcel}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <div className='col-2'>
          <DatePicker
            label='Data Inicial'
            text={filtros.dtInicial}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtInicial) => {
              if (dtInicial > filtros.dtFinal) {
                setMessage({
                  message:
                    'A Data Inicial não pode ser maior que a Data Final.',
                  theme: Theme.Danger,
                });
              } else {
                setFiltros({ ...filtros, dtInicial });
              }
            }}
          />
        </div>

        <div className='col-2'>
          <DatePicker
            label='Data Final'
            text={filtros.dtFinal}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtFinal) => {
              if (filtros.dtInicial > dtFinal) {
                setMessage({
                  message:
                    'A Data Final não pode ser menor que a Data Inicial.',
                  theme: Theme.Danger,
                });
              } else {
                setFiltros({ ...filtros, dtFinal });
              }
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
