import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Button,
  DatePicker,
  Modal,
  Notification,
  Textbox,
} from 'ui/components';
import EnergiaConta from 'core/models/ENE/energiaConta';
import EnergiaUnidadeConsumidora from 'core/models/ENE/energiaUnidadeConsumidora';
import { BootstrapSizes, MaskTypes, StatusGrid, Theme } from 'ui/Helpers/enums';
import { Message } from 'ui/Helpers/interfaces';

interface IProps {
  data: EnergiaUnidadeConsumidora | {};
  setData: Dispatch<SetStateAction<{} | EnergiaUnidadeConsumidora>>;
  gridView: any;
  onClose: () => void;
  show: boolean;
}

export const ModalAdicionarFatura = ({
  data,
  setData,
  gridView,
  show,
  onClose,
}: IProps) => {
  // @ts-expect-error
  const [fatura, setFatura] = useState<EnergiaConta>({});
  const [message, setMessage] = useState<Message>();

  const onSetMessage = (msg: string, theme: string) => {
    setMessage({
      message: msg,
      theme,
    });
  };

  const handleAdd = () => {
    if (!fatura.qtdDiasConsumo) {
      onSetMessage('Necessário informar quantidade de dias.', Theme.Warning);

      return;
    }

    if (!fatura.anoMesRef || fatura.anoMesRef.toString().length < 6) {
      onSetMessage(
        'Necessário informar ano/mês referência no formato esperado.',
        Theme.Warning
      );

      return;
    }

    if (
      fatura.anoMesRef &&
      (Number(fatura.anoMesRef.toString().slice(-2)) < 1 ||
        Number(fatura.anoMesRef.toString().slice(-2)) > 12)
    ) {
      onSetMessage(
        'Mês de referência precisa ser entre 01 e 12.',
        Theme.Warning
      );

      return;
    }

    if (!fatura.vlrConta) {
      onSetMessage('Necessário informar valor da conta.', Theme.Warning);

      return;
    }

    if (!fatura.dtVencimento) {
      onSetMessage('Necessário informar data de vencimento.', Theme.Warning);

      return;
    }

    if (!fatura.dtLeitura) {
      onSetMessage('Necessário informar data de leitura.', Theme.Warning);

      return;
    }

    if (!fatura.dtLeituraAnterior) {
      onSetMessage(
        'Necessário informar data de leitura anterior.',
        Theme.Warning
      );

      return;
    }

    if (data && 'contas' in data) {
      const jaExiste = data.contas.find(
        (c) => c.anoMesRef === fatura.anoMesRef
      );

      if (jaExiste) {
        onSetMessage(
          'Já exise uma conta cadastrada para esse ano/mês referência.',
          Theme.Warning
        );

        return;
      }

      fatura.status = StatusGrid.Inserir;

      const conta = new EnergiaConta(fatura);

      setData({ ...data, contas: [...data.contas, conta] });

      if (gridView && gridView.current) {
        gridView.current.setDataSource([
          ...gridView.current.getDataSource(),
          conta,
        ]);
      }

      // @ts-expect-error
      setFatura({});
      onClose();
    }
  };

  return (
    // @ts-expect-error
    <Modal
      show={show}
      title='Adicionar fatura'
      onClose={onClose}
      size={BootstrapSizes.Large}
    >
      <Notification
        floatable
        message={message?.message}
        theme={message?.theme}
        onClose={() => setMessage(undefined)}
      />
      {/* @ts-expect-error */}
      <Modal.Body>
        <div className='row mb-3'>
          <div className='col-4'>
            {/* @ts-expect-error */}
            <Textbox
              label='Qtd. Dias consumo'
              mask={MaskTypes.Integer}
              maxLength={2}
              text={fatura?.qtdDiasConsumo}
              onChangedValue={(qtdDiasConsumo: number) =>
                setFatura({ ...fatura, qtdDiasConsumo })
              }
              required
            />
          </div>
          <div className='col-4'>
            {/* @ts-expect-error */}
            <Textbox
              label='Ano/Mês Ref.'
              mask={MaskTypes.Integer}
              maxLength={6}
              text={fatura?.anoMesRef}
              onChangedValue={(anoMesRef: number) =>
                setFatura({ ...fatura, anoMesRef })
              }
              required
              placeholder='Ex: 202501'
            />
          </div>
          <div className='col-4'>
            {/* @ts-expect-error */}
            <Textbox
              label='Valor'
              mask={MaskTypes.Currency}
              maxLength={18}
              text={fatura?.vlrConta}
              onChangedValue={(vlrConta: number) =>
                setFatura({ ...fatura, vlrConta })
              }
              required
            />
          </div>
        </div>

        <div className='row mb-5'>
          <div className='col-4'>
            <DatePicker
              label='Dt. Vencimento'
              text={fatura?.dtVencimento}
              onChange={(dtVencimento: Date) =>
                setFatura({ ...fatura, dtVencimento })
              }
            />
          </div>
          <div className='col-4'>
            <DatePicker
              label='Dt. Leitura'
              text={fatura?.dtLeitura}
              onChange={(dtLeitura: Date) =>
                setFatura({ ...fatura, dtLeitura })
              }
            />
          </div>
          <div className='col-4'>
            <DatePicker
              label='Dt. Leitura Anterior'
              text={fatura?.dtLeituraAnterior}
              onChange={(dtLeituraAnterior: Date) =>
                setFatura({ ...fatura, dtLeituraAnterior })
              }
            />
          </div>
        </div>

        <div className='d-flex justify-content-center mt-5'>
          <Button
            text='Adicionar'
            theme={Theme.Primary}
            size={BootstrapSizes.Large}
            onClick={() => handleAdd()}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
