import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mask, unMask, MaskTypes } from 'ui/Helpers/masks';
import { generateID } from 'ui/Helpers/utils';
import { CSDBasePageContext } from 'ui/components/';

const Textbox = ({
  mask: maskType,
  onChangedValue,
  onKeyDown,
  onPrependIconClick,
  visible,
  type = 'text',
  placeholder = '...',
  className,
  id = generateID(),
  required,
  readOnly,
  maxLength,
  showClear,
  label,
  prependIcon,
  prependRender,
  appendIcon,
  invalidFeedback,
  text,
  keyValue,
  onBlur,
  row,
  decimalPlaces,
  disabledTabIndex,
  textRight,
  borderColor,
  title,
  zeroInBlank,
  onEnter,
  fixedOnTop,
}) => {
  const inputEl = useRef(null);
  const context = React.useContext(CSDBasePageContext);
  const { settings } = context ?? [];

  const [textState, setTextState] = useState(undefined);
  const [isTapping, setIsTapping] = useState(false);
  const [onTop, setOnTop] = useState(false);
  const controlRenderFixedOnTop = useRef();

  useEffect(() => {
    if (text === null) text = '';
    if (maskType !== 9) {
      setTextState(text);
    } else if (text !== undefined) {
      const valuecheck = String(text).replace(',', '.');
      if (isNaN(valuecheck)) {
        return;
      }

      if (!isTapping) {
        if (text !== '') {
          setTextState(mask(text, maskType, decimalPlaces));
        } else {
          setTextState(mask(0, maskType));
        }
      } else {
        const formated = String(text).replace('.', ',');
        setTextState(formated);
        setIsTapping(false);
      }
    }
  }, [text]);

  const scrollHandler = () => {
    if (fixedOnTop) {
      if (inputEl) {
        if (inputEl.current) {
          const distanciaTopo = inputEl.current.getBoundingClientRect().top;

          let dif = 1;
          if (controlRenderFixedOnTop.current)
            dif = (Date.now() - controlRenderFixedOnTop.current) / 1000;

          if (distanciaTopo < 50 && dif > 0.1) {
            controlRenderFixedOnTop.current = Date.now();
            setOnTop(true);
          } else if (dif > 0.1) {
            setOnTop(false);
          }
        }
      }
    }
  };

  const handleBlur = (e) => {
    let value = '';
    if (maskType === MaskTypes.DecimalCustom) {
      value = e.target.value;
      if (value.slice(-1) === ',') value += '0';

      const places = decimalPlaces !== undefined ? decimalPlaces : 2;

      value = mask(value, maskType, places);

      setTextState(value);
    }

    if (maskType === MaskTypes.Time) {
      if (textState !== undefined) {
        if (textState.length === 1) {
          const valor = `${textState}00000`;
          value = mask(valor, maskType);

          setTextState(value);
        }
        if (textState.length === 2) {
          const valor = `${textState}0000`;
          value = mask(valor, maskType);

          setTextState(value);
        }
        if (textState.length === 3) {
          const valor = `${textState}000`;
          value = mask(valor, maskType);

          setTextState(value);
        }
        if (textState.length === 4) {
          const valor = `${textState}00`;
          value = mask(valor, maskType);

          setTextState(value);
        }
        if (textState.length === 5) {
          const valor = `${textState}0`;
          value = mask(valor, maskType);

          setTextState(value);
        }
      }
    }

    if (onBlur) {
      if (maskType !== MaskTypes.DecimalCustom)
        value = mask(e.target.value, maskType);

      if (
        maskType === MaskTypes.Currency ||
        maskType === MaskTypes.Decimal ||
        maskType === MaskTypes.DecimalCustom
      )
        value = unMask(value, maskType);

      onBlur(keyValue, value, row);
    }
  };

  const handleValueChange = (e) => {
    let value = '';

    if (maskType !== MaskTypes.DecimalCustom) {
      value = mask(e.target.value, maskType, decimalPlaces);
      setTextState(value);
    } else {
      let valuecheck = e.target.value.replace('.', '');
      valuecheck = valuecheck.replace(',', '.');

      if (!isNaN(valuecheck)) {
        value = e.target.value.replace('.', '');
        setTextState(value);
      }
    }

    if (maskType === MaskTypes.Currency || maskType === MaskTypes.Decimal) {
      value = unMask(value, maskType, decimalPlaces);
      setTextState(value);
    }

    if (onChangedValue && maskType !== MaskTypes.DecimalCustom) {
      onChangedValue(value);
    } else if (onChangedValue && maskType === MaskTypes.DecimalCustom) {
      value = mask(value, maskType, decimalPlaces);
      value = unMask(value, maskType, decimalPlaces);
      onChangedValue(value);
    }
  };
  const handleKeyDown = (e) => {
    if (onEnter) {
      if (e.code === 'NumpadEnter' || e.code === 'Enter') {
        onEnter();

        setTextState('');
      }
    }

    setIsTapping(true);
    if (onKeyDown) onKeyDown(e);
  };
  const handlePrependIconClick = () => {
    if (onPrependIconClick) onPrependIconClick(inputEl.current.value);
  };

  let placehoderValue = placeholder;
  if (onTop && fixedOnTop) {
    placehoderValue = label;
  }
  const inputProps = {
    ref: inputEl,
    type,
    placeholder: placehoderValue,
    className: 'form-control',
    value: mask(textState ?? '', maskType, decimalPlaces, true, zeroInBlank),
    onChange: handleValueChange,
    onKeyDown: handleKeyDown,
    onBlur: handleBlur,
    id,
  };

  if (zeroInBlank && inputProps.value === '0,00') inputProps.value = '';

  if (disabledTabIndex) inputProps.tabIndex = '-1';
  if (className) inputProps.className += ` ${className}`;
  if (required) inputProps.required = required;
  if (readOnly) inputProps.readOnly = readOnly;
  if (maxLength) inputProps.maxLength = maxLength;
  if (title) inputProps.title = title;
  if (borderColor) {
    inputProps.style = { border: `1px solid ${borderColor}` };
  }

  if (fixedOnTop) {
    if (onTop) {
      inputProps.style = {
        position: 'fixed',
        top: '50px',
        zIndex: 3,
        width: '200px',
      };
    } else {
      inputProps.style = { position: 'relative' };
    }
  }

  if (textRight) {
    inputProps.className += ' text-end';
  }

  if (showClear && (textState?.length > 0 || textState === 0))
    inputProps.className += ' clear';

  // Carrega as configurações do banco se tiver e sobrescreve as configurações do componente
  const setting = settings?.find((p) => p.noCampo === id);

  let isVisible = visible ?? true;
  if (setting?.flgVisivel) {
    isVisible = setting?.flgVisivel;
  }

  const renderedLabel = (setting?.noLabel || label) && (
    <label className='form-label' htmlFor={id}>
      {(required || setting?.flgObrigatorio) && '* '}
      {setting?.noLabel || label}
    </label>
  );

  if ((setting?.flgObrigatorio ?? undefined) !== undefined) {
    inputProps.required = setting.flgObrigatorio;
  }
  if ((setting?.flgHabilitado ?? undefined) !== undefined)
    inputProps.disabled = !setting.flgHabilitado;

  useEffect(() => {
    if (setting?.noDefaultValue) setTextState(setting?.noDefaultValue);
  }, [setting]);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler, true);
  }, []);

  return isVisible ? (
    <>
      {renderedLabel}
      <div className='CSDTextbox input-group'>
        {prependIcon && (
          <div
            role='presentation'
            className={`input-group-text ${
              onPrependIconClick ? 'cursor-pointer' : ''
            }`}
            onClick={handlePrependIconClick}
          >
            <FontAwesomeIcon icon={prependIcon} />
          </div>
        )}

        {prependRender && prependRender}

        {React.createElement('input', inputProps)}
        {showClear && (
          <div className='texbox-inner-content'>
            <span
              role='presentation'
              className='textbox-clear'
              onClick={() => onChangedValue && onChangedValue(null)}
            >
              <FontAwesomeIcon icon='times' />
            </span>
          </div>
        )}

        {appendIcon && (
          <div className='input-group-text'>
            <FontAwesomeIcon icon={appendIcon} />
          </div>
        )}
        {invalidFeedback && (
          <div className='invalid-feedback'>{invalidFeedback}</div>
        )}
      </div>
    </>
  ) : null;
};

export default Textbox;
