import React, { useState, useRef, useEffect } from 'react';

import {
  CSDSelPage,
  GridView,
  Autocomplete,
  DatePicker,
  Panel,
  Textbox,
  Switch,
  RadioButton,
  Notification,
  Button,
  ToolbarButtons,
} from 'ui/components';
import {
  Theme,
  ResponseStatus,
  JustifyContent,
  BootstrapSizes,
  dateNow,
} from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';

import ColetaEmbarque from 'core/models/FRO/coletaEmbarque';
import { getTipoEmbarqueAutoComplete } from 'core/services/FRO/tipoEmbarque';
import {
  getColetaEmbarquePagined,
  getColetaEmbarque,
  printColetaEmbarque,
  printCartaCorrecao,
  corrigirCte,
  transmitCte,
  averbarCte,
  verificarCte,
  envioEDI,
  downloadCte,
  desvincularCteRef,
  enviaEmail,
  solicitaContingencia,
  downloadImageColetaEmbarqueCTE,
  printRelatorioRemessas,
  printRelatorioRemessasSimplificado,
  SearchAgrupamentoCte,
  getPesquisaGerenciarCTESimplificada,
} from 'core/services/FRO/coletaEmbarque';

import { downloadPdfColetaEmbarque } from 'core/services/COP/nfeimportada.tsx';

import {
  getEmpresaAutoComplete,
  getEmpresaDefaultUsuario,
} from 'core/services/SEG/empresa';
import { getPessoaAutoCompleteTipoNome } from 'core/services/SEG/pessoa';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import { getTipoOcorrenciaColetaEmbarqueAutoComplete } from 'core/services/FRO/tipoOcorrenciaColetaEmbarque';
import { getDestinatarioSemRemetenteAutoComplete } from 'core/services/FRO/remetente_Destinatario';

import { getFreteMercadoriaAutoComplete } from 'core/services/FRO/freteMercadoria';

import ModalDestalhesCte from 'ui/pages/FRO/GerenciarCte/modalDetalhesCte';
import ModalAnulacaoCte from 'ui/pages/FRO/GerenciarCte/modalAnulacaoCte';
import ModalCancelarCte from 'ui/pages/FRO/GerenciarCte/modalCancelarCte';
import ModalInutilizarCte from 'ui/pages/FRO/GerenciarCte/modalInutilizarCte';
import ModalCorrigirCte from 'ui/pages/FRO/GerenciarCte/modalCorrgirCte';
import ModalGeraComplemento from 'ui/pages/FRO/GerenciarCte/modalGeraComplemento';
import ModalAverbaCte from 'ui/pages/FRO/GerenciarCte/modalAverbaCte';
import ModalCancelamentoExtemporaneo from 'ui/pages/FRO/GerenciarCte/modalCancelamentoExtemporaneo.tsx';
import ModalInformaProtocolo from 'ui/pages/FRO/GerenciarCte/modalInformaProtocolo.tsx';
import ModalAverbaLoteCtes from 'ui/pages/FRO/GerenciarCte/modalAverbaLoteCtes';
import ModalOcorrenciasCte from 'ui/pages/FRO/GerenciarCte/modalOcorrenciasCte';
import ModalComprovanteEntrega from 'ui/pages/FRO/Viagem/modalComprovanteEntrega.tsx';
import ModalAgruparCte from './modalAgruparCte';

export default function GerenciarCte({
  transaction,
  isActive,
  onOpenReport,
  findTransaction,
  onOpenTransaction,
  registryKey,
}) {
  const gridView = useRef();

  const modalComprovanteEntregaRef = useRef({});
  const [message, setMessage] = useState(null);
  const [pesquisaSimplificada, setPesquisaSimplificada] = useState(false);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filtros, setFiltros] = useState({});
  const [modalComprovanteEntrega, setModalComprovanteEntrega] = useState({});
  const [gridVisible, setGridVisible] = useState(false);
  const [modalShow, setModalShow] = useState({});

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onClickOpenModalComprovanteEntrega = (e) => {
    setModalComprovanteEntrega({
      show: true,
      nrSeqColetaEmbarque: e.nrSeqColetaEmbarque,
    });
  };

  const onClickTransmitirCte = async (e) => {
    setLoading(true);

    const { messages: msgs } = await transmitCte(e);

    if (msgs) setMessages(msgs);

    setLoading(false);
  };

  const onClickCancelarCte = (e) => {
    setModalShow({
      ...modalShow,
      cancelarCte: {
        show: true,
        cte: e,
      },
    });
  };

  const onClickCancelarExtemporaneoCte = (e) => {
    setModalShow({
      ...modalShow,
      cancelamentoExtemporaneo: {
        show: true,
        cte: e,
      },
    });
  };

  const onClickCorrigir = async (e) => {
    const { status, message: msg, data: retorno } = await corrigirCte({
      nrSeqColetaEmbarque: e.nrSeqColetaEmbarque,
      validate: true,
    });

    if (retorno) {
      setModalShow({ ...modalShow, corrigirCte: { show: true, cte: e } });
    }

    if (msg) onSetMessage(status, msg);
  };

  const onClickImprimirCte = async (e) => {
    setLoading(true);

    const { status, message: msg, value } = await printColetaEmbarque(e);

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success)
      onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  const onClickImprimirCartaCorrecao = async (e) => {
    setLoading(true);

    const { status, message: msg, value } = await printCartaCorrecao(e);

    if (status === ResponseStatus.Success)
      onOpenReport(transaction.noTransacao, value);
    else {
      onSetMessage(status, msg);
    }

    setLoading(false);
  };

  const onClickDetalhes = async (e) => {
    const cte = await getColetaEmbarque(e.nrSeqColetaEmbarque);

    setModalShow({
      ...modalShow,
      detalhes: {
        show: true,
        cte,
      },
    });
  };

  const averbaCte = async (cte) => {
    setLoading(true);

    const { status, message: msg, messages: msgs } = await averbarCte({
      nrSeqColetaEmbarque: cte.nrSeqColetaEmbarque,
    });

    const messagesAverbacao = [];

    if (msg) {
      messagesAverbacao.push({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }

    if (msgs && msgs?.length > 0) {
      setMessages([...messagesAverbacao, ...msgs]);
    }

    setLoading(false);
  };

  const onClickAverbar = async (e) => {
    if (e.nrSeqApoliceSeguro && e.flgGeraSeguro) {
      await averbaCte(e);
    } else {
      setModalShow({
        ...modalShow,
        averbaCte: {
          show: true,
          cte: e,
        },
      });
    }
  };

  const onClickVerificarCte = async (e) => {
    setLoading(true);

    const { status, message: msg, data: cte } = await verificarCte({
      nrSeqColetaEmbarque: e.nrSeqColetaEmbarque,
    });

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      let datasource = gridView.current.getDataSource();

      datasource = datasource.map((item) => {
        if (item.nrSeqColetaEmbarque === e.nrSeqColetaEmbarque) {
          cte.statusCteGrid = ColetaEmbarque.EnumStatusCte[cte.statusCte];

          return cte;
        }

        return item;
      });

      if (gridView && gridView.current)
        gridView.current.setDataSource(datasource);
    }

    setLoading(false);
  };

  const onClickDownload = async (e) => {
    setLoading(true);

    const { data: xmls } = await downloadCte({
      nrSeqColetaEmbarque: e.nrSeqColetaEmbarque,
    });

    if (xmls) {
      for (let i = 0; i < xmls.length; i += 1) {
        const xmlURI = encodeURIComponent(xmls[i].xml);
        const downloadLink = document.createElement('a');
        downloadLink.href = `data:text/plain;charset=utf-8,${xmlURI}`;
        downloadLink.download = `CTe_${e.cdColetaEmbarque}.xml`;
        downloadLink.download = `${xmls[i].nome}_${e.cdColetaEmbarque}.xml`;
        downloadLink.click();
      }
    }
    setLoading(false);
  };

  const onClickEmail = async (e) => {
    setLoading(true);

    const { status, message: msg } = await enviaEmail({
      nrSeqColetaEmbarque: e.nrSeqColetaEmbarque,
    });

    if (msg) onSetMessage(status, msg);

    setLoading(false);
  };

  const onClickGeraComplemento = (e) => {
    setModalShow({
      ...modalShow,
      geraComplemento: {
        show: true,
        cte: e,
      },
    });
  };

  const donwloadEdi = (arquivo, nomeArquivo) => {
    const blob = new Blob([arquivo], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = nomeArquivo;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const onClickEditConemb = async (e) => {
    setLoading(true);

    const { status, message: msg, data } = await envioEDI({
      nrSeqColetaEmbarque: e.nrSeqColetaEmbarque,
      noEnvioEdi: 'conemb',
    });

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success)
      donwloadEdi(data.arquivo, data.nomeArquivo);

    setLoading(false);
  };

  const onClickEditOcoren = async (e) => {
    setLoading(true);

    const { status, message: msg, data } = await envioEDI({
      nrSeqColetaEmbarque: e.nrSeqColetaEmbarque,
      noEnvioEdi: 'ocoren',
    });

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success)
      donwloadEdi(data.arquivo, data.nomeArquivo);

    setLoading(false);
  };

  const onClickEditCob = async (e) => {
    setLoading(true);

    const { status, message: msg, data } = await envioEDI({
      nrSeqColetaEmbarque: e.nrSeqColetaEmbarque,
      noEnvioEdi: 'cob',
    });

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success)
      donwloadEdi(data.arquivo, data.nomeArquivo);

    setLoading(false);
  };

  const onClickDesvincularCteRef = async (e) => {
    setLoading(true);

    const { status, message: msg } = await desvincularCteRef({
      nrSeqColetaEmbarque: e.nrSeqColetaEmbarque,
    });

    if (msg) onSetMessage(status, msg);

    setLoading(false);
  };

  const onClickInutilizarCte = async (e) => {
    setModalShow({
      ...modalShow,
      inutilizarCte: {
        show: true,
        cte: e,
      },
    });
  };

  const onClickSolicitarContingencia = async (e) => {
    setLoading(true);

    const { status, message: msg } = await solicitaContingencia({
      nrSeqColetaEmbarque: e.nrSeqColetaEmbarque,
    });

    if (msg) onSetMessage(status, msg);

    setLoading(false);
  };

  const onClickOcorrenciasCte = async (e) => {
    setModalShow({
      ...modalShow,
      ocorrenciasCte: {
        show: true,
        cte: e,
      },
    });
  };

  const onClickDownloadImage = async (e) => {
    setLoading(true);

    const { value } = await downloadImageColetaEmbarqueCTE({
      nrSeqColetaEmbarque: e.nrSeqColetaEmbarque,
    });

    if (value) {
      const downloadLink = document.createElement('a');
      downloadLink.href = `data:image/png;base64,${value}`;
      downloadLink.download = `${e.nrSeqColetaEmbarque}.jpg`;
      downloadLink.click();
    }

    setLoading(false);
  };

  const onClickDownloadPdfNota = async (selectedValue) => {
    const { nrSeqColetaEmbarque } = selectedValue;

    const { data } = await downloadPdfColetaEmbarque(nrSeqColetaEmbarque);

    if (data) {
      const downloadLink = document.createElement('a');

      downloadLink.href = `data:application/pdf;base64, ${data}`;

      downloadLink.download = `${nrSeqColetaEmbarque}nota.pdf`;

      downloadLink.click();
    }
  };

  /* const onGenerateCteUnified = async () => {
    if (gridView && gridView.current) {
      const selecteds = gridView.current.getCheckBoxValuesAt(0);

      if (selecteds.length === 0) {
        onSetMessage(0, 'Necessário selecionar as ctes para agrupamento');
      }

      const { status, message: msg } = await generateCteUnified({
        nrSeqEmbarques: selecteds.map((row) => row[0].value),
      });

      if (msg) onSetMessage(status, msg);
    }
  }; */

  const columns = [
    { key: 'nrSeqColetaEmbarque', type: GridView.ColumnTypes.Checkbox },
    { key: 'cdColetaEmbarque', title: 'Cód.' },
    {
      key: 'coletaEmbarqueAutorizacao.nrChaveCTE',
      title: 'Chave',
      visible: false,
    },
    { key: 'cliente.noPessoa', title: 'Cliente', visible: false },
    { key: 'dtEmissao', title: 'Emissão', format: GridView.DataTypes.Date },
    { key: 'stringDocumentos', title: 'Docs.', visible: false },
    { key: 'vlrDoc', title: 'Total Doc.', format: GridView.DataTypes.Decimal },
    { key: 'averbado', title: 'Aver.' },
    { key: 'vlrFrete', title: 'Frete', format: GridView.DataTypes.Decimal },
    {
      key: 'tipoEmbarque.noTipoEmbarque',
      title: 'Tipo Embarque',
      visible: false,
    },
    {
      key: 'mercadoriaPrincipal.noFreteMercadoria',
      title: 'Frete Mercadoria',
      visible: false,
    },
    {
      key: 'nrSeqAcertoMotorista',
      title: 'Cd Acerto Motorista',
      visible: false,
    },
    {
      key: 'noProtocoloAverbacaoSeguro',
      title: 'Protocolo Seguro 1',
    },
    {
      key: 'noProtocoloAverbacaoSeguroEmpresa',
      title: 'Protocolo Seguro 2',
    },
    {
      key: 'statusCteGrid.string',
      title: 'Status',
      color: 'statusCteGrid.color',
    },

    {
      key: 'noUltimaOcorrencia',
      title: 'Ocorrência',

      visible: false,
    },

    { key: 'usuarioCad.noPessoa', title: 'Usúario Cadastrado', visible: false },
    { key: 'usuarioAlt.noPessoa', title: 'Alter.', visible: false },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Transmitir',
      visibleDynamic: 'flgMostraTransmitir',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickTransmitirCte(e, datasource),
      theme: Theme.Success,
      icon: 'paper-plane',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Transmitir',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Cancelar',
      visibleDynamic: 'flgMostraCancelar',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickCancelarCte(e),
      theme: Theme.Danger,
      icon: 'ban',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Cancelar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Can. Extem.',
      visibleDynamic: 'flgMostraCancelamentoExtemporaneo',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickCancelarExtemporaneoCte(e),
      theme: Theme.Warning,
      icon: 'ban',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Cancelamento Extemporâneo',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Inutilizar',
      visibleDynamic: 'flgMostraInutilizar',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickInutilizarCte(e),
      theme: Theme.Primary,
      icon: 'exclamation',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Inutilizar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Corr.',
      visibleDynamic: 'flgMostraCorrigir',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickCorrigir(e),
      theme: Theme.Warning,
      icon: 'eraser',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Corrigir',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Desvincular',
      visibleDynamic: 'flgMostraDesvincular',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickDesvincularCteRef(e),
      theme: Theme.Danger,
      icon: 'minus',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Desvincular Cte Referenciado',
      tooltipDirection: 'bottom',
      visible: false,
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Impr.',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Impr.',
      visibleDynamic: 'flgMostraImprimirCartaCorrecao',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCartaCorrecao(e),
      theme: Theme.Warning,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir Carta Correcao',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Det.',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickDetalhes(e),
      theme: Theme.Success,
      icon: 'list',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Detalhes',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Email',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickEmail(e),
      theme: Theme.Success,
      icon: 'envelope',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Email',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Aver.',
      visibleDynamic: 'flgMostraAverbarCte',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickAverbar(e, datasource),
      theme: Theme.Success,
      icon: 'check',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Averbar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Sit.',
      visibleDynamic: 'flgMostraVerificarCte',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickVerificarCte(e, datasource),
      theme: Theme.Success,
      icon: 'info-circle',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Verificar CTE',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Dow.',
      visibleDynamic: 'flgMostraDownload',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownload(e, datasource),
      theme: Theme.Info,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Comp.',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickGeraComplemento(e),
      theme: Theme.Primary,
      icon: 'copy',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Gerar Complemento',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Conemb',
      visibleDynamic: 'flgMostraEdiConemb',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditConemb(e, datasource),
      theme: Theme.Info,
      icon: 'cogs',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Conemb',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Ocoren',
      visibleDynamic: 'flgMostraEdiOcoren',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditOcoren(e, datasource),
      theme: Theme.Primary,
      icon: 'cogs',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Ocoren',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Cob',
      visibleDynamic: 'flgMostraEdiCob',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditCob(e, datasource),
      theme: Theme.Warning,
      icon: 'cogs',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Cob',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'EPEC',
      visibleDynamic: 'flgMostraEpec',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickSolicitarContingencia(e),
      theme: Theme.Warning,
      icon: 'exclamation-circle',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Solicitar Contingência',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Ocorr',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickOcorrenciasCte(e),
      theme: Theme.Warning,
      icon: 'cogs',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Ocorrências Cte',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Ticket',
      type: GridView.ColumnTypes.Button,
      visibleDynamic: 'flgMostrarTicket',
      onClick: (e) => onClickDownloadImage(e),
      theme: Theme.Primary,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download Imagem',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Nota',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickDownloadPdfNota(e),
      theme: Theme.Secondary,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download Imagem',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickOpenModalComprovanteEntrega(e),
      theme: Theme.Info,
      icon: ['far', 'image'],
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Comprovante de entrega',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsSimplificado = [
    { key: 'nrSeqColetaEmbarque', type: GridView.ColumnTypes.Checkbox },
    { key: 'cdColetaEmbarque', title: 'Cód.' },
    {
      key: 'coletaEmbarqueAutorizacao.nrChaveCTE',
      title: 'Chave',
      visible: false,
    },
    { key: 'cliente.noPessoa', title: 'Cliente', visible: true },
    { key: 'dtEmissao', title: 'Emissão', format: GridView.DataTypes.Date },
    { key: 'stringDocumentos', title: 'Docs.', visible: true },
    {
      key: 'vlrDoc',
      title: 'Total Doc.',
      format: GridView.DataTypes.Decimal,
      visible: false,
    },
    { key: 'averbado', title: 'Aver.', visible: false },
    { key: 'vlrFrete', title: 'Frete', format: GridView.DataTypes.Decimal },
    {
      key: 'tipoEmbarque.noTipoEmbarque',
      title: 'Tipo Embarque',
      visible: false,
    },
    {
      key: 'mercadoriaPrincipal.noFreteMercadoria',
      title: 'Frete Mercadoria',
      visible: false,
    },
    {
      key: 'nrSeqAcertoMotorista',
      title: 'Cd Acerto Motorista',
      visible: false,
    },
    {
      key: 'noProtocoloAverbacaoSeguro',
      title: 'Protocolo Seguro 1',
      visible: false,
    },
    {
      key: 'noProtocoloAverbacaoSeguroEmpresa',
      title: 'Protocolo Seguro 2',
      visible: false,
    },
    {
      key: 'statusCteGrid.string',
      title: 'Status',
      color: 'statusCteGrid.color',
    },

    {
      key: 'noUltimaOcorrencia',
      title: 'Ocorrência',

      visible: true,
    },

    { key: 'usuarioCad.noPessoa', title: 'Usúario Cadastrado', visible: false },
    { key: 'usuarioAlt.noPessoa', title: 'Alter.', visible: false },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Transmitir',
      visibleDynamic: 'flgMostraTransmitir',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickTransmitirCte(e, datasource),
      theme: Theme.Success,
      icon: 'paper-plane',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Transmitir',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Cancelar',
      visibleDynamic: 'flgMostraCancelar',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickCancelarCte(e),
      theme: Theme.Danger,
      icon: 'ban',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Cancelar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Can. Extem.',
      visibleDynamic: 'flgMostraCancelamentoExtemporaneo',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickCancelarExtemporaneoCte(e),
      theme: Theme.Warning,
      icon: 'ban',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Cancelamento Extemporâneo',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Inutilizar',
      visibleDynamic: 'flgMostraInutilizar',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickInutilizarCte(e),
      theme: Theme.Primary,
      icon: 'exclamation',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Inutilizar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Corr.',
      visibleDynamic: 'flgMostraCorrigir',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickCorrigir(e),
      theme: Theme.Warning,
      icon: 'eraser',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Corrigir',
      tooltipDirection: 'bottom',
    },

    {
      key: 'nrSeqColetaEmbarque',
      title: 'Impr.',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCte(e),
      theme: Theme.Info,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Impr.',
      visibleDynamic: 'flgMostraImprimirCartaCorrecao',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickImprimirCartaCorrecao(e),
      theme: Theme.Warning,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir Carta Correcao',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Det.',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickDetalhes(e),
      theme: Theme.Success,
      icon: 'list',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Detalhes',
      tooltipDirection: 'bottom',
    },

    {
      key: 'nrSeqColetaEmbarque',
      title: 'Aver.',
      visibleDynamic: 'flgMostraAverbarCte',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickAverbar(e, datasource),
      theme: Theme.Success,
      icon: 'check',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Averbar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Sit.',
      visibleDynamic: 'flgMostraVerificarCte',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickVerificarCte(e, datasource),
      theme: Theme.Success,
      icon: 'info-circle',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Verificar CTE',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Dow.',
      visibleDynamic: 'flgMostraDownload',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownload(e, datasource),
      theme: Theme.Info,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Comp.',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickGeraComplemento(e),
      theme: Theme.Primary,
      icon: 'copy',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Gerar Complemento',
      tooltipDirection: 'bottom',
    },

    {
      key: 'nrSeqColetaEmbarque',
      title: 'Ocoren',
      visibleDynamic: 'flgMostraEdiOcoren',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditOcoren(e, datasource),
      theme: Theme.Primary,
      icon: 'cogs',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Ocoren',
      tooltipDirection: 'bottom',
    },

    {
      key: 'nrSeqColetaEmbarque',
      title: 'Ocorr',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickOcorrenciasCte(e),
      theme: Theme.Warning,
      icon: 'cogs',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Ocorrências Cte',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Ticket',
      type: GridView.ColumnTypes.Button,
      visibleDynamic: 'flgMostrarTicket',
      onClick: (e) => onClickDownloadImage(e),
      theme: Theme.Primary,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download Imagem',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      title: 'Nota',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickDownloadPdfNota(e),
      theme: Theme.Secondary,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download Imagem',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqColetaEmbarque',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickOpenModalComprovanteEntrega(e),
      theme: Theme.Info,
      icon: ['far', 'image'],
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Comprovante de entrega',
      tooltipDirection: 'bottom',
    },
  ];

  const search = async (params = null) => {
    setLoading(true);
    setGridVisible(false);

    const { data, pagination } = await getColetaEmbarquePagined(
      params ?? { ...filtros, page: 1 }
    );

    data.forEach((item) => {
      item.statusCteGrid = ColetaEmbarque.EnumStatusCte[item.statusCte];
    });

    setGridVisible(true);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  useEffect(async () => {
    setLoading(true);

    const valuePesquisaSimplificada = await getPesquisaGerenciarCTESimplificada();
    setPesquisaSimplificada(valuePesquisaSimplificada);

    const { empresas } = await getEmpresaDefaultUsuario({});

    if (registryKey) {
      search({
        nrSeqColetaEmbarque: registryKey,
      });
    }

    setFiltros({
      empresa: empresas[0],
      nrSeqEmpresa: empresas[0].nrSeqEmpresa,
      flgStatusCte: 'T',
      dtCadInicial: !valuePesquisaSimplificada ? dateNow() : undefined,
      dtCadFinal: !valuePesquisaSimplificada ? dateNow() : undefined,
    });

    setLoading(false);
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };

  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };

  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  const onOpenAgrupamentoTitulo = async () => {
    const NrSeqsColetaEmbarque = gridView.current
      .getCheckBoxValuesAt(0)
      ?.map((row) => row[0].value);

    if (NrSeqsColetaEmbarque.length === 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Selecione um item para fazer o pagamento'
      );
      return;
    }

    const searchColetaEmbarque = { NrSeqsColetaEmbarque };

    const coletaEmbarqueS = await SearchAgrupamentoCte(searchColetaEmbarque);

    if (coletaEmbarqueS.data.length === 0) {
      setMessage({
        message: coletaEmbarqueS.message,
        theme:
          coletaEmbarqueS.status === ResponseStatus.Success
            ? Theme.Success
            : Theme.Danger,
      });
      return;
    }

    setModalShow({
      ...modalShow,
      agruparCte: true,
      coletaEmbarqueS: coletaEmbarqueS.data,
    });
  };

  const onAutoCompleteEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);

    return empresas;
  };

  const onAutoCompleteTomador = async (e) => {
    const {
      status,
      message: msg,
      data: clientes,
    } = await getPessoaAutoCompleteTipoNome({
      noPessoa: e,
      Documento: e,
      noPessoaTipo: 'CLI',
    });

    if (msg) onSetMessage(status, msg);

    return clientes;
  };

  const onAutoCompleteVeiculo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      placa: e,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const statusCte = [
    { text: 'Sem Autorização', value: 'S' },
    { text: 'Autorizados', value: 'A' },
    { text: 'Inutilizados', value: 'I' },
    { text: 'Cancelados', value: 'C' },
    { text: 'Todos', value: 'T' },
  ];

  const onCloseModalCancelarCte = (data, inutilizacao, extemporaneo) => {
    if (data) {
      if (data.messages) {
        setMessages(data.messages);
      }

      if (data.cte) {
        let datasource = gridView.current.getDataSource();

        datasource = datasource.map((item) => {
          if (item.nrSeqColetaEmbarque === data.cte?.nrSeqColetaEmbarque) {
            const cte = new ColetaEmbarque(data.cte);

            cte.statusCteGrid =
              ColetaEmbarque.EnumStatusCte[data.cte?.statusCte];

            return cte;
          }

          return item;
        });

        if (gridView && gridView.current)
          gridView.current.setDataSource(datasource);
      }
    }

    if (inutilizacao) {
      setModalShow({
        ...modalShow,
        inutilizarCte: {
          show: false,
          cte: null,
        },
      });
    } else if (extemporaneo) {
      setModalShow({
        ...modalShow,
        cancelamentoExtemporaneo: {
          show: false,
          cte: null,
        },
      });
    } else {
      setModalShow({
        ...modalShow,
        cancelarCte: {
          show: false,
          cte: null,
        },
      });
    }
  };

  const validateDataSourceInformaProtocolo = (datasource) => {
    if (datasource.some((item) => !item.flgMostraAverbarCte)) {
      onSetMessage(
        ResponseStatus.Error,
        'Selecione apenas CTes que possam ser averbados.'
      );

      return false;
    }

    return true;
  };
  const onAutoCompleteFreteMercadoria = async (e) => {
    const {
      status,
      message: msg,
      data: freteMercadorias,
    } = await getFreteMercadoriaAutoComplete({
      noFreteMercadoria: e,
    });

    if (msg) onSetMessage(status, msg);

    return freteMercadorias;
  };

  const onAutoCompleteDestinatario = async (e) => {
    const {
      status,
      message: msg,
      data: destinatarios,
    } = await getDestinatarioSemRemetenteAutoComplete({
      noPessoa: e,
      flgTodos: true,
    });

    if (msg) onSetMessage(status, msg);

    return destinatarios;
  };
  const onAutoCompleteTipoEmbarque = async (e) => {
    const {
      status,
      message: msg,
      data: tiposEmbarque,
    } = await getTipoEmbarqueAutoComplete({
      noTipoEmbarque: e,
    });

    if (msg) onSetMessage(status, msg);

    return tiposEmbarque;
  };
  const onAutoCompleteTipoOcorrenciaColetaEmbarque = async (e) => {
    const {
      status,
      message: msg,
      data: value,
    } = await getTipoOcorrenciaColetaEmbarqueAutoComplete({
      noTipoOcorrenciaColetaEmbarque: e,
    });

    if (msg) onSetMessage(status, msg);

    return value;
  };

  const getSelectedsFromGridView = () => {
    const selecteds = gridView.current
      .getCheckBoxValuesAt(0)
      .map((row) => row[0].value);

    const datasource = gridView.current
      .getDataSource()
      .filter((item) => selecteds.includes(item.nrSeqColetaEmbarque));

    return datasource;
  };

  const openModalInformaProtocolo = async () => {
    setLoading(true);

    const datasource = getSelectedsFromGridView();

    if (validateDataSourceInformaProtocolo(datasource)) {
      if (datasource.length > 0) {
        setModalShow({
          ...modalShow,
          informaProtocolo: {
            show: true,
            ctes: datasource,
          },
        });
      } else {
        onSetMessage(ResponseStatus.Error, 'Selecione ao menos um CTe.');
      }
    }

    setLoading(false);
  };

  const openModalAverbarCtes = () => {
    setLoading(true);

    const datasource = getSelectedsFromGridView();

    if (validateDataSourceInformaProtocolo(datasource)) {
      if (datasource.length > 0) {
        setModalShow({
          ...modalShow,
          averbarLoteCtes: {
            show: true,
            ctes: datasource,
          },
        });
      } else {
        onSetMessage(ResponseStatus.Error, 'Selecione ao menos um CTe.');
      }
    }

    setLoading(false);
  };

  const onPrintRemessas = async () => {
    setLoading(true);
    const {
      value: base64,
      status,
      message: msg,
    } = await printRelatorioRemessas(filtros);

    if (msg) onSetMessage(status, msg);
    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `planilha_remessas.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };

  const onPrintRemessasSimplificado = async () => {
    setLoading(true);
    const {
      value: base64,
      status,
      message: msg,
    } = await printRelatorioRemessasSimplificado(filtros);

    if (msg) onSetMessage(status, msg);
    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `planilha_remessas.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Gerenciar CTE'
      loading={loading}
      transaction={transaction}
      onSearch={() => search()}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Imprimir'
          icon='print'
          onClick={() => onPrintRemessas()}
        />
        <ToolbarButtons.ButtonList
          onPrintFornecedorAgrupado
          icon='caret-down'
          items={[
            {
              text: 'Conferência Remessas',
              onClick: () => onPrintRemessas(),
            },
            {
              text: 'Conferência Remessas Simplificado ',
              onClick: () => onPrintRemessasSimplificado(),
            },
          ]}
        />
      </ToolbarButtons>

      {messages.length > 0 && (
        <Notification
          floatable
          messages={messages}
          onClose={() => {
            setMessages([]);
          }}
        />
      )}
      <div className='row'>
        <div className='row mb-3'>
          <Panel>
            <Panel.Header
              title='Filtros'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row'>
                <div className='row mb-3'>
                  <div className='col-8'>
                    <Autocomplete
                      label='Empresa'
                      searchDataSource={onAutoCompleteEmpresa}
                      selectedItem={filtros.empresa}
                      onSelectItem={(empresa) =>
                        setFiltros({
                          ...filtros,
                          empresa,
                          nrSeqEmpresa: empresa.nrSeqEmpresa,
                        })
                      }
                      dataSourceTextProperty='noPessoa'
                    />
                  </div>
                  <div className='col-2'>
                    <DatePicker
                      label='Data Cad. Inicial'
                      text={filtros.dtCadInicial}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtCadInicial) =>
                        setFiltros({ ...filtros, dtCadInicial })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <DatePicker
                      label='Data Cad. Final'
                      text={filtros.dtCadFinal}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtCadFinal) =>
                        setFiltros({ ...filtros, dtCadFinal })
                      }
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-2'>
                    <DatePicker
                      label='Data Emissão Inicial'
                      text={filtros.dtEmissaoInicial}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtEmissaoInicial) =>
                        setFiltros({ ...filtros, dtEmissaoInicial })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <DatePicker
                      label='Data Emissão Final'
                      text={filtros.dtEmissaoFinal}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtEmissaoFinal) =>
                        setFiltros({ ...filtros, dtEmissaoFinal })
                      }
                    />
                  </div>
                  <div className='col-4'>
                    <Autocomplete
                      label='Tomador'
                      searchDataSource={onAutoCompleteTomador}
                      selectedItem={filtros.tomador}
                      onSelectItem={(tomador) =>
                        setFiltros({
                          ...filtros,
                          tomador,
                          nrSeqPessoaCli: tomador.nrSeqPessoa,
                        })
                      }
                      dataSourceTextProperty='noPessoa'
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Nr Ordem Transporte'
                      mask={MaskTypes.Integer}
                      text={filtros.nrOrdemTransporte}
                      onChangedValue={(nrOrdemTransporte) => {
                        setFiltros({ ...filtros, nrOrdemTransporte });
                      }}
                    />
                  </div>
                  <div className='col-2'>
                    <Autocomplete
                      label='Placa'
                      searchDataSource={onAutoCompleteVeiculo}
                      selectedItem={filtros.veiculo}
                      onSelectItem={(veiculo) =>
                        setFiltros({
                          ...filtros,
                          veiculo,
                          nrSeqVeiculo: veiculo.nrSeqVeiculo,
                        })
                      }
                      dataSourceTextProperty='placa'
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-4'>
                    <Autocomplete
                      label='Frete Mercadoria'
                      searchDataSource={onAutoCompleteFreteMercadoria}
                      selectedItem={filtros.freteMercadoria}
                      onSelectItem={(mercadoriaPrincipal) => {
                        setFiltros({
                          ...filtros,
                          nrSeqFreteMercadoria:
                            mercadoriaPrincipal.nrSeqFreteMercadoria,
                          freteMercadoria: mercadoriaPrincipal,
                        });
                      }}
                      dataSourceTextProperty='noFreteMercadoria'
                    />
                  </div>

                  <div className='col-4'>
                    <Autocomplete
                      label='Destinatário'
                      searchDataSource={onAutoCompleteDestinatario}
                      selectedItem={filtros.destinatario}
                      onSelectItem={(destinatario) => {
                        setFiltros({
                          ...filtros,
                          nrSeqPessoaDes: destinatario.nrSeqPessoaDes,
                          destinatario,
                        });
                      }}
                      dataSourceTextProperty='noPessoa'
                    />
                  </div>
                  <div className='col-3'>
                    <Autocomplete
                      required
                      label='Tipo Embarque'
                      searchDataSource={onAutoCompleteTipoEmbarque}
                      selectedItem={filtros.tipoEmbarque}
                      onSelectItem={(tipoEmbarque) => {
                        setFiltros({
                          ...filtros,
                          tipoEmbarque,
                          nrSeqTipoEmbarque: tipoEmbarque.nrSeqTipoEmbarque,
                        });
                      }}
                      dataSourceTextProperty='noTipoEmbarque'
                    />
                  </div>
                </div>

                <div className='row mb-3'>
                  <div className='col-4'>
                    <Autocomplete
                      label='Tipo Ocorrência'
                      searchDataSource={
                        onAutoCompleteTipoOcorrenciaColetaEmbarque
                      }
                      selectedItem={filtros.tipoOcorrenciaColetaEmbarque}
                      onSelectItem={(tipoOcorrenciaColetaEmbarque) =>
                        setFiltros({
                          ...filtros,
                          nrSeqTipoOcorrenciaColetaEmbarque:
                            tipoOcorrenciaColetaEmbarque.nrSeqTipoOcorrenciaColetaEmbarque,
                        })
                      }
                      dataSourceTextProperty='noTipoOcorrenciaColetaEmbarque'
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Código CTE'
                      text={filtros.cdColetaEmbarque}
                      onChangedValue={(cdColetaEmbarque) =>
                        setFiltros({ ...filtros, cdColetaEmbarque })
                      }
                    />
                  </div>
                  <div className='col-5'>
                    <RadioButton
                      outline
                      label='Status CTE'
                      size={BootstrapSizes.Small}
                      theme={Theme.Primary}
                      buttons={statusCte}
                      selectedButton={filtros.flgStatusCte}
                      onChange={(flgStatusCte) =>
                        setFiltros({ ...filtros, flgStatusCte })
                      }
                    />
                  </div>
                  <div className='row mt-2'>
                    <div className='col-4'>
                      <Textbox
                        label='Número Nota'
                        text={filtros.nrNotaFiscal}
                        onChangedValue={(nrNotaFiscal) =>
                          setFiltros({ ...filtros, nrNotaFiscal })
                        }
                      />
                    </div>
                    <div className='col-2'>
                      <Switch
                        formControl
                        label='Incluir Chave'
                        checked={filtros.flgMostrarChave}
                        onChange={(flgMostrarChave) =>
                          setFiltros({ ...filtros, flgMostrarChave })
                        }
                      />
                    </div>
                    <div className='col-2'>
                      <Switch
                        formControl
                        label='Não Averbados c/ Seg.'
                        checked={filtros.naoAverbadosSeg}
                        onChange={(naoAverbadosSeg) =>
                          setFiltros({ ...filtros, naoAverbadosSeg })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>

        <div className='row'>
          <div className='col-4  '>
            <Button
              text='Agrupar Conhecimentos'
              className='mb-3 mt-4'
              size={BootstrapSizes.Large}
              theme={Theme.Success}
              template={Button.Templates.Default}
              onClick={onOpenAgrupamentoTitulo}
            />
          </div>
        </div>

        {gridVisible && !pesquisaSimplificada && (
          <div className='row'>
            <div className='col'>
              <GridView
                ref={gridView}
                enableExcelExport
                transaction={transaction}
                dataColumns={columns}
                gridSizeList={[500]}
                canControlVisibility
                onColumnSort={onColumnSort}
                onPageSizeChange={onPageSizeChange}
                onPageChange={onPageChange}
              >
                <Button
                  text='Averbar CTEs'
                  outline
                  theme={Theme.Success}
                  icon='check'
                  template={Button.Templates.ToogleText}
                  tooltip='Averbação de CTes em lote'
                  tooltipDirection='bottom'
                  onClick={openModalAverbarCtes}
                />
                <Button
                  text='Prot. Manual'
                  outline
                  theme={Theme.Primary}
                  icon='edit'
                  template={Button.Templates.ToogleText}
                  tooltip='Possibilita informar o protocolo de averbação manualmente.'
                  tooltipDirection='bottom'
                  onClick={openModalInformaProtocolo}
                />
              </GridView>
            </div>
          </div>
        )}

        {gridVisible && pesquisaSimplificada && (
          <div className='row'>
            <div className='col'>
              <GridView
                ref={gridView}
                enableExcelExport
                transaction={transaction}
                dataColumns={columnsSimplificado}
                gridSizeList={[500]}
                canControlVisibility
                onColumnSort={onColumnSort}
                onPageSizeChange={onPageSizeChange}
                onPageChange={onPageChange}
              >
                <Button
                  text='Averbar CTEs'
                  outline
                  theme={Theme.Success}
                  icon='check'
                  template={Button.Templates.ToogleText}
                  tooltip='Averbação de CTes em lote'
                  tooltipDirection='bottom'
                  onClick={openModalAverbarCtes}
                />
                <Button
                  text='Prot. Manual'
                  outline
                  theme={Theme.Primary}
                  icon='edit'
                  template={Button.Templates.ToogleText}
                  tooltip='Possibilita informar o protocolo de averbação manualmente.'
                  tooltipDirection='bottom'
                  onClick={openModalInformaProtocolo}
                />
              </GridView>
            </div>
          </div>
        )}

        <div className='row'>
          <div className='col'>
            <ModalDestalhesCte
              show={modalShow?.detalhes?.show}
              cte={modalShow?.detalhes?.cte}
              findTransaction={findTransaction}
              onOpenTransaction={onOpenTransaction}
              onClose={() => {
                setModalShow({
                  ...modalShow,
                  detalhes: {
                    show: false,
                    cte: null,
                  },
                });
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <ModalAnulacaoCte
              show={modalShow?.anulacaoCte?.show}
              cte={modalShow?.anulacaoCte?.cte}
              onClose={() =>
                setModalShow({
                  ...modalShow,
                  anulacaoCte: {
                    show: false,
                    cte: null,
                  },
                })
              }
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <ModalCancelarCte
              show={modalShow?.cancelarCte?.show}
              cte={modalShow?.cancelarCte?.cte}
              onClose={(datasource) => {
                onCloseModalCancelarCte(datasource, false);
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <ModalInutilizarCte
              show={modalShow?.inutilizarCte?.show}
              cte={modalShow?.inutilizarCte?.cte}
              onClose={(datasource) => {
                onCloseModalCancelarCte(datasource, true);
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <ModalCorrigirCte
              show={modalShow?.corrigirCte?.show}
              cte={modalShow?.corrigirCte?.cte}
              onClose={(msg, status) => {
                setModalShow({
                  ...modalShow,
                  corrigirCte: {
                    show: false,
                    cte: null,
                  },
                });

                if (msg) onSetMessage(status, msg);
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <ModalGeraComplemento
              show={modalShow?.geraComplemento?.show}
              cte={modalShow?.geraComplemento?.cte}
              onClose={(status, msg) => {
                setModalShow({
                  ...modalShow,
                  geraComplemento: {
                    show: false,
                    cte: null,
                  },
                });

                if (msg) onSetMessage(status, msg);
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <ModalAverbaCte
              show={modalShow?.averbaCte?.show}
              cte={modalShow?.averbaCte?.cte}
              onClose={(status, msg) => {
                if (msg) onSetMessage(status, msg);

                setModalShow({
                  ...modalShow,
                  averbaCte: {
                    show: false,
                    cte: null,
                  },
                });
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <ModalCancelamentoExtemporaneo
              show={modalShow?.cancelamentoExtemporaneo?.show}
              cte={modalShow?.cancelamentoExtemporaneo?.cte}
              onClose={(data) => {
                onCloseModalCancelarCte(data, false, true);
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <ModalInformaProtocolo
              show={modalShow?.informaProtocolo?.show}
              ctes={modalShow?.informaProtocolo?.ctes}
              onClose={(msg, status) => {
                if (msg) onSetMessage(status, msg);

                setModalShow({
                  ...modalShow,
                  informaProtocolo: {
                    show: false,
                    nrSeqsCte: null,
                  },
                });
              }}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <ModalOcorrenciasCte
              show={modalShow?.ocorrenciasCte?.show}
              cte={modalShow?.ocorrenciasCte?.cte}
              onClose={() => {
                setModalShow({
                  ...modalShow,
                  ocorrenciasCte: {
                    show: false,
                    cte: undefined,
                  },
                });
              }}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <ModalAverbaLoteCtes
              show={modalShow?.averbarLoteCtes?.show}
              ctes={modalShow?.averbarLoteCtes?.ctes}
              onAverbados={(ctes, msgs) => {
                setModalShow({
                  ...modalShow,
                  averbarLoteCtes: {
                    show: false,
                    nrSeqsCte: null,
                  },
                });

                const nrSeqsColetaEmbarque = ctes.map(
                  (item) => item.nrSeqColetaEmbarque
                );

                if (msgs.length) setMessages(msgs);

                search({ nrSeqsColetaEmbarque });
              }}
              onClose={(onSeach, msgs) => {
                setModalShow({
                  ...modalShow,
                  averbarLoteCtes: {
                    show: false,
                    nrSeqsCte: null,
                  },
                });

                if (msgs.length) setMessages(msgs);

                if (onSeach) search();
              }}
            />
          </div>
        </div>
        <ModalAgruparCte
          show={modalShow?.agruparCte}
          cte={modalShow?.coletaEmbarqueS}
          onClose={(value) => {
            setModalShow({
              ...modalShow,
              agruparCte: false,
              cte: undefined,
            });

            if (value)
              setMessage({
                message: value.message,
                theme: value.theme,
              });
          }}
        />

        <ModalComprovanteEntrega
          ref={modalComprovanteEntregaRef}
          show={modalComprovanteEntrega.show}
          nrSeqColetaEmbarque={modalComprovanteEntrega.nrSeqColetaEmbarque}
          onClose={(status, msg) => {
            if (msg) onSetMessage(status, msg);

            setModalComprovanteEntrega({
              show: false,
              nrSeqColetaEmbarque: null,
            });
          }}
        />
      </div>
    </CSDSelPage>
  );
}
