/* eslint-disable import/extensions */
import EnergiaContaConsumo from './energiaContaConsumo';
import EnergiaContaEncargo from './energiaContaEncargo';

const mesInText = (mes: number): string => {
  const meses = [
    'JAN',
    'FEV',
    'MAR',
    'ABR',
    'MAI',
    'JUN',
    'JUL',
    'AGO',
    'SET',
    'OUT',
    'NOV',
    'DEZ',
  ];

  if (mes >= 1 && mes <= 12) {
    return meses[mes - 1];
  }

  return '';
};

export const formatAnoMesRef = (ref: string = ''): string => {
  // eslint-disable-next-line radix
  const nomeMes = mesInText(parseInt(ref.slice(4)));

  return nomeMes === '' ? '' : `${ref.slice(0, 4)}/${nomeMes}`;
};

export default class EnergiaConta {
  nrSeqEnergiaUnidadeConsumidora: number;

  nrSeqEnergiaConta: number;

  dtLeitura: Date;

  dtLeituraAnterior: Date;

  qtdDiasConsumo: number;

  anoMesRef: number;

  anoMesRefFormated: string;

  dtVencimento: Date;

  vlrConta: number;

  consumos: EnergiaContaConsumo[];

  encargos: EnergiaContaEncargo[];

  status: string;

  constructor(jsonObject: EnergiaConta) {
    this.nrSeqEnergiaUnidadeConsumidora =
      jsonObject.nrSeqEnergiaUnidadeConsumidora;
    this.nrSeqEnergiaConta = jsonObject.nrSeqEnergiaConta;
    this.dtLeitura = jsonObject.dtLeitura;
    this.dtLeituraAnterior = jsonObject.dtLeituraAnterior;
    this.qtdDiasConsumo = jsonObject.qtdDiasConsumo;
    this.anoMesRef = jsonObject.anoMesRef;
    this.anoMesRefFormated = formatAnoMesRef(jsonObject.anoMesRef?.toString());
    this.dtVencimento = jsonObject.dtVencimento;
    this.vlrConta = jsonObject.vlrConta;
    this.consumos =
      jsonObject.consumos?.map((consumo) => new EnergiaContaConsumo(consumo)) ??
      [];
    this.encargos =
      jsonObject.encargos?.map((encargo) => new EnergiaContaEncargo(encargo)) ??
      [];
    this.status = jsonObject.status;
  }

  toJson = () => JSON.stringify(this);
}
